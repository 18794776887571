import { Button, Form, Input, Space } from 'antd';
import { useLogAccess } from 'api/auth';
import { useGetStockCheckList } from 'api/stockInventoryList';
import LoadingCallAPI from 'components/LoadingCallAPI/LoadingCallAPI';
import { FlexBetween } from 'components/StyleCommon';
import { PATH } from 'configs/routes';
import { MSG_ERROR, TEXT_ALL } from 'constants/text';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectUserInfo } from 'slice/app';
import messagePopup from 'utils/message/mesage';
import {
  FormWrapper,
  HeaderForm,
  WrapperMainte,
} from 'views/login/FormLogin/formLogin.style';

function Inventory() {
  const [form] = Form.useForm();
  const userInfo = useSelector(selectUserInfo);
  const navigate = useNavigate();
  const formRef = React.useRef<any>({});
  const labelTokuisaki =
    (userInfo.kaisya ?? '') + (userInfo.tensyo ?? '') + TEXT_ALL.SAMA;

  /**
   * *API LogAccess
   * todo log
   * @param {...}
   */
  const { mutate: logAccess } = useLogAccess();

  /**
   * *API GetStockCheckList
   * todo Get Stock Check List info to init
   * @param sysDate
   */
  const { mutate: excelMutation, isLoading: isLoadingExcel } =
    useGetStockCheckList();

  const onFinish = (values: any) => {
    messagePopup({
      type: 'warning',
      content:
        '実行すると、引当更新も実行されます。\n実行してもよろしいですか。',
      onOk: () => {
        excelMutation(
          { sysDate: values.sysDate },
          {
            onSuccess(data: any) {
              // Download file excel
              let fileName = data.headers['content-disposition'] || 'errorName';
              const file = new Blob([data?.data], {
                type: 'application/octet-stream',
              });
              const fileURL = URL.createObjectURL(file);
              const link = document.createElement('a');
              link.href = fileURL;

              link.download = decodeURIComponent(
                fileName.split('filename*=')[1].split(';')[0],
              ).substring(7);
              link.click();
              logAccess([
                {
                  tokuiCd: userInfo.torihikicd,
                  gyoumuId: '棚卸用一覧リスト',
                  pgName: '棚卸用一覧リスト',
                  logType: '2',
                  action: '棚卸用一覧リストをダウンロードされます。',
                },
              ] as any);
            },
            onError: async (error, variables, context) => {
              const errorMsg = JSON.parse(
                await error.response.data.text(),
              )?.message;
              messagePopup({
                type: 'info',
                content: MSG_ERROR[errorMsg] || errorMsg,
                onOk: () => formRef.current.btnSubmit.focus(),
              });
            },
          },
        );
      },
      onCancel: () => formRef.current?.btnSubmit?.focus(),
    });
  };

  return (
    <WrapperMainte>
      {isLoadingExcel && <LoadingCallAPI />}
      <FormWrapper
        form={form}
        className="form-mainte"
        name="stock-checkLst"
        labelCol={{ flex: '150px' }}
        wrapperCol={{ flex: 1 }}
        requiredMark={false}
        autoComplete="false"
        labelAlign="left"
        colon={false}
        validateTrigger="onSubmit"
        scrollToFirstError
        initialValues={{
          sysDate: moment(new Date()).format('YYYYMMDD'),
          sysDateFull: moment(new Date()).format('YYYY/MM/DD (ddd)'),
        }}
        onFinish={onFinish}
      >
        <HeaderForm>
          <span>{labelTokuisaki}</span>
        </HeaderForm>
        <FlexBetween>
          <Space>
            <Form.Item label={<label>棚卸日</label>}>
              <Space size={4}>
                <Form.Item name="sysDate" noStyle>
                  <Input disabled style={{ width: 100 }} />
                </Form.Item>
                <Form.Item name="sysDateFull" noStyle>
                  <Input disabled style={{ width: 140 }} />
                </Form.Item>
              </Space>
            </Form.Item>
          </Space>
          <Form.Item>
            <Button
              autoFocus
              ref={el => (formRef.current.btnSubmit = el)}
              htmlType="submit"
              type="primary"
              style={{ marginRight: 10, minWidth: 100 }}
            >
              実行
            </Button>
            <Button type="default" onClick={() => navigate(PATH.TOP)}>
              メニューへ戻る
            </Button>
          </Form.Item>
        </FlexBetween>
      </FormWrapper>
    </WrapperMainte>
  );
}

export default Inventory;
