import styled from 'styled-components';
import { Input, Button, Tabs } from 'antd';

export const HeaderContent = styled.div`
  width: 100%;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
export const BodyContent = styled.div`
  width: 100%;
  min-height: 400px;
  height: 100%;
  padding: 20px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &.osirase-mainte {
    padding: 0px;
    min-height: 400px;
  }
  &.order-confirm {
    padding: 0px;
    min-height: 360px;
  }
  /* justify-content: center; */
  .error_maxlength {
    margin-top: 4px;
    margin-bottom: 4px;
    width: 100%;
    height: 20px;
  }
`;

export const WrapperTable = styled.div`
  min-width: 0px;
  max-width: 95vw;
  overflow: auto;
  border-radius: 5px 5px 0px 0px;
  box-shadow: rgb(0 0 0 / 50%) 0px 0px 0px 0.5px;
`;

export const FlexColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const FlexEnd = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const FlexBetween = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  table {
    height: fit-content;
    td {
      font-weight: 500;
      border: 0.5px solid;
      padding: 4px;
      &.title {
        background: #e3e3e3;
        width: 140px;
      }
      &.content {
        width: 270px;
      }
    }
  }
`;

export const Label = styled.span`
  font-weight: 400;
  font-size: 14px;
  margin-right: 10px;
`;

export const InputField = styled(Input)`
  width: 336px;
  height: 30px;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 3px;
  margin-right: 4px;
`;
export const ButtonSearch = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 108px;
  height: 30px;
  background: #efefef;
  border: 1px solid #e4e4e4;
  box-sizing: border-box;
  border-radius: 5px;
  margin-right: 10px;
`;
export const ButtonClear = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  border: 1px solid #e4e4e4;
  box-sizing: border-box;
  border-radius: 5px;
  margin-right: 10px;
`;

export const SwitchModeTabs = styled(Tabs)`
  overflow: visible;
  .ant-tabs-nav {
    margin: 0;
    .ant-tabs-ink-bar {
      display: none;
    }
    .ant-tabs-nav-wrap {
      overflow: visible;
      .ant-tabs-nav-list {
        .ant-tabs-tab {
          margin: 0;
          padding: 0;
          .ant-tabs-tab-btn {
            min-width: 120px;
            margin: auto;
            padding: 3px 12px;
            border: solid 1px #949494;
            border-left: none;
            text-align: center;
            &:focus {
              box-shadow: 0 0 0 2px rgba(25, 40, 218, 0.5);
              border-color: #1928da;
            }
          }
          &:first-child {
            .ant-tabs-tab-btn {
              border-radius: 5px 0 0 5px;
              border-left: solid 1px #949494;
            }
          }
          &:nth-last-child(2) {
            .ant-tabs-tab-btn {
              border-radius: 0 5px 5px 0;
            }
          }
          &.ant-tabs-tab-active {
            .ant-tabs-tab-btn {
              color: #fff;
              background: #1928da;
              border-color: #1928da;
            }
          }
        }
      }
    }
    &::before {
      display: none;
    }
  }
  .ant-tabs-content-holder {
    display: none;
  }
`;
