import { Button, Checkbox, Space } from 'antd';
import { useLogAccess } from 'api/auth';
import { useUpdateStockOrder } from 'api/stockOrder';
import LoadingCallAPI from 'components/LoadingCallAPI/LoadingCallAPI';
import ModalCustom from 'components/ModalCustom';
import { BodyContent, FlexColumn, FlexEnd } from 'components/StyleCommon';
import TableCustom from 'components/TableCommon';
import { MSG_ERROR } from 'constants/text';
import { memo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { selectUserInfo } from 'slice/app';
import messagePopup from 'utils/message/mesage';
import {
  Content,
  DetailsOrder,
  DetailsOrderItem,
  Label,
} from 'views/common/orderSheet/orderSheet.style';

interface Props {
  isModalVisible: boolean;
  setIsModalVisible: any;
  record?: any;
  onResetPage?: any;
  afterClose?: any;
}

const StockRefillOrderConfirm = ({
  isModalVisible,
  setIsModalVisible,
  record,
  onResetPage,
  afterClose,
}: Props) => {
  const btnRef = useRef<any>();
  const userInfo = useSelector(selectUserInfo);

  /**
   * *API LogAccess
   * todo log
   * @param {...}
   */
  const { mutate: logAccess } = useLogAccess();

  /**
   * *API UpdateStockOrder
   * todo Request UpdateStockOrder
   * @param listStock
   */
  const {
    mutate: mutationUpdateStockOrder,
    isLoading: loadingStockOrder,
    reset: resetUpdateStockOrder,
  } = useUpdateStockOrder();

  const onSubmit = () => {
    const request = record.trokno?.map((item: any) => {
      const {
        trokno,
        chuno,
        zyugen,
        noki,
        hdate,
        cd5,
        cd24,
        cd10,
        dai,
        tani,
        hatchusuu,
      } = item;
      return {
        trokno,
        chuno,
        zyugen,
        noki,
        hdate,
        cd5,
        cd24,
        cd10,
        dai,
        tani,
        hatchusuu,
      };
    });
    messagePopup({
      type: 'question',
      content: '登録します。よろしいですか?',
      onOk: () => {
        mutationUpdateStockOrder(
          { arr: request },
          {
            onSuccess(data, variables, context) {
              logAccess([
                {
                  tokuiCd: userInfo.torihikicd,
                  gyoumuId: '発注入力',
                  pgName: '発注入力確認',
                  logType: '2',
                  action: '発注します',
                },
              ] as any);
              messagePopup({
                type: 'success',
                content: '発注情報を登録しました',
                onOk: () => {
                  setIsModalVisible(false);
                  onResetPage();
                },
              });
            },
            onError(error, variables, context) {
              messagePopup({
                type: 'error',
                content:
                  MSG_ERROR[(error as any)?.response?.data?.message] ||
                  error?.response?.data?.message,
                onOk: () => btnRef?.current?.focus(),
              });
            },
          },
        );
      },
      onCancel: () => btnRef?.current?.focus(),
    });
  };

  const columns = [
    {
      title: () => <div style={{ minWidth: 60 }}>削除</div>,
      dataIndex: 'check',
      key: 'check',
      render: (_value: any) => (
        <div
          style={{
            minWidth: 60,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Checkbox checked={_value} disabled />
        </div>
      ),
    },
    {
      title: () => <div style={{ minWidth: 40 }}>№</div>,
      dataIndex: 'index',
      key: 'index',
      render: (_value: any) => (
        <div style={{ textAlign: 'right', minWidth: 40 }}>{_value}</div>
      ),
    },
    {
      title: () => <div style={{ minWidth: 110 }}>注番</div>,
      dataIndex: 'chuno',
      key: 'chuno',
      render: (_value: any) => (
        <div
          style={{
            minWidth: 110,
            textAlign: 'center',
          }}
        >
          {_value}
        </div>
      ),
    },
    {
      title: () => <div style={{ minWidth: 110 }}>品名CD</div>,
      dataIndex: 'cd24',
      key: 'cd24',
      render: (_value: any) => {
        return (
          <div
            style={{
              minWidth: 110,
              textAlign: 'center',
            }}
          >
            {_value}
          </div>
        );
      },
    },
    {
      title: () => <div style={{ minWidth: 500 }}>品名</div>,
      dataIndex: 'nnm',
      key: 'nnm',
      render: (_value: any) => (
        <div
          style={{
            minWidth: 500,
            textAlign: 'left',
          }}
        >
          {_value}
        </div>
      ),
    },
    {
      title: () => <div style={{ minWidth: 85 }}>変更前数量</div>,
      dataIndex: 'suu',
      key: 'suu',
      render: (_value: any) => (
        <div
          style={{
            minWidth: 85,
            textAlign: 'right',
          }}
        >
          {_value}
        </div>
      ),
    },
    {
      title: () => <div style={{ minWidth: 85 }}>変更数量</div>,
      dataIndex: 'hatchusuu',
      key: 'hatchusuu',
      render: (_value: any) => (
        <div
          style={{
            minWidth: 85,
            textAlign: 'right',
          }}
        >
          {_value}
        </div>
      ),
    },
  ];

  return (
    <ModalCustom
      titleModal="発注入力確認"
      size="medium"
      isModalVisible={isModalVisible}
      setIsModalVisible={setIsModalVisible}
      afterClose={() => {
        resetUpdateStockOrder();
        afterClose();
      }}
      hideButtonClose={true}
    >
      {loadingStockOrder && <LoadingCallAPI />}
      <BodyContent className="order-confirm">
        <FlexColumn>
          <h2>内容をご確認ください</h2>
          <DetailsOrder style={{ width: 1020 }}>
            <DetailsOrderItem span={24}>
              <Label style={{ minWidth: '100px' }}>登録番号</Label>
              <Content>{record?.header?.trokno}</Content>
            </DetailsOrderItem>
            <DetailsOrderItem span={24}>
              <Label style={{ minWidth: '100px' }}>仕入先</Label>
              <Content>
                <span>
                  {record?.header?.zyugen} {record?.header?.siirenm}
                </span>
              </Content>
            </DetailsOrderItem>
            <DetailsOrderItem span={24}>
              <Label style={{ minWidth: '100px' }}>納期</Label>
              <Content>
                <span>{record?.header?.nokiFull}</span>
              </Content>
            </DetailsOrderItem>
          </DetailsOrder>
        </FlexColumn>
        <FlexColumn style={{ marginTop: 20 }}>
          <TableCustom
            rowKey="index"
            height={'230px'}
            dataSource={record?.trokno}
            columns={columns}
            checkChangeValue={(record: any) => {
              if (record?.suu !== record?.hatchusuu) return 'new-data';
              return '';
            }}
          />
        </FlexColumn>
        <FlexEnd style={{ marginTop: 20 }}>
          <Space>
            <Button ref={btnRef} autoFocus type="primary" onClick={onSubmit}>
              登録します
            </Button>
            <Button
              type="default"
              onClick={() => {
                setIsModalVisible(false);
              }}
            >
              訂正します（戻る）
            </Button>
          </Space>
        </FlexEnd>
      </BodyContent>
    </ModalCustom>
  );
};

export default memo(StockRefillOrderConfirm);
