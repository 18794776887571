export const columns = [
  {
    title: () => <div style={{ minWidth: 30 }}>No</div>,
    dataIndex: 'idd',
    key: 'idd',
    align: 'right',
    render: (_value: any, record: any, index: any) => (
      <div style={{ minWidth: 30, width: 'max-content' }}>{index + 1}</div>
    ),
  },

  {
    title: () => <div style={{ minWidth: 70 }}>協力店CD</div>,
    dataIndex: 'torihikicd',
    key: 'torihikicd',
    render: (_value: any) => (
      <div style={{ minWidth: 70, width: 'max-content' }}>{_value}</div>
    ),
  },
  // {
  //   title: () => <div style={{ minWidth: 110 }}>旧CAP事業者CD</div>,
  //   dataIndex: 'captytoricd',
  //   key: 'captytoricd',
  //   render: (_value: any) => (
  //     <div style={{ minWidth: 110, width: 'max-content' }}>{_value}</div>
  //   ),
  // },
  {
    title: () => <div style={{ minWidth: 185 }}>現CAP事業者CD（１０桁）</div>,
    dataIndex: 'chumonfax',
    key: 'chumonfax',
    render: (_value: any) => (
      <div style={{ minWidth: 185, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 85 }}>企業種類</div>,
    dataIndex: 'kigyoTypeName',
    key: 'kigyoTypeName',
    render: (_value: any) => (
      <div style={{ minWidth: 85, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 85 }}>企業グループ</div>,
    dataIndex: 'kigyoGroupName',
    key: 'kigyoGroupName',
    render: (_value: any) => (
      <div style={{ minWidth: 85, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 120 }}>所属本部</div>,
    dataIndex: 'tensyoH',
    key: 'tensyoH',
    render: (_value: any) => (
      <div style={{ minWidth: 120, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 250 }}>会社名</div>,
    dataIndex: 'kaisya',
    key: 'kaisya',
    render: (_value: any) => (
      <div style={{ minWidth: 250, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 60 }}>事業所名</div>,
    dataIndex: 'tensyo',
    key: 'tensyo',
    render: (_value: any) => (
      <div style={{ minWidth: 60, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 70 }}>会社名かな</div>,
    dataIndex: 'kaisyaH',
    key: 'kaisyaH',
    render: (_value: any) => (
      <div style={{ minWidth: 70, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 70 }}>旧班コード</div>,
    dataIndex: 'captyHancd',
    key: 'captyHancd',
    render: (_value: any) => (
      <div style={{ minWidth: 70, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 80 }}>班名</div>,
    dataIndex: 'hannm',
    key: 'hannm',
    render: (_value: any) => (
      <div style={{ minWidth: 80, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 50 }}>班ＣＤ</div>,
    dataIndex: 'hancd',
    key: 'hancd',
    render: (_value: any) => (
      <div style={{ minWidth: 50, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 80 }}>所属</div>,
    dataIndex: 'syozoku',
    key: 'syozoku',
    render: (_value: any) => (
      <div style={{ minWidth: 80, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 60 }}>現場ＣＤ</div>,
    dataIndex: 'genbacd',
    key: 'genbacd',
    render: (_value: any) => (
      <div style={{ minWidth: 60, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 80 }}>現場名</div>,
    dataIndex: 'genbanm',
    key: 'genbanm',
    render: (_value: any) => (
      <div style={{ minWidth: 80, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 50 }}>便CD</div>,
    dataIndex: 'bincd',
    key: 'bincd',
    render: (_value: any) => (
      <div style={{ minWidth: 50, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 80 }}>便名</div>,
    dataIndex: 'binnm1',
    key: 'binnm1',
    render: (_value: any) => (
      <div style={{ minWidth: 80, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 80 }}>郵便番号</div>,
    dataIndex: 'postcd',
    key: 'postcd',
    render: (_value: any) => (
      <div style={{ minWidth: 80, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 270 }}>住所</div>,
    dataIndex: 'adrs',
    key: 'adrs',
    render: (_value: any) => (
      <div style={{ minWidth: 270, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 100 }}>電話番号</div>,
    dataIndex: 'genbamTel',
    key: 'genbamTel',
    render: (_value: any) => (
      <div style={{ minWidth: 100, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 80 }}>FAX番号</div>,
    dataIndex: 'genbamFax',
    key: 'genbamFax',
    render: (_value: any) => (
      <div style={{ minWidth: 80, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 80 }}>ID</div>,
    dataIndex: 'id',
    key: 'id',
    render: (_value: any) => (
      <div style={{ minWidth: 80, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 80 }}>PASSWD</div>,
    dataIndex: 'passwd',
    key: 'passwd',
    render: (_value: any) => (
      <div style={{ minWidth: 80, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 80 }}>TEL</div>,
    dataIndex: 'torihikimTel',
    key: 'torihikimTel',
    render: (_value: any) => (
      <div style={{ minWidth: 80, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 80 }}>FAX</div>,
    dataIndex: 'torihikimFax',
    key: 'torihikimFax',
    render: (_value: any) => (
      <div style={{ minWidth: 80, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 60 }}>ADRS1</div>,
    dataIndex: 'adrs1',
    key: 'adrs1',
    render: (_value: any) => (
      <div style={{ minWidth: 60, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 60 }}>ADRS2</div>,
    dataIndex: 'adrs2',
    key: 'adrs2',
    render: (_value: any) => (
      <div style={{ minWidth: 60, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 60 }}>ADRS3</div>,
    dataIndex: 'adrs3',
    key: 'adrs3',
    render: (_value: any) => (
      <div style={{ minWidth: 60, width: 'max-content' }}>{_value}</div>
    ),
  },
];
