import {
  CloseButtonCustom,
  CustomModal,
  HeaderModel,
} from './modalCustom.style';
import { CloseCircleOutlined } from '@ant-design/icons';

interface Props {
  titleModal: string;
  isModalVisible: boolean;
  setIsModalVisible: any;
  disabledClose?: boolean;
  children: any;
  hideButtonClose?: boolean;
  size?: 'large' | 'medium';
  afterClose?: any;
  widthModal?: string;
  theme?: 'light' | 'contrast';
}

const ModalCustom = ({
  titleModal,
  isModalVisible,
  setIsModalVisible,
  disabledClose,
  children,
  size = 'medium',
  afterClose,
  hideButtonClose = false,
  theme = 'contrast',
}: Props) => {
  // const widthModal = size === 'large' ? '1161px' : '952px';
  const handleCancel = () => {
    disabledClose || setIsModalVisible(false);
  };

  return (
    <CustomModal
      closeIcon={
        <CloseCircleOutlined
          style={{
            fontSize: 20,
            color: theme === 'contrast' ? '#fff' : '#000',
          }}
        />
      }
      title={
        titleModal !== '' ? (
          <HeaderModel>
            <span className="title-modal">{titleModal}</span>
            <span></span>
          </HeaderModel>
        ) : (
          ''
        )
      }
      visible={isModalVisible}
      onCancel={handleCancel}
      width={'max-content'}
      bodyStyle={{
        height: '100%',
        width: 'max-content',
      }}
      footer={false}
      destroyOnClose={true}
      afterClose={afterClose}
      maskClosable={false}
    >
      {children}
      {!hideButtonClose && (
        <CloseButtonCustom
          onClick={() => setIsModalVisible(false)}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              e.preventDefault();
              setIsModalVisible(false);
            }
          }}
        >
          閉じる
        </CloseButtonCustom>
      )}
    </CustomModal>
  );
};
export default ModalCustom;
