import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Checkbox, Col, Form, Input, Row, Space } from 'antd';
import {
  useGetPartnerShipStore,
  useGetPartnerShipStoreExcel,
} from 'api/partnerShipStore';
import { PATH } from 'configs/routes';
import {
  getKeyboardFocusableElements,
  Hankaku2Zenkaku,
  hasKanji,
  IsHankakuEisu,
  ToASCII,
} from 'constants/common';
import { MSG_ERROR, TEXT_ALL } from 'constants/text';
import { selectUserInfo } from 'slice/app';
import messagePopup from 'utils/message/mesage';
import { FormWrapper } from 'views/login/FormLogin/formLogin.style';
import { ControlFilter, MenuWrapper, Wrapper } from '../webSearchMenu.style';
import ModalResultPartnerShipStore from './modalResult';
import { iKigyoGroup, useGetKigyoGroup } from 'api/customer';
import LoadingCallAPI from 'components/LoadingCallAPI/LoadingCallAPI';

const PartnerShipStore = () => {
  const userInfo = useSelector(selectUserInfo);
  const labelTokuisaki =
    (userInfo.kaisya ?? '') + (userInfo.tensyo ?? '') + TEXT_ALL.SAMA;
  const [form] = Form.useForm();
  const refForm = useRef<any>({});
  const refPreviousValue = useRef<any>(null);
  const navigate = useNavigate();
  const [dataSource, setDataSource] = useState<any>({
    count: 0,
    listData: [],
  });
  const [kigyoTypeOptions, setKigyoTypeOptions] = useState<any>([]);
  const {
    mutate: getKigyoGroup,
    data: dataKigyoGroups,
    isLoading: loadingKigyoGroup,
  } = useGetKigyoGroup();
  const [visibleModalResult, setVisibleModalResult] = useState<any>(false);
  const { mutate, isLoading, reset } = useGetPartnerShipStore();
  const {
    mutate: excelMutation,
    isLoading: isLoadingExcel,
    reset: resetExcel,
  } = useGetPartnerShipStoreExcel();

  const keyboardFocusElement: any = useRef([]);
  const focusNextEle = (e: any) => {
    const index = keyboardFocusElement.current.findIndex(
      (element: any) => element === e.target,
    );
    if (index !== -1) {
      if (e.key === 'Enter' || (e.key === 'Tab' && !e.shiftKey)) {
        e.preventDefault();
        keyboardFocusElement.current[index + 1]
          ? keyboardFocusElement.current[index + 1]?.focus()
          : keyboardFocusElement.current[0]?.focus();
      } else if (e.shiftKey && e.key === 'Tab') {
        e.preventDefault();
        keyboardFocusElement.current[index - 1]
          ? keyboardFocusElement.current[index - 1]?.focus()
          : keyboardFocusElement.current[
              keyboardFocusElement.current.length
            ]?.focus();
      }
    }
  };

  useEffect(() => {
    keyboardFocusElement.current = getKeyboardFocusableElements();
  });

  // Func reset form and clear cache data
  function resetPage() {
    form.resetFields();
    reset();
    resetExcel();
    setDataSource({
      count: 0,
      listData: [],
    });
  }

  // In first render
  useEffect(() => {
    resetPage();
    getKigyoGroup(
      {},
      {
        onSuccess({ data }, variables, context) {
          setKigyoTypeOptions(data);
          let formValues = {
            ...form.getFieldsValue(),
            kigyoGroups: data?.map(
              (kigyoType: iKigyoGroup) => kigyoType.kigyogroupId,
            ),
          };
          form.setFieldsValue(formValues);
        },
        onError(error, variables, context) {
          messagePopup({
            type: 'info',
            content:
              MSG_ERROR[(error as any)?.response?.data?.message] ||
              (error as any)?.response?.data?.message,
          });
        },
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // onSubmit to get response list  hat product delivery
  const onFinish = async (record: any) => {
    let params = {
      torihikicd: record.torihikicd,
      kaisyaH: record.kaisyaH,
      kaisya: record.kaisya,
      kigyoGroups: record.kigyoGroups,
    };
    mutate(params as any, {
      onSuccess(data, variables, context) {
        const { data: dataList } = data;
        if (dataList?.count > 0) {
          setDataSource({
            count: dataList?.count,
            listData: dataList?.data?.map((order: any, index: number) => ({
              idx: index + 1,
              ...order,
            })),
          });
          setVisibleModalResult(true);
        } else {
          messagePopup({
            type: 'error',
            content: MSG_ERROR['MSG_NO_DATA'],
            onOk: () => {
              refForm?.current?.kaisyaH?.focus();
            },
          });
        }
      },
      onError(error, variables, context) {
        messagePopup({
          type: 'error',
          content:
            MSG_ERROR[(error as any)?.response?.data?.message] ||
            (error as any)?.response?.data?.message,
          onOk: () => {
            refForm?.current?.kaisyaH?.focus();
          },
        });
      },
    });
  };

  // Validate form input
  const onFinishFailed = ({ values, errorFields }: any) => {
    messagePopup({
      type: 'info',
      content: errorFields[0]?.errors[0],
      onOk: () => {
        refForm?.current?.[errorFields[0]?.name[0]]?.focus();
        !!refForm?.current?.[errorFields[0]?.name[0]]?.select?.toString() &&
          refForm?.current?.[errorFields[0]?.name[0]]?.select();
      },
    });
  };

  //Handle Excel
  const handleExcel = () => {
    const { torihikicd, kaisyaH, kaisya, kigyoGroups } = form.getFieldsValue();
    const params = {
      torihikicd: torihikicd,
      kaisyaH: kaisyaH,
      kaisya: kaisya,
      kigyoGroups,
    };

    form
      .validateFields()
      .then(() =>
        excelMutation(params as any, {
          onSuccess(data, variables, context) {
            let fileName = data.headers['content-disposition'] || 'errorName';
            const file = new Blob([data?.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            const fileURL = URL.createObjectURL(file);
            const link = document.createElement('a');
            link.href = fileURL;
            link.download = decodeURIComponent(
              fileName?.split('filename*=')[1].split(';')[0],
            ).substring(7);
            link.click();
          },
          onError: async (error, variables, context) => {
            const errorMsg = JSON.parse(
              await error.response.data.text(),
            )?.message;
            messagePopup({
              type: 'error',
              content: MSG_ERROR[errorMsg] || errorMsg,
              onOk: () => {
                refForm?.current?.kaisyaH?.focus();
              },
            });
          },
        }),
      )
      .catch(({ errorFields }) => {
        messagePopup({
          type: 'info',
          content: errorFields[0]?.errors[0],
          onOk: () => {
            refForm?.current?.[errorFields[0]?.name[0]]?.focus();
            !!refForm?.current?.[errorFields[0]?.name[0]]?.select?.toString() &&
              refForm?.current?.[errorFields[0]?.name[0]]?.select();
          },
        });
      });
  };

  return (
    <Wrapper>
      {loadingKigyoGroup && <LoadingCallAPI />}
      <MenuWrapper style={{ marginBottom: 8 }}>
        <span>{labelTokuisaki}</span>
      </MenuWrapper>

      <FormWrapper
        form={form}
        className="form-mainte"
        name="websearch-mainte"
        labelCol={{ flex: '200px' }}
        wrapperCol={{ flex: 1 }}
        requiredMark={false}
        labelAlign="left"
        colon={false}
        validateTrigger="onSubmit"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        scrollToFirstError
        initialValues={{
          torihikicd: '',
          kaisyaH: '',
          kaisya: '',
          kigyoGroups: dataKigyoGroups?.data?.map(
            (kigyoType: iKigyoGroup) => kigyoType.kigyogroupId,
          ),
        }}
        onKeyPress={(e: any) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
      >
        <Form.Item
          name="kaisyaH"
          label={<label>{'会社名ひらがな（中間一致）'}</label>}
        >
          <Input
            disabled={isLoading}
            autoFocus
            maxLength={30}
            ref={el => (refForm.current.kaisyaH = el)}
            style={{ width: 400 }}
            onKeyUp={event =>
              (event.key === 'Enter' || event.key === 'Tab') &&
              event.currentTarget.select()
            }
            onBlur={event => {
              event.preventDefault();
              form.setFieldsValue({
                kaisyaH: Hankaku2Zenkaku(event.target.value.trim()),
              });
            }}
            onKeyDown={focusNextEle}
          ></Input>
        </Form.Item>
        <Form.Item
          name="kaisya"
          label={<label>{'会社名　漢字（中間一致）'}</label>}
        >
          <Input
            disabled={isLoading}
            maxLength={15}
            ref={el => (refForm.current.kaisya = el)}
            style={{ width: 380 }}
            onKeyUp={event =>
              (event.key === 'Enter' || event.key === 'Tab') &&
              event.currentTarget.select()
            }
            onBlur={event => {
              event.preventDefault();
              form.setFieldsValue({
                kaisya: Hankaku2Zenkaku(event.target.value.trim()),
              });
            }}
            onKeyDown={focusNextEle}
          ></Input>
        </Form.Item>
        <Form.Item
          name="torihikicd"
          label={<label>{'協力店ＣＤ（前方一致）'}</label>}
          rules={[
            () => ({
              validator(_: any, value: string) {
                if (value && (IsHankakuEisu(value) || hasKanji(value))) {
                  return Promise.reject(
                    new Error('協力店ＣＤ' + MSG_ERROR['MSG_HANKAKU_EISU']),
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input
            disabled={isLoading}
            ref={el => (refForm.current.torihikicd = el)}
            maxLength={6}
            style={{ width: 160 }}
            onKeyUp={event =>
              (event.key === 'Enter' || event.key === 'Tab') &&
              event.currentTarget.select()
            }
            onFocus={event => {
              refPreviousValue.current = event.target.value;
            }}
            onBlur={event => {
              event.preventDefault();
              let value = ToASCII(event.target.value.trim());
              form.setFieldsValue({
                torihikicd: value,
              });
              if (value !== refPreviousValue.current) {
                form.validateFields(['torihikicd']).catch(({ errorFields }) => {
                  messagePopup({
                    type: 'info',
                    content: errorFields[0]?.errors[0],
                    onOk: () => {
                      refForm?.current?.[errorFields[0]?.name[0]]?.focus();
                      refForm?.current?.[errorFields[0]?.name[0]]?.select();
                    },
                  });
                });
              }
            }}
            onKeyDown={focusNextEle}
          ></Input>
        </Form.Item>
        <Form.Item
          label={<label>{'企業グループ'}</label>}
          style={{ alignItems: 'baseline' }}
        >
          <Space style={{ marginBottom: 8 }}>
            <Button
              disabled={isLoading}
              onClick={() => {
                form.setFieldsValue({
                  kigyoGroups: kigyoTypeOptions?.map(
                    (kigyoType: any) => kigyoType.kigyogroupId,
                  ),
                });
              }}
              onKeyDown={e => {
                e.key === 'Enter' && (e.target as any).click();
              }}
            >
              全チェック
            </Button>
            <Button
              disabled={isLoading}
              onClick={() => {
                form.setFieldsValue({
                  kigyoGroups: [],
                });
              }}
              onKeyDown={e => {
                e.key === 'Enter' && (e.target as any).click();
              }}
            >
              全チェック解除
            </Button>
          </Space>
          <Form.Item name="kigyoGroups" noStyle>
            <Checkbox.Group style={{ width: '100%' }}>
              <Row gutter={[0, 2]} style={{ flexWrap: 'wrap' }}>
                {kigyoTypeOptions.map((kigyo: any) => {
                  return (
                    <Col
                      key={kigyo.kigyogroupId}
                      style={{
                        flex: '0 0 33%',
                      }}
                    >
                      <Checkbox
                        disabled={isLoading}
                        value={kigyo.kigyogroupId}
                        key={kigyo.kigyogroupId}
                        onKeyDown={focusNextEle}
                      >
                        {kigyo.kigyogroupNm}
                      </Checkbox>
                    </Col>
                  );
                })}
              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Form.Item>
      </FormWrapper>

      <ControlFilter style={{ marginTop: 15 }}>
        <Space>
          <Button
            style={{ minWidth: 100 }}
            loading={isLoading}
            type="primary"
            ref={el => (refForm.current.btnSubmit = el)}
            onClick={e => {
              e.preventDefault();
              setDataSource({
                count: 0,
                listData: [],
              });
              setTimeout(() => {
                form.submit();
              }, 100);
            }}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                e.preventDefault();
                e.currentTarget.click();
              }
            }}
          >
            検索
          </Button>
          <Button
            onClick={e => {
              e.preventDefault();
              resetExcel();
              handleExcel();
            }}
            loading={isLoadingExcel}
            type="default"
            style={{ width: 180 }}
          >
            EXCEL作成（XLSX）
          </Button>
          <Button
            onClick={event => {
              event.preventDefault();
              resetPage();
            }}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                e.preventDefault();
                e.currentTarget.click();
              }
            }}
            type="default"
            ref={el => (refForm.current.btnReturn = el)}
          >
            画面クリア
          </Button>
          <Button
            type="default"
            onClick={event => {
              event.preventDefault();
              resetPage();
              navigate(PATH.WEBSEARCH_MENU);
            }}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                e.preventDefault();
                e.currentTarget.click();
              }
            }}
          >
            メニューへ戻る
          </Button>
        </Space>
      </ControlFilter>

      <ModalResultPartnerShipStore
        form={form}
        isModalVisible={visibleModalResult}
        setIsModalVisible={setVisibleModalResult}
        dataSource={dataSource}
        afterClose={() => {
          refForm.current.btnSubmit.focus();
          reset();
        }}
      />
    </Wrapper>
  );
};

export default PartnerShipStore;
