import { Input } from 'antd';
import styled from 'styled-components';

export const SCInputDatePicker = styled(Input)`
  width: 150px;
  .ant-picker-input > input {
    display: none;
  }
  .ant-picker-suffix {
    margin-left: 0;
  }
  .ant-input-group-addon {
    padding: 0;
  }
  .ant-picker {
    border: 0px;
    padding: 0px;
    box-shadow: none;
    cursor: pointer;
  }
  .ant-input:focus{
    box-shadow: none !important;
  }
`;
