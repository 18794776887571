import { Col, Row } from 'antd';
import { PATH } from 'configs/routes';
import data from 'constants/notification-menu.json';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { autoLogin } from 'slice/app';
import FormLogin from 'views/login/FormLogin';
import { LoginContainer, NoteContent, NoteItem } from './login.style';
import queryString from 'query-string';
import { Base64 } from 'js-base64';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { key } = queryString.parse(location.search);
  useEffect(() => {
    if (key) {
      const loginInfo: any = JSON?.parse(Base64.decode(key as string));
      dispatch(
        autoLogin({
          ...JSON.parse(loginInfo.userInfo),
          tokenResponse: {
            expiredAt: loginInfo.expiredAt,
            token: loginInfo.accessToken,
          },
        }),
      );
      navigate(PATH.TOP);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LoginContainer>
      <FormLogin></FormLogin>
      <NoteContent title="お知らせ">
        {data.map((item, idx) => (
          <NoteItem
            key={idx}
            title={item.title}
            extra={<span>{item.subTitle}</span>}
          >
            <span>{item.company}</span>
            <Row>
              {item.contact?.map((itemDescription, idxDescription) => (
                <Col span={12} key={idxDescription}>
                  {itemDescription.substring(0, 4).toString() === 'MAIL' ? (
                    <div style={{ display: 'flex' }}>
                      MAIL
                      <a className="link-mail" href="mailto:oa@hat.co.jp">
                        {itemDescription.substring(4).toString()}
                      </a>
                    </div>
                  ) : (
                    itemDescription
                  )}
                </Col>
              ))}
            </Row>
            <span>{item?.tel}</span>
            <Row>
              {item.description?.map((itemDescription, idxDescription) => (
                <Col span={12} key={idxDescription}>
                  {itemDescription.substring(0, 4).toString() === 'MAIL' ? (
                    <div style={{ display: 'flex' }}>
                      MAIL
                      <a className="link-mail" href="mailto:oa@hat.co.jp">
                        {itemDescription.substring(4).toString()}
                      </a>
                    </div>
                  ) : (
                    itemDescription
                  )}
                </Col>
              ))}
            </Row>
          </NoteItem>
        ))}
      </NoteContent>
      <span style={{ marginTop: 30 }}>Version 1.9.7.1</span>
    </LoginContainer>
  );
};

export default Login;
