export const columns = (lstKigyoType?: any) => {
  return [
    {
      title: () => <div style={{ minWidth: 30 }}>No</div>,
      dataIndex: 'id',
      key: 'id',
      align: 'right',
      render: (_value: any, record: any, index: any) => (
        <div style={{ minWidth: 30, width: 'max-content' }}>{index + 1}</div>
      ),
    },
    {
      title: () => <div style={{ minWidth: 60 }}>商品CD</div>,
      dataIndex: 'cd24',
      key: 'cd24',
      render: (_value: any) => (
        <div style={{ minWidth: 60, width: 'max-content' }}>{_value}</div>
      ),
    },
    {
      title: () => <div style={{ minWidth: 60 }}>フラグ立</div>,
      dataIndex: 'st2',
      key: 'st2',
      render: (_value: any) => (
        <div style={{ minWidth: 60, width: 'max-content' }}>{_value}</div>
      ),
    },
    {
      title: () => <div style={{ minWidth: 100 }}>商品名(ｶﾅ)</div>,
      dataIndex: 'kanm',
      key: 'kanm',
      render: (_value: any) => (
        <div style={{ minWidth: 100, width: 'max-content' }}>{_value}</div>
      ),
    },
    {
      title: () => <div style={{ minWidth: 50 }}>商品名</div>,
      dataIndex: 'nnm',
      key: 'nnm',
      render: (_value: any) => (
        <div style={{ minWidth: 50, width: 'max-content' }}>{_value}</div>
      ),
    },
    {
      title: () => <div style={{ minWidth: 50 }}>規格</div>,
      dataIndex: 'nnmsz',
      key: 'nnmsz',
      render: (_value: any) => (
        <div style={{ minWidth: 50, width: 'max-content' }}>{_value}</div>
      ),
    },
    {
      title: () => <div style={{ minWidth: 70 }}>分類CD</div>,
      dataIndex: 'code5',
      key: 'code5',
      render: (_value: any) => (
        <div style={{ minWidth: 70, width: 'max-content' }}>{_value}</div>
      ),
    },
    {
      title: () => <div style={{ minWidth: 70 }}>分類名</div>,
      dataIndex: 'code5nm',
      key: 'code5nm',
      render: (_value: any) => (
        <div style={{ minWidth: 70, width: 'max-content' }}>{_value}</div>
      ),
    },
    {
      title: () => <div style={{ minWidth: 70 }}>ﾛｹｰｼｮﾝ</div>,
      dataIndex: 'location',
      key: 'location',
      render: (_value: any) => (
        <div style={{ minWidth: 70, width: 'max-content' }}>{_value}</div>
      ),
    },
    {
      title: () => <div style={{ minWidth: 70 }}>仕入先</div>,
      dataIndex: 'siirenm',
      key: 'siirenm',
      render: (_value: any) => (
        <div style={{ minWidth: 70, width: 'max-content' }}>{_value}</div>
      ),
    },
    {
      title: () => <div style={{ minWidth: 135 }}>ﾋﾟｯｷﾝｸﾞｼｰﾄ出力条件</div>,
      dataIndex: 'ptypePbiasu',
      key: 'ptypePbiasu',
      render: (_value: any) => (
        <div style={{ minWidth: 135, width: 'max-content' }}>{_value}</div>
      ),
    },
    {
      title: () => <div style={{ minWidth: 40 }}>入数</div>,
      dataIndex: 'kpd',
      key: 'kpd',
      align: 'right',
      render: (_value: any) => (
        <div style={{ minWidth: 40, width: 'max-content' }}>{_value}</div>
      ),
    },
    {
      title: () => <div style={{ minWidth: 60 }}>在庫区分</div>,
      dataIndex: 'decodedZaikokbn',
      key: 'decodedZaikokbn',
      render: (_value: any) => (
        <div style={{ minWidth: 60, width: 'max-content' }}>{_value}</div>
      ),
    },
    {
      title: () => <div style={{ minWidth: 80 }}>引当可能数</div>,
      dataIndex: 'zaiko',
      key: 'zaiko',
      align: 'right',
      render: (_value: any) => (
        <div style={{ minWidth: 80, width: 'max-content' }}>{_value}</div>
      ),
    },
    {
      title: () => <div style={{ minWidth: 80 }}>最大在庫数</div>,
      dataIndex: 'zaikomax',
      key: 'zaikomax',
      align: 'right',
      render: (_value: any) => (
        <div style={{ minWidth: 80, width: 'max-content' }}>{_value}</div>
      ),
    },
    {
      title: () => <div style={{ minWidth: 80 }}>発注点</div>,
      dataIndex: 'orderpoint',
      key: 'orderpoint',
      align: 'right',
      render: (_value: any) => (
        <div style={{ minWidth: 80, width: 'max-content' }}>{_value}</div>
      ),
    },
    {
      title: () => <div style={{ minWidth: 60 }}>重量(Kg)</div>,
      dataIndex: 'jyuryo',
      key: 'jyuryo',
      align: 'right',
      render: (_value: any) => {
        _value = _value.toFixed(2);
        return (
          <div style={{ minWidth: 60, width: 'max-content' }}>{_value}</div>
        );
      },
    },
    {
      title: () => <div style={{ minWidth: 90 }}>手配書フラグ</div>,
      dataIndex: 'decodedSt3',
      key: 'decodedSt3',
      render: (_value: any) => (
        <div style={{ minWidth: 90, width: 'max-content' }}>{_value}</div>
      ),
    },
    {
      title: () => <div style={{ minWidth: 70 }}>販売単価</div>,
      dataIndex: 'bold',
      key: 'bold',
      align: 'right',
      render: (_value: any) => (
        <div style={{ minWidth: 70, width: 'max-content' }}>{_value}</div>
      ),
    },
    {
      title: () => <div style={{ minWidth: 90 }}>価格設定日付</div>,
      dataIndex: 'bdate',
      key: 'bdate',
      render: (_value: any) => (
        <div style={{ minWidth: 90, width: 'max-content' }}>{_value}</div>
      ),
    },
    {
      title: () => <div style={{ minWidth: 60 }}>ﾘｰﾄﾞﾀｲﾑ</div>,
      dataIndex: 'nok',
      key: 'nok',
      render: (_value: any) => (
        <div style={{ minWidth: 60, width: 'max-content' }}>{_value}</div>
      ),
    },
    {
      title: () => <div style={{ minWidth: 60 }}>ﾒｰﾄﾙ管理</div>,
      dataIndex: 'decodedTnicd',
      key: 'decodedTnicd',
      render: (_value: any) => (
        <div style={{ minWidth: 60, width: 'max-content' }}>{_value}</div>
      ),
    },
    {
      title: () => <div style={{ minWidth: 40 }}>単位</div>,
      dataIndex: 'tninm',
      key: 'tninm',
      render: (_value: any) => (
        <div style={{ minWidth: 40, width: 'max-content' }}>{_value}</div>
      ),
    },
    {
      title: () => <div style={{ minWidth: 110 }}>異常発注警告数</div>,
      dataIndex: 'ordermax',
      key: 'ordermax',
      align: 'right',
      render: (_value: any) => (
        <div style={{ minWidth: 110, width: 'max-content' }}>{_value}</div>
      ),
    },
    ...(lstKigyoType?.data || []).map((item: any) => {
      return {
        title: () => (
          <div
            style={{
              minWidth: 70,
              width: item.kigyotypeNm.length * 15,
            }}
          >
            {item.kigyotypeNm}
          </div>
        ),
        dataIndex: 'kigyoTypes',
        key: 'kigyoTypes',
        render: (_value: any, _record: any) => {
          return (
            <div style={{ minWidth: 70, width: item.kigyotypeNm.length * 15 }}>
              {!!_value?.find((itemVl: any) => itemVl === item.kigyotypeId)
                ? '○'
                : '×'}
            </div>
          );
        },
      };
    }),
    {
      title: () => <div style={{ minWidth: 70 }}>登録日</div>,
      dataIndex: 'inpdt',
      key: 'inpdt',
      render: (_value: any) => (
        <div style={{ minWidth: 70, width: 'max-content' }}>{_value}</div>
      ),
    },
    {
      title: () => <div style={{ minWidth: 70 }}>変更日</div>,
      dataIndex: 'updt',
      key: 'updt',
      render: (_value: any) => (
        <div style={{ minWidth: 70, width: 'max-content' }}>{_value}</div>
      ),
    },
    {
      title: () => <div style={{ minWidth: 70 }}>削除日</div>,
      dataIndex: 'deldate',
      key: 'deldate',
      render: (_value: any) => (
        <div style={{ minWidth: 70, width: 'max-content' }}>
          {_value == '99999999' || _value == null ? '' : _value}
        </div>
      ),
    },
  ];
}
