export const columns = [
  {
    title: () => <div style={{ minWidth: 30 }}>No.</div>,
    dataIndex: 'id',
    key: 'id',
    align: 'right',
    render: (_value: any, record: any, index: any) => (
      <div style={{ minWidth: 30, width: 'max-content' }}>{index + 1}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 60 }}>入荷日</div>,
    dataIndex: 'nyukaDt',
    key: 'nyukaDt',
    render: (_value: any) => (
      <div style={{ minWidth: 60, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 60 }}>品名CD</div>,
    dataIndex: 'code24',
    key: 'code24',
    render: (_value: any) => (
      <div style={{ minWidth: 60, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 500 }}>品名</div>,
    dataIndex: 'kikaku',
    key: 'kikaku',
    render: (_value: any) => (
      <div style={{ minWidth: 500, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 80 }}>数量</div>,
    dataIndex: 'suryou',
    key: 'suryou',
    align: 'right',
    render: (_value: any) => (
      <div style={{ minWidth: 80, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 80 }}>入力日</div>,
    dataIndex: 'inpDt',
    key: 'inpDt',
    render: (_value: any) => (
      <div style={{ minWidth: 80, width: 'max-content' }}>{_value}</div>
    ),
  },
];
