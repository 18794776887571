import { API_URL } from 'constants/api';
import { useMutation } from 'react-query';
import AxiosPost from 'utils/axios/axiosPost';
import AxiosGetFile from 'utils/axios/getFile/axiosGetFile';

interface TypeProps {
  fromDt: string | null;
  toDt: string | null;
  denKbn: string | null;
}

export function useGetShippingProduct() {
  const getProduct = async ({ fromDt, toDt, denKbn }: TypeProps) => {
    const params = {
      fromDt,
      toDt,
      denKbn,
    };
    return await AxiosPost(API_URL.GET_SHIPPINGTOTALBYPRODUCT, params);
  };

  const mutation = useMutation(getProduct as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });
  return mutation;
}

export function useGetShippingProductExcel() {
  const getProduct = async ({ fromDt, toDt, denKbn }: TypeProps) => {
    const params = {
      fromDt,
      toDt,
      denKbn,
    };
    return await AxiosGetFile(API_URL.GET_SHIPPINGTOTALBYPRODUCT_EXCEL, params);
  };

  const mutation = useMutation(getProduct as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });

  return mutation;
}
