import { API_URL } from 'constants/api';
import { useMutation } from 'react-query';
import AxiosPost from 'utils/axios/axiosPost';
import AxiosGetFile from 'utils/axios/getFile/axiosGetFile';

interface TypeProps {
  torihikicd: string | null;
  kaisyaH: string | null;
  kaisya: string | null;
  kigyoGroups: any;
}

export function useGetCooperatingStoreInformation() {
  const getCooperating = async ({
    torihikicd,
    kaisyaH,
    kaisya,
    kigyoGroups,
  }: TypeProps) => {
    const params = {
      torihikicd,
      kaisyaH,
      kaisya,
      kigyoGroups,
    };
    return await AxiosPost(API_URL.GET_COOPERATINGSTOREINFORMATION, params);
  };

  const mutation = useMutation(getCooperating as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });
  return mutation;
}

export function useGetCooperatingStoreInformationExcel() {
  const getCooperating = async ({
    torihikicd,
    kaisyaH,
    kaisya,
    kigyoGroups,
  }: TypeProps) => {
    const params = {
      torihikicd,
      kaisyaH,
      kaisya,
      kigyoGroups,
    };
    return await AxiosGetFile(
      API_URL.GET_COOPERATINGSTOREINFORMATION_EXCEL,
      params,
    );
  };

  const mutation = useMutation(getCooperating as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });

  return mutation;
}
