import {
  LocalizationMap,
  Viewer,
  Worker,
  OpenFile,
} from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/drop/lib/styles/index.css';
import '@react-pdf-viewer/highlight/lib/styles/index.css';
import { dropPlugin } from '@react-pdf-viewer/drop';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import jp_JP from '@react-pdf-viewer/locales/lib/jp_JP.json';
import { BodyContent, Wrapper } from '../webSearchMenu.style';
import { getFilePlugin } from '@react-pdf-viewer/get-file';

declare const window: any;
const ViewerPDF = () => {
  window.urlPdf && localStorage.setItem('pdf', JSON.stringify(window.urlPdf));

  const { url, fileName } =
    localStorage.getItem('pdf') &&
    JSON.parse(localStorage.getItem('pdf') || '{}');

  const getFilePluginInstance = getFilePlugin({
    fileNameGenerator: (_file: OpenFile) => {
      return fileName;
    },
  });

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: defaultTabs => [
      defaultTabs[0], // Thumbnails tab
    ],
  });
  const dropPluginInstance = dropPlugin();
  const element = document.querySelectorAll(
    "[data-testid^='print__button']",
  )?.[0];
  element?.addEventListener(
    'click',
    function (e) {
      e.stopPropagation();
      printIframe('print-view');
    },
    false,
  );

  const elementDownbload: any = document.getElementsByClassName(
    'rpv-core__minimal-button',
  );
  const elementNonDownbload = document.querySelectorAll(
    "[data-testid^='get-file__download-button']",
  )?.[1];

  elementNonDownbload?.addEventListener(
    'click',
    function (e) {
      e.stopPropagation();
      elementDownbload[0].click();
    },
    false,
  );

  function printIframe(id: string) {
    let doc: any = document;
    const iframe = doc.frames ? doc.frames[id] : doc.getElementById(id);
    const iframeWindow = iframe.contentWindow || iframe;
    iframe.focus();
    iframeWindow.print();
    return false;
  }

  const { DownloadButton } = getFilePluginInstance;

  return (
    <Wrapper>
      <BodyContent>
        <div style={{ display: 'none' }}>
          <DownloadButton />
        </div>
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.14.305/build/pdf.worker.js">
          <div
            style={{
              display: 'flex',
              height: '95vh',
              padding: '10px',
              width: '100vw',
              justifyContent: 'center',
            }}
          >
            <Viewer
              localization={jp_JP as unknown as LocalizationMap}
              fileUrl={url}
              plugins={[
                defaultLayoutPluginInstance,
                dropPluginInstance,
                getFilePluginInstance,
              ]}
            />
          </div>
        </Worker>
      </BodyContent>
      <iframe
        key={1}
        id="print-view"
        src={url}
        style={{ display: 'none' }}
        title={'注文書(控)'}
      />
    </Wrapper>
  );
};

export default ViewerPDF;
