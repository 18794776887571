import { Space } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 990px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .ant-btn {
    font-weight: 500;
  }
  button {
    min-width: 100px;
  }
  .results-wrapper {
    * {
      margin: 0;
      line-height: 1.2;
    }
    br {
      content: '';
      margin: 12px;
      display: block;
    }
    font-size: 12pt;
    width: 100%;
  }
`;

export const Guidance = styled.div`
  width: 100%;
  padding: 8px;
  background-color: #eeeeff;
  border: solid 1px #8080f0;
  border-radius: 4px;
  margin-bottom: 20px;
`;

export const Footer = styled(Space)`
  width: 100%;
  justify-content: flex-end;
`;
