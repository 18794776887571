import { useEffect, useState } from 'react';
import { CustomTable } from './tableCommon.style';

interface Props {
  dataSource?: any;
  expandable?: any;
  columns?: any;
  isLoading?: boolean;
  setIsLoading?: any;
  limitTable?: number;
  lastId?: any;
  debug?: boolean;
  loadingIndicator?: boolean;
  scroll?: any;
  height?: string;
  width?: string;
  rowSelection?: any;
  rowKey?: any;
  className?: string;
  components?: any;
  fromVirtual?: boolean;
  checkChangeValue?: any;
  expandedRowKeys?: any;
  footer?: any;
  onRow?: any;
  summary?: any;
}

const TableCustom = ({
  debug,
  dataSource,
  columns,
  isLoading,
  setIsLoading,
  limitTable = 20,
  height,
  rowSelection,
  rowKey,
  className,
  components,
  checkChangeValue,
  onRow,
  ...props
}: Props) => {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    setRows(dataSource);
  }, [dataSource]);

  return (
    <CustomTable
      className={className}
      height={height}
      rowKey={rowKey}
      rowClassName={(record, index) => {
        if (checkChangeValue) {
          return index % 2 === 0
            ? `${checkChangeValue && checkChangeValue(record)} table-row-light`
            : `${checkChangeValue && checkChangeValue(record)} table-row-dark`;
        } else {
          return index % 2 === 0 ? `table-row-light` : `table-row-dark`;
        }
      }}
      loading={isLoading}
      columns={columns}
      dataSource={rows}
      scroll={{
        scrollToFirstRowOnChange: false,
        y: height ?? 475,
      }}
      pagination={false}
      rowSelection={rowSelection}
      components={components}
      locale={{
        emptyText: <span></span>,
      }}
      onRow={onRow}
      {...props}
    />
  );
};

export default TableCustom;
