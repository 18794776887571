import { API_URL } from 'constants/api';
import { useMutation } from 'react-query';
import AxiosPost from 'utils/axios/axiosPost';
import AxiosGetFile from 'utils/axios/getFile/axiosGetFile';

interface TypeProps {
  fromDt: string | null;
  toDt: string | null;
}

export function useGetCollectingGoodsByProduct() {
  const getCollecting = async ({ fromDt, toDt }: TypeProps) => {
    const params = {
      fromDt,
      toDt,
    };
    return await AxiosPost(API_URL.GET_COLLECTINGGOODSBYPRODUCT, params);
  };

  const mutation = useMutation(getCollecting as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });

  return mutation;
}

export function useGetCollectingGoodsByProductExcel() {
  const getCollecting = async ({ fromDt, toDt }: TypeProps) => {
    const params = {
      fromDt,
      toDt,
    };
    return await AxiosGetFile(
      API_URL.GET_COLLECTINGGOODSBYPRODUCT_EXCEL,
      params,
    );
  };

  const mutation = useMutation(getCollecting as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });

  return mutation;
}
