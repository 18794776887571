export const columns = [
  {
    title: () => <div style={{ minWidth: 30 }}>No</div>,
    dataIndex: 'id',
    key: 'id',
    align: 'right',
    render: (_value: any, record: any, index: any) => (
      <div style={{ minWidth: 30, width: 'max-content' }}>{index + 1}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 60 }}>ﾛｹｰｼｮﾝ</div>,
    dataIndex: 'maxLocation',
    key: 'maxLocation',
    render: (_value: any) => (
      <div style={{ minWidth: 60, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 60 }}>品名CD</div>,
    dataIndex: 'code24',
    key: 'code24',
    render: (_value: any) => (
      <div style={{ minWidth: 60, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 350 }}>品名</div>,
    dataIndex: 'nnm',
    key: 'nnm',
    render: (_value: any) => (
      <div style={{ minWidth: 350, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 60 }}>規格</div>,
    dataIndex: 'nnmsz',
    key: 'nnmsz',
    render: (_value: any) => (
      <div style={{ minWidth: 60, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 40 }}>回数</div>,
    dataIndex: 'countCode24',
    key: 'countCode24',
    align: 'right',
    render: (_value: any) => (
      <div style={{ minWidth: 40, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 60 }}>数量合計</div>,
    dataIndex: 'sumSuryou',
    key: 'sumSuryou',
    align: 'right',
    render: (_value: any) => (
      <div style={{ minWidth: 60, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 65 }}>最大在庫</div>,
    dataIndex: 'maxZaikomax',
    key: 'maxZaikomax',
    align: 'right',
    render: (_value: any) => (
      <div style={{ minWidth: 65, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 45 }}>発注点</div>,
    dataIndex: 'maxOrderpoint',
    key: 'maxOrderpoint',
    align: 'right',
    render: (_value: any) => (
      <div style={{ minWidth: 45, width: 'max-content' }}>{_value}</div>
    ),
  },
];
