/* eslint-disable no-useless-escape */
export const TEXT_ALL = Object.freeze({
  SAMA: '様',
  TITLE_ERROR: 'エラー',
  TITLE_CONFIRM: '確認してください',
});

export const MSG_ERROR: any = Object.freeze({
  // eslint-disable-next-line no-useless-escape
  MSG_OVER_BARA: '現在の数量を超える数量を入力できません',
  MSG_VALUES_CHANGE: '行った変更は保存されない場合があります。',
  MSG_INVALID_CHAR: `に次の文字は入力できません(! % , " ' ;)`,
  DEFAULT: 'ERROR!',
  MSG_ERROR1: '※ログインに失敗しました。',
  MSG_ERROR2: '再度入力をお願い致します。',
  MSG_LOGIN_ERROR:
    'ユーザーIDまたはパスワードに誤りがあります。再度入力をお願い致します。',
  MSG_SYS_ERROR:
    'システムにログインしているユーザ情報が複数人存在します。システムが異常状態にあります。',
  MSG_CUS_NOTFOUND:
    'ユーザはシステムにログインしていません。ログインしているユーザ情報が見つかりません。Loginしてからアクセスして下さい。',
  MSG_OVER_MAX_CNT:
    '表示可能な最大件数を超えました。該当データは、%該当件数%件です。上位2000件を表示します。',
  MSG_NO_DATA: '該当データはありません',
  A_LOG_GYOUMU_ID: '取引先検索',
  MSG_DATE_ANY_INPUT: 'は少なくてもどちらか一方を入力して下さい',
  MSG_NO_FILE_PDF: '商品データが用意されておりません',
  MSG_ORDER_MAX_OVER:
    '注文可能な最大商品数を超えています。最大24明細まで注文できます。',
  MSG_FILE_NOT_FOUND: '商品仕様ファイルがありません',
  MSG_CONFIRM: '終了します。よろしいですか?',
  MSG_INPUT: 'を入力してください',
  MSG_PASSDAY: '入力日に本日以前（本日含む）の日付を指定してください',
  MSG_HANKAKU_EISU: 'に半角英数字以外は入力できません',
  MSG_TORIHIKI_NOT_EXIST: '取引先情報が登録されていません',
  MSG_ZENKAKU: 'に全角文字は入力できません',
  MSG_NOT_PASSDAY: '受取日に本日以前の日付は指定できません',
  MSG_SYOHIN_NOT_EXIST: '商品情報が登録されていません',
  MSG_001: 'に全角文字は入力できません',
  MSG_002: 'に半角文字は入力できません',
  MSG_003: `に次の文字は入力できません(! % , " ' ;)`,
  MSG_004: 'に半角英数字以外は入力できません',
  MSG_005: '削除する「行」を選択してください',
  MSG_006: '該当する取引先（コード）は、登録されておりません',
  MSG_007: 'を入力してください',
  MSG_008: 'を選択してください',
  MSG_009: '該当する商品（コード）は、登録されておりません',
  MSG_010: 'に半角数字以外は入力できません',
  MSG_011: '過去の日付は入力はできません',
  MSG_012: '該当する班（コード）は、登録されておりません',
  MSG_013: '該当するお届け先（コード）は、登録されておりません',
  MSG_014: 'ご注文担当者が登録されておりません',
  MSG_015: '注文する商品（数量）の入力をしてください',
  MSG_016: '品名コードを入力してください',
  MSG_017: '班（便）情報が登録されておりません',
  MSG_018: 'ご注文数を再度確認ください',
  MSG_019: 'ご注文数に0は入力できません',
  MSG_020: '注文する品目数がオーバーしました（1品目削除してください）',
  MSG_021: 'ご注文数は「９９９９」以下で入力してください',
  MSG_022: '入力中に在庫数が変動しました、確認の上、ご注文数を調整してください',
  MSG_023: '共同倉庫の納品は「東雲引取」のみです',
  MSG_024: '分納商品の為、在庫を超える分は次行に入力してください',
  MSG_025: 'この品名は%行番号%行目ですでに入力されています',
  MSG_026: 'オンライン顧客情報が登録されておりません',
  MSG_027: '一ヶ月以上先の納期は受付（入力）できません',
  MSG_028: 'ご注文有難うございました、受付番号は %受付番号% です',
  MSG_029:
    '注文処理でエラーが発生しました。ご注文ができませんでした。（YHK_WRKORDER書き込み失敗）',
  MSG_030: 'この取引先は入力できません',
  MSG_032: '在庫不足のため、分割（2回）納品になります',
  MSG_033: '締め切り時刻が間近です',
  MSG_034:
    'このまま処理を続けると2重注文の危険があります。お手数ですが、一旦ご注文画面を閉じ、再度メニューよりご注文を選択してください。',
  MSG_035: 'の入力桁数が最大桁数をオーバーしています。',
  MSG_036: '注文処理でエラーが発生しました。',
  MSG_037:
    '複数ＩＤでログインされています。お手数ですが、一旦すべてのブラウザ画面を閉じ、再度メニューよりご注文を選択してください。',
  MSG_038: '取引先コードを入力してください',
  MSG_DATE_FORMAT: 'は数値8桁（例：20070401）で入力してください',
  MSG_DATE_FORMAT_YYMMDD: 'は数値6桁（例：070401）で入力してください',
  MSG_DATE_FORMAT_YEAR: 'の年部分に誤りがあります',
  MSG_DATE_FORMAT_MONTH: 'の月部分に誤りがあります',
  MSG_DATE_FORMAT_DAY: 'の日部分に誤りがあります',
  MSG_DATE_RANGE: 'の範囲が正しくありません',
  MSG_DATE_RANGE_OVER: ' の範囲が最大期間（１年）を超えています',
  MSG_NENGETU_INVALID: '対象年月は数値6桁（例：200704）で入力してください',
  MSG_GASMATER_INVALID: 'ガスメーターが不正です',
  MSG_CALENDAR_NOT_EXIST: 'カレンダー情報が登録されていません',
  MSG_CALENDAR_EXIST: 'カレンダー情報が登録されています',
  MSG_CALENDAR_DELETED: 'カレンダー情報が削除されています',
  MSG_CALENDAR_INSERT_SUCCESS: 'カレンダー情報を登録しました',
  MSG_CALENDAR_UPDATE_SUCCESS: 'カレンダー情報を修正しました',
  MSG_CALENDAR_INSERT_ERR: 'カレンダー情報の登録に失敗しました',
  MSG_CALENDAR_UPDATE_ERR: 'カレンダー情報の修正に失敗しました',
  MSG_NUMERICLETTER: 'に半角文字以外は入力できません',
  MSG_NUMERIC: 'に半角数字以外は入力できません',
  MSG_HANKAKU: 'に半角文字は入力できません',
  MSG_LENGTH: 'の入力文字数がオーバーしています',
  MSG_SELECT: 'を選択してください',
  MSG_FORMATERR_TANTOUCD:
    '担当者コードに0以外の半角数字、半角アルファベット以外は入力できません',
  MSG_TORIHIKI_DELETED: '取引先情報が削除されています',
  MSG_TANTOU_EXIST: '担当者情報は登録できません',
  MSG_TANTOU_NOT_EXIST: '担当者情報が登録されていません',
  MSG_TANTOU_DELETED: '担当者情報が削除されています',
  MSG_TANTOU_INSERT_SUCCESS: '担当者情報を登録しました',
  MSG_TANTOU_UPDATE_SUCCESS: '担当者情報を修正しました',
  MSG_TANTOU_DELETE_SUCCESS: '担当者情報を削除しました',
  MSG_TANTOU_RESTORE_SUCCESS: '担当者情報を復活しました',
  MSG_INSERT_SUCCESS: 'カレンダー情報を登録しました',
  MSG_UPDATE_SUCCESS: 'カレンダー情報を修正しました',
  MSG_INSERT_ERR: 'カレンダー情報の登録に失敗しました',
  MSG_UPDATE_ERR: 'カレンダー情報の修正に失敗しました',
  MSG_LOGINUSER_CANNOT_DELETE: 'ログインユーザーの取引先情報は削除できません',
  MSG_SOKO_CANNOT_DELETE: '倉庫の取引先情報は削除できません',
  MSG_NOT_MATCH: 'が一致しません',
  MSG_TORIHIKI_EXIST: '取引先情報は登録済みです',
  MSG_DUPLICATE_ID: 'ユーザーIDは使用されています。',
  MSG_MASTER_CHECK:
    '以下のマスタに取引先の情報が登録されている為、削除できません',
  MSG_DELETE_SUCCESS: '取引先情報を削除しました',
  MSG_TORIHIKISAKI_EMPTY: '取引先が登録されていません',
  MSG_KEYMAN_EMPTY: 'ご注文担当が登録されていません',
  MSG_HAN_EMPTY: '班が登録されていません',
  MSG_OTODOKESAKI_EMPTY: 'お届け先が登録されていません',
  MSG_OLKYAKUM_EMPTY: 'オンライン顧客マスタが登録されていません',
  MSG_CODE5_EMPTY: '商品分類が登録されていません',
  MSG_SIIREM_EMPTY: '仕入先が登録されていません',
  MSG_BINM_EMPTY: '便が登録されていません',
  MSG_SYOHINM_EMPTY: '商品が登録されていません',
  MSG_SOKOM_EMPTY: '倉庫が登録されていません',
  MSG_SHIMEM_EMPTY: '%該当区分%の締め時間が登録されていません',
  MSG_SYOHIN_SIIRE_HAT: '仕入先が橋本総業の商品は登録されていません',
  MSG_SYOHIN_SIIRE_OTHER: '仕入先が橋本総業以外の商品は登録されていません',
  MSG_DOUBLE: 'に半角数字、小数点(.)以外は入力できません',
  MSG_DOUBLE_LENGTH:
    'は整数部%整数部%桁、小数部%小数部%桁以内で入力してください',
  MSG_HAT_SYOHIN_NOT_EXIST: 'HAT商品は登録されていません',
  MSG_SYOHIN_EXIST: '商品情報は登録済みです',
  MSG_SYOHIN_MAINTE_NOT_EXIST: '商品情報が登録されていません',
  MSG_CODE5M_NOT_EXIST: '商品分類情報が登録されていません',
  MSG_SIIREM_NOT_EXIST: '仕入先情報が登録されていません',
  MSG_SYOHIN_DELETED: '商品情報が削除されています',
  MSG_INSERT_SYOHIN_MAINTE_SUCCESS: '商品情報を登録しました',
  MSG_UPDATE_SYOHIN_MAINTE_SUCCESS: '商品情報を修正しました',
  MSG_DELETE_SYOHIN_MAINTE_SUCCESS: '商品情報を削除しました',
  MSG_PBAISU_NOT_INPUT_ZERO: 'ピッキング出力条件の倍数に0は入力できません',
  MSG_ARRIVALCONTROL_INPUT_OVER:
    '入荷調整日にリードタイム以上の日数は指定できません',
  MSG_ARRIVALCONTROL_INPUT_OVER_MINUS:
    '入荷調整日には0を指定して下さい（リードタイムがマイナスです）',
  MSG_LEADTIME_INPUT_OVER: 'リードタイムは-1から99の範囲で指定して下さい',
  MSG_OTODOKESAKI_DELETED: 'お届け先情報が削除されています',
  MSG_OTODOKESAKI_EXIST: 'お届け先情報(%お届け先コード%)は登録できません',
  MSG_DELETE_HAN_SUCCESS: '班情報を削除しました',
  MSG_DELETE_OTODOKESAKI_SUCCESS: 'お届け先（便）情報を削除しました',
  MSG_BIN_EXIST: '便情報(%お届け先コード%)は登録できません',
  MSG_INSERT_HANOTO_SUCCESS: '班、お届け先（便）情報を登録しました',
  MSG_UPDATE_HANOTO_SUCCESS: '班、お届け先（便）情報を修正しました',
  MSG_DELETE_GROUP_ADDRESS_SUCCESS: '班、お届け先（便）情報を削除しました',
  MSG_CONFIRM_INSERT_SYOHIN_MAINTE: '商品情報を登録してもよろしいですか',
  MSG_CONFIRM_UPDATE_SYOHIN_MAINTE: '商品情報を修正してもよろしいですか',
  MSG_CONFIRM_DELETE_SYOHIN_MAINTE: '商品情報を削除してもよろしいですか',
  MSG_CONFIRM_INSERT_TORIHIKI_MAINTE: '取引先情報を登録してもよろしいですか',
  MSG_CONFIRM_UPDATE_TORIHIKI_MAINTE: '取引先情報を修正してもよろしいですか',
  MSG_CONFIRM_DELETE_TORIHIKI_MAINTE: '取引先情報を削除してもよろしいですか',
  MSG_INSERT_TORIHIKI_MAINTE_SUCCESS: '取引先情報を登録しました',
  MSG_UPDATE_TORIHIKI_MAINTE_SUCCESS: '取引先情報を修正しました',
  MSG_DELETE_TORIHIKI_MAINTE_SUCCESS: '取引先情報を削除しました',
  MSG_HAN_DELETED: '班情報が削除されています',
  MSG_HAN_NOT_EXIST: '班情報が登録されていません',
  MSG_HAN_EXIST: '班情報は登録済みです',
  MSG_RESTORE_HAN_SUCCESS: ' 班、お届け先（便）情報を復活しました。',
  MSG_RESTORE_HAN_FAILED: '班、お届け先（便）情報の復活に失敗しました。',
  MSG_RESTORE_ADDRESS_SUCCESS: 'お届け先（便）情報を復活しました。',
  MSG_RESTORE_ADDRESS_FAILED: 'お届け先（便）情報の復活に失敗しました。',
  MSG_SHIPPING_UPDATE_CONFLICT:
    '出荷情報が更新されましたので、再度やり直してください',
  MSG_UPDATE_SHIPPING_SUCCESS: '出荷情報を更新しました',
  MSG_REPRINT_SUCCESS: '出荷情報を再出力しました',
  NOT_EXIST: 'NOT_EXIST',
  CAN_NOT_EDIT: 'CAN_NOT_EDIT',
  RECEIVE_CONFLICT: '入荷情報が更新されましたので、再度やり直してください',
  MSG_UPDATE_RECEIVE_SUCCESS: '入荷情報を登録しました',
  MSG_OVER_HACHUZAN: '発注残を超えています。発注残以下の数量を入力してください',
  MSG_CREATE_STOCK_REFILL_RECEIVE_SUCCESS: '入荷情報を登録しました',
  MSG_DELETE_BACKLOG_SUCCESS: '注残を削除しました',
  MSG_NOT_BELONG_TO_SIIRE: '商品と仕入先の組み合わせが誤っています。',
  MSG_SHIPPING_NOT_EXIST: '出荷情報が見つかりません',
  MSG_SHIPPING_CANNOT_EDIT: 'この出荷は更新できる状態ではありません。',
  MSG_SPECIAL_CHARS: `に次の文字は入力できません(! % , \\" ' ;)`,
  TROKNO_NOT_EXIST: '登録番号が登録されておりません',
  TROKNO_ALREADY_PROCESS: '登録番号が既に進行しております。',
  ZAIKO_IS_NOT_ENOUGH: '在庫不足中のため変更できません．',
  REFILL_CONFLICT: 'この登録番号は既に登録済みです',
  ZAIKO_EXCEED_RANGE: '在庫数上限を超えているため、返品できません．',
  MSG_UPDATE_CONFLICT: 'このデータは他の人によって更新されました。確認お願いします。',
  MSG_REQUEST_UPDATE_STOCK_REFILL_EMPTY:"入荷商品はありません。"
});
