import { Button, Checkbox, Form, Input, Space } from 'antd';
import { useGetStockOrder } from 'api/stockOrder';
import LoadingCallAPI from 'components/LoadingCallAPI/LoadingCallAPI';
import TableCustom from 'components/TableCommon';
import { PATH } from 'configs/routes';
import { IsHankakuEisu, ToASCII } from 'constants/common';
import { MSG_ERROR, TEXT_ALL } from 'constants/text';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectUserInfo } from 'slice/app';
import messagePopup from 'utils/message/mesage';

import {
  FooterForm,
  FormWrapper,
  HeaderForm,
  WrapperMainte,
} from 'views/login/FormLogin/formLogin.style';
import InputRefill from './InputRefill';
import StockRefillOrderConfirm from './stockRefillOrderConfirm';

const InputStockRefillOrder = () => {
  const userInfo = useSelector(selectUserInfo);
  const labelTokuisaki =
    (userInfo.kaisya ?? '') + (userInfo.tensyo ?? '') + TEXT_ALL.SAMA;

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const formRef = React.useRef<any>({});
  const inputRef = React.useRef<any>([]);
  const previousValues = React.useRef<any>();
  const [listStock, setListStock] = useState<any>([]);
  const [record, setRecord] = useState<any>({});
  const [isVisibleConfirm, setIsVisibleConfirm] = useState(false);

  /**
   * *API GetStockOrder
   * todo Get Stock Order info to init
   * @param trokno
   */
  const {
    mutate: mutateStockOrder,
    data: dataStockOrder,
    isLoading: loadingStockOrder,
    reset: resetStockOrder,
  } = useGetStockOrder();

  const columns: any = (
    lstStock: any,
    setLstStock: any,
    inputRef: any,
    formRef: any,
  ) => [
    {
      title: () => <div style={{ minWidth: 60 }}>削除</div>,
      dataIndex: 'check',
      key: 'check',
      render: (_value: any, record: any) => (
        <div
          style={{
            minWidth: 60,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Checkbox
            onChange={(event: any) => {
              let updateArray: any;
              if (event.target.checked) {
                let checkedStock = {
                  ...record,
                  check: true,
                  hatchusuu: '0',
                  errorHatChuSuu: false,
                  msgErrorHatChuSuu: '',
                };
                updateArray = lstStock?.map((u: any) =>
                  u?.index !== checkedStock.index ? u : checkedStock,
                );
              } else {
                let updateStock = {
                  ...record,
                  check: false,
                  hatchusuu:
                    isNaN(parseInt(record.previousHatchusuu)) ||
                    parseInt(record.previousHatchusuu) === 0
                      ? ''
                      : record.previousHatchusuu,
                };
                updateArray = lstStock?.map((u: any) =>
                  u?.index !== updateStock.index ? u : updateStock,
                );
              }
              setLstStock(updateArray);
            }}
          />
        </div>
      ),
    },
    {
      title: () => <div style={{ minWidth: 40 }}>№</div>,
      dataIndex: 'index',
      key: 'index',
      render: (_value: any) => (
        <div style={{ textAlign: 'right', minWidth: 40 }}>{_value}</div>
      ),
    },
    {
      title: () => <div style={{ minWidth: 110 }}>注番</div>,
      dataIndex: 'chuno',
      key: 'chuno',
      render: (_value: any) => (
        <div
          style={{
            minWidth: 110,
            textAlign: 'center',
          }}
        >
          {_value}
        </div>
      ),
    },
    {
      title: () => <div style={{ minWidth: 110 }}>品名CD</div>,
      dataIndex: 'cd24',
      key: 'cd24',
      render: (_value: any) => (
        <div
          style={{
            minWidth: 110,
            textAlign: 'center',
          }}
        >
          {_value}
        </div>
      ),
    },
    {
      title: () => <div style={{ minWidth: 550 }}>品名</div>,
      dataIndex: 'nnm',
      key: 'nnm',
      render: (_value: any) => (
        <div
          style={{
            minWidth: 550,
            textAlign: 'left',
          }}
        >
          {_value}
        </div>
      ),
    },

    {
      title: () => <div style={{ minWidth: 60 }}>数量</div>,
      dataIndex: 'suu',
      key: 'suu',
      render: (_value: any) => (
        <div
          style={{
            minWidth: 60,
            textAlign: 'right',
          }}
        >
          {_value}
        </div>
      ),
    },
    {
      title: () => <div style={{ minWidth: 90 }}>変更数量</div>,
      dataIndex: 'hatchusuu',
      key: 'hatchusuu',
      render: (_value: any, record: any) => (
        <div style={{ minWidth: 90, padding: '2px 4px' }}>
          <InputRefill
            dfValue={_value || ''}
            lstStock={lstStock}
            setLstStock={setLstStock}
            record={record}
            inputRef={inputRef}
            formRef={formRef}
          />
        </div>
      ),
    },
  ];

  // Click buttom close form
  const closeOrder = () => {
    messagePopup({
      type: 'question',
      content: '終了します。よろしいですか?',
      onOk: () => {
        navigate(PATH.TOP);
      },
      onCancel: () => formRef?.current?.btnClose?.focus(),
    });
  };

  const onFinish = (values: any) => {
    const requestLstStock = listStock.map((stock: any) => ({
      ...stock,
      hatchusuu: parseFloat(stock.hatchusuu || stock.suu),
    }));
    setRecord({ header: form.getFieldsValue(true), trokno: requestLstStock });
    if (checkLstStock(listStock)) {
      setIsVisibleConfirm(true);
    }
  };

  const onFinishFailed = ({ values, errorFields }: any) => {
    // Show the first error from form's validator and focus on the error field after click on OK button
    messagePopup({
      type: 'info',
      content: errorFields[0]?.errors[0],
      onOk: () => {
        formRef?.current[errorFields[0]?.name[0]]?.select();
      },
    });
  };

  const clearScreen = () => {
    resetStockOrder();
    form.resetFields();
    setListStock([]);
    formRef?.current?.trokno?.focus();
  };

  function checkLstStock(lstStock: any) {
    const errorHatChuSuu = lstStock.find(
      (record: any) => record.errorHatChuSuu === true,
    );

    if (!!errorHatChuSuu) {
      messagePopup({
        type: 'info',
        content: errorHatChuSuu.msgErrorHatChuSuu,
        onOk: () => errorHatChuSuu?.focusHatChuSuu(),
      });
      return false;
    }
    return true;
  }

  useEffect(() => {
    clearScreen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <WrapperMainte>
      {loadingStockOrder && <LoadingCallAPI />}
      <FormWrapper
        form={form}
        className="form-mainte"
        name="input-stockrefillorder"
        labelCol={{ flex: '150px' }}
        wrapperCol={{ flex: 1 }}
        onFinish={onFinish}
        requiredMark={false}
        autoComplete="false"
        labelAlign="left"
        colon={false}
        validateTrigger="onSubmit"
        onFinishFailed={onFinishFailed}
        scrollToFirstError
        onKeyPress={e => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
        initialValues={{
          trokno: '',
        }}
      >
        <HeaderForm>
          <span>{labelTokuisaki}</span>
        </HeaderForm>

        <Form.Item
          label={
            <label>
              登録番号<label style={{ color: 'red' }}>【必須】</label>
            </label>
          }
          className={dataStockOrder ? 'disable-input' : ''}
        >
          <Space size={4} style={{ display: 'flex', alignItems: 'center' }}>
            <Form.Item
              noStyle
              name="trokno"
              rules={[
                () => ({
                  validator(_: any, value: string) {
                    if (!value) {
                      return Promise.reject(
                        new Error('登録番号' + MSG_ERROR['MSG_INPUT']),
                      );
                    }
                    if (IsHankakuEisu(value)) {
                      return Promise.reject(
                        new Error('登録番号' + MSG_ERROR['MSG_HANKAKU_EISU']),
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input
                autoFocus
                ref={el => (formRef.current.trokno = el)}
                disabled={dataStockOrder}
                maxLength={6}
                style={{ width: 125 }}
                onBlur={(event: any) => {
                  let value = ToASCII(event.target.value, true, true).trim();
                  form.setFieldsValue({
                    trokno: value,
                  });
                  if (previousValues.current !== value) {
                    form
                      .validateFields(['trokno'])
                      .then(res => {
                        const _value = res.trokno;
                        mutateStockOrder(
                          { trokno: _value },
                          {
                            onSuccess(data, variables, context) {
                              if (data && data?.data?.length > 0) {
                                form.setFieldsValue({
                                  trokno: _value,
                                  zyugen: data?.data[0]?.zyugen,
                                  siirenm: data?.data[0]?.siirenm,
                                  noki: moment(data?.data[0]?.noki).format(
                                    'YYMMDD',
                                  ),
                                  nokiFull: moment(data?.data[0]?.noki).format(
                                    'YYYY/MM/DD (ddd)',
                                  ),
                                });

                                setListStock(
                                  data?.data?.map((item: any, index: any) => {
                                    return {
                                      ...item,
                                      index: index + 1,
                                      check: false,
                                      hatchusuu: '',
                                      previousHatchusuu: '',
                                    };
                                  }),
                                );
                                setTimeout(() => {
                                  inputRef?.current[1].focus();
                                }, 100);
                              } else {
                                messagePopup({
                                  type: 'info',
                                  content: MSG_ERROR['TROKNO_NOT_EXIST'],
                                  onOk: () => {
                                    formRef.current?.trokno?.select();
                                  },
                                });
                              }
                            },
                            onError(error, variables, context) {
                              messagePopup({
                                type: 'info',
                                content:
                                  MSG_ERROR[
                                    (error as any)?.response?.data?.message
                                  ] || error?.response?.data?.message,
                                onOk: () => {
                                  formRef.current?.trokno?.select();
                                },
                              });
                            },
                          },
                        );
                      })
                      .catch(({ values, errorFields }) =>
                        messagePopup({
                          type: 'info',
                          content: errorFields[0]?.errors[0],
                          onOk: () => {
                            formRef?.current[errorFields[0]?.name[0]]?.select();
                          },
                        }),
                      );
                  }
                }}
                onKeyUp={event =>
                  (event.key === 'Enter' || event.key === 'Tab') &&
                  event.currentTarget.select()
                }
                onFocus={(event: any) => {
                  previousValues.current = event.target.value;
                  // event.currentTarget.select();
                }}
                onPressEnter={(event: any) => {
                  event.preventDefault();
                  event.target.blur();
                }}
              />
            </Form.Item>
          </Space>
        </Form.Item>
        <Form.Item
          label={<label>仕入先</label>}
          className={dataStockOrder ? 'disable-input' : ''}
        >
          <Space size={4}>
            <Form.Item name="zyugen" noStyle>
              <Input disabled maxLength={6} style={{ width: 125 }}></Input>
            </Form.Item>
            <Form.Item name="siirenm" noStyle>
              <Input disabled maxLength={30} style={{ width: 200 }}></Input>
            </Form.Item>
          </Space>
        </Form.Item>
        <Form.Item
          label={<label>納期</label>}
          className={dataStockOrder ? 'disable-input' : ''}
          style={{ marginBottom: 20 }}
        >
          <Space size={4}>
            <Form.Item name="noki" noStyle>
              <Input disabled maxLength={6} style={{ width: 125 }}></Input>
            </Form.Item>
            <Form.Item name="nokiFull" noStyle>
              <Input
                ref={el => (formRef.current.nokiFull = el)}
                disabled
                maxLength={12}
                style={{ width: 200 }}
              ></Input>
            </Form.Item>
          </Space>
        </Form.Item>
        <TableCustom
          rowKey={'index'}
          height={'290px'}
          columns={columns(listStock, setListStock, inputRef, formRef)}
          dataSource={listStock}
        />
        <FooterForm>
          <Space>
            <Button
              ref={el => (formRef.current.btnSubmit = el)}
              disabled={!dataStockOrder?.data?.length || listStock.length === 0}
              type="primary"
              htmlType="submit"
              form="input-stockrefillorder"
              style={{ minWidth: 100 }}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  form.submit();
                }
              }}
            >
              確定
            </Button>
            <Button
              ref={el => (formRef.current.btnReset = el)}
              type="default"
              onClick={() => {
                messagePopup({
                  type: 'question',
                  content: '画面をクリアします。よろしいですか?',
                  onOk: () => {
                    clearScreen();
                  },
                  onCancel: () => formRef?.current?.btnReset?.focus(),
                });
              }}
              onKeyDown={event => {
                if (event.key === 'Enter') {
                  formRef.current.btnReset.click();
                }
              }}
            >
              画面クリア
            </Button>
            <Button
              ref={el => (formRef.current.btnClose = el)}
              type="default"
              onClick={closeOrder}
              onKeyDown={event => {
                if (event.key === 'Enter') {
                  formRef.current.btnClose.click();
                }
              }}
            >
              メニューへ戻る
            </Button>
          </Space>
        </FooterForm>
      </FormWrapper>
      <StockRefillOrderConfirm
        isModalVisible={isVisibleConfirm}
        setIsModalVisible={setIsVisibleConfirm}
        record={record}
        onResetPage={clearScreen}
        afterClose={() => formRef.current.btnSubmit.focus()}
      />
    </WrapperMainte>
  );
};

export default InputStockRefillOrder;
