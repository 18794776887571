import { API_URL } from 'constants/api';
import { useMutation } from 'react-query';
import AxiosPost from 'utils/axios/axiosPost';

interface GetStockOrderProps {
  trokno: string;
}

export function useGetStockOrder() {
  const getStockOrder = async ({ trokno }: GetStockOrderProps) => {
    const params = {
      trokno,
    };
    return await AxiosPost(API_URL.GET_STOCK, params);
  };

  const mutation = useMutation(getStockOrder as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });

  return mutation;
}

export function useUpdateStockOrder() {
  const updateStockOrder = async ({ arr }: any) => {
    return await AxiosPost(API_URL.UPDATE_STOCK_PROCESS, arr, {});
  };

  const mutation = useMutation(updateStockOrder as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });

  return mutation;
}
