// import { message } from 'antd';
// import axios from 'axios';
// import history from '../history';

// const axiosClient = axios.create({
//   baseURL: process.env.REACT_APP_ENDPOINT,
//   headers: {
//     Accept: 'application/json',
//     'content-type': 'application/json',
//     Pragma: 'no-cache',
//   },
// });

// axiosClient.interceptors.request.use((config: any) => {
//   const token = window.localStorage.getItem('access_token');
//   if (token) {
//     config.headers.Authorization = `Bearer ${token}`;
//   } else {
//     config.headers.LocalIpAddress = '';
//     config.headers.PCHostname = '';
//     config.headers.AppVersion = '';
//   }
//   return config;
// });

// let isTokenExpired = false;

// axiosClient.interceptors.response.use(
//   response => {
//     return response;
//   },
//   error => {
//     const { data, status } = error.response || {};
//     if (
//       (data?.message === 'Token Expired' || status === 401) &&
//       !isTokenExpired
//     ) {
//       isTokenExpired = true;
//       message.warning({ content: 'Expired token!' });
//       setTimeout(() => {
//         localStorage.removeItem('access_token');
//         localStorage.removeItem('userInfo');
//         history.go(0);
//         isTokenExpired = false;
//       }, 3000);
//     }
//     return Promise.reject(error);
//   },
// );

// export default axiosClient;

import axios from 'axios';
import { API_URL } from 'constants/api';
import { SESSION_STORAGE_ITEM } from 'constants/common';
import moment from 'moment';
import history from 'utils/history';
import AxiosPost from './axiosPost';
import getLocalToken from './token';

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_ENDPOINT,
  headers: {
    Accept: 'application/json',
    // 'content-type': 'application/json',
    Pragma: 'no-cache',
  },
});

axiosClient.interceptors.request.use((config: any) => {
  const token = getLocalToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

axiosClient.interceptors.response.use(
  (response: any) => {
    // TODO: handle expired token
    const expiredTime = new Date(
      sessionStorage.getItem(SESSION_STORAGE_ITEM.EXPIREDAT) || '',
    );
    if (moment(expiredTime).diff(moment(), 'minutes') < 60) {
      AxiosPost(API_URL.REFETCH_TOKEN).then((res: any) => {
        const { expiredAt, token } = res?.data;
        if (res?.data) {
          sessionStorage.setItem(SESSION_STORAGE_ITEM.EXPIREDAT, expiredAt);
          sessionStorage.setItem(SESSION_STORAGE_ITEM.ACCESS_TOKEN, token);
        }
      });
    }
    return response;
  },
  (error: any) => {
    if (
      ['MSG_TOKEN_EXPIRED', 'MSG_UNAUTHORIZED'].includes(
        error.response.data.message,
      )
    ) {
      sessionStorage.clear();
      history.go(0);
      // AxiosPost(API_URL.REFETCH_TOKEN).then((res: any) => {
      //   const { expiredAt, token } = res?.data;
      //   if (res?.data) {
      //     sessionStorage.setItem(SESSION_STORAGE_ITEM.EXPIREDAT, expiredAt);
      //     sessionStorage.setItem(SESSION_STORAGE_ITEM.ACCESS_TOKEN, token);
      //   }
      // });
    }
    return Promise.reject(error);
  },
);

export default axiosClient;
