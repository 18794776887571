import { Space } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 990px;
  .ant-btn {
    font-weight: 500;
  }
  button {
    min-width: 100px;
  }
`;

export const Header = styled.div``;

export const Footer = styled(Space)`
  width: 100%;
  justify-content: flex-end;
  margin-top: 40px;
`;

export const BodyContent = styled.div`
  margin-top: 20px;
  #tantou-list {
    border-collapse: collapse;
    td,
    th {
      border: 0.5px solid #9e9e9e;
      padding: 4px 8px;
    }
  }
`;
