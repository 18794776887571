import { Card } from 'antd';
import styled from 'styled-components';

export const LoginContainer = styled.div`
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
`;

export const LoginHeader = styled.div`
  font-size: 30px;
  text-align: center;
  color: blueviolet;
  margin-top: 50px;
`;

export const NoteContent = styled(Card)`
  margin-top: 32px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 8px;
  width: 700px;
  overflow: hidden;
  border: none;
  .ant-card-head {
    background: #3b3bcf;
    border: none;
    .ant-card-head-title {
      padding: 12px 0;
      font-weight: 600;
      font-size: 18px;
      color: #fff;
    }
  }
  .ant-card-body {
    min-height: 245px;
  }
  .link-mail {
    width: 120px;
    margin-left: 10px;
    border-radius: 5px;
    border: 1px solid #fff;
  }
  .link-mail:focus {
    box-shadow: 0 0 0 2px rgba(25, 40, 218, 0.2);
    border-color: #3e52e6;
    border: 1px solid #3e52e6;
  }
`;

export const NoteItem = styled(Card)`
  border: none;
  border-radius: 8px;
  width: 100%;
  overflow: hidden;
  &:last-child {
    margin-top: 16px;
  }
  .ant-card-body {
    min-height: auto;
    padding: 16px 24px;
    border: 1px solid #e4e4e4;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    font-weight: 500;
  }
  .ant-card-head {
    background: #c3c3ff;
    border: none;
    .ant-card-head-wrapper {
      padding: 12px 0;
      flex-direction: column;
      align-items: flex-start;
      .ant-card-head-title {
        padding: 0;
        flex: 1 1 auto;
        font-weight: 500;
        font-size: 18px;
        color: #000;
      }
      .ant-card-extra {
        color: #353535;
        font-size: 0.8rem;
        padding: 0;
        margin: 0;
      }
    }
  }
`;
