import { API_URL } from 'constants/api';
import { useMutation } from 'react-query';
import AxiosPost from 'utils/axios/axiosPost';
import AxiosGetFile from 'utils/axios/getFile/axiosGetFile';

interface TypeProps {
  idCd24: string | null;
  idKana: string | null;
  idNnm: string | null;
  idCd5: string | null;
  idCd5nm: string | null;
}

export function useGetInventoryInformation() {
  const getInventory = async ({
    idCd24,
    idKana,
    idNnm,
    idCd5,
    idCd5nm,
  }: TypeProps) => {
    const params = {
      idCd24,
      idKana,
      idNnm,
      idCd5,
      idCd5nm,
    };
    return await AxiosPost(API_URL.GET_INVENTORYINFORMATION, params);
  };

  const mutation = useMutation(getInventory as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });
  return mutation;
}

export function useGetInventoryInformationExcel() {
  const getInventory = async ({
    idCd24,
    idKana,
    idNnm,
    idCd5,
    idCd5nm,
  }: TypeProps) => {
    const params = {
      idCd24,
      idKana,
      idNnm,
      idCd5,
      idCd5nm,
    };
    return await AxiosGetFile(API_URL.GET_INVENTORYINFORMATION_EXCEL, params);
  };

  const mutation = useMutation(getInventory as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });

  return mutation;
}
