import { API_URL } from 'constants/api';
import { useMutation, useQuery } from 'react-query';
import AxiosDelete from 'utils/axios/axiosDelete';
import AxiosGet from 'utils/axios/axiosGet';
import AxiosPost from 'utils/axios/axiosPost';
import AxiosPut from 'utils/axios/axiosPut';

/**
 * *API GET NOTIFICATION DATA
 * todo Hooks get notification data
 */

export function useGetNotification() {
  const getNotification = async () => {
    return await AxiosGet(API_URL.NOTIFICATION);
  };

  const response = useQuery(['notification'], getNotification, {
    enabled: true,
    retry: 1,
  });

  return response;
}

interface PostNotificationProps {
  data: string;
}

export function usePostNotification() {
  /**
   * *API POST NOTIFICATION DATA
   * todo Hooks post notification data
   * @param API
   */
  const postNotification = async (data: PostNotificationProps) => {
    const body = {
      text: data,
    };
    // console.log(body);
    return await AxiosPost(API_URL.NOTIFICATION, body);
  };

  const mutation = useMutation(postNotification as any, {
    onMutate: data => {
      // console.log(data);
    },
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // console.log(err.response.data.message);
    },
  });

  return mutation;
}

export function useUpdateNotification() {
  /**
   * *API POST NOTIFICATION DATA
   * todo Hooks post notification data
   * @param API
   */
  const putNotification = async (request: any) => {
    return await AxiosPut(API_URL.NOTIFICATION, request, {});
  };

  const mutation = useMutation(putNotification as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // console.log(err.response.data.message);
    },
  });

  return mutation;
}

export function useCreateNotification() {
  /**
   * *API POST NOTIFICATION DATA
   * todo Hooks post notification data
   * @param API
   */
  const createNotification = async (request: any) => {
    return await AxiosPost(API_URL.NOTIFICATION, request, {});
  };

  const mutation = useMutation(createNotification as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // console.log(err.response.data.message);
    },
  });

  return mutation;
}

export function useDeleteNotification() {
  /**
   * *API POST NOTIFICATION DATA
   * todo Hooks post notification data
   * @param API
   */
  const delNotification = async ({ oshiraseId }: any) => {
    return await AxiosDelete(API_URL.NOTIFICATION, {}, { oshiraseId });
  };

  const mutation = useMutation(delNotification as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // console.log(err.response.data.message);
    },
  });

  return mutation;
}

export function useOrderNotification() {
  /**
   * *API POST NOTIFICATION DATA
   * todo Hooks post notification data
   * @param API
   */
  const delNotification = async (lstOrderOsirase: any) => {
    return await AxiosPut(API_URL.NOTIFICATION_ORDER, lstOrderOsirase, {});
  };

  const mutation = useMutation(delNotification as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // console.log(err.response.data.message);
    },
  });

  return mutation;
}

export function useGetNotificationInfo() {
  /**
   * *API POST NOTIFICATION DATA
   * todo Hooks post notification data
   * @param API
   */
  const postNotification = async () => {
    return await AxiosPost(API_URL.NOTIFICATION_INFO);
  };

  const response = useQuery(['list-osirase-mainte'], postNotification, {
    enabled: false,
    retry: 1,
  });

  return response;
}
