import { Button, Checkbox, Space } from 'antd';
import { useLogAccess } from 'api/auth';
import { useUpdateStockReceive } from 'api/stockDetails';
import LoadingCallAPI from 'components/LoadingCallAPI/LoadingCallAPI';
import ModalCustom from 'components/ModalCustom';
import { BodyContent, FlexColumn, FlexEnd } from 'components/StyleCommon';
import TableCustom from 'components/TableCommon';
import { MSG_ERROR } from 'constants/text';
import moment from 'moment';
import { memo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { selectUserInfo } from 'slice/app';
import messagePopup from 'utils/message/mesage';
import {
  Content,
  DetailsOrder,
  DetailsOrderItem,
  Label,
} from 'views/common/orderSheet/orderSheet.style';

interface Props {
  isModalVisible: boolean;
  setIsModalVisible: any;
  record?: any;
  onResetPage?: any;
  afterClose?: any;
}

const StockRefillReceiveConfirm = ({
  isModalVisible,
  setIsModalVisible,
  record,
  onResetPage,
  afterClose,
}: Props) => {
  const userInfo = useSelector(selectUserInfo);
  const btnSubmitRef = useRef<any>(null);

  /**
   * *API LogAccess
   * todo log
   * @param {...}
   */
  const { mutate: logAccess } = useLogAccess();
  /**
   * *API GetStockReceive
   * todo Get GetStockReceive to update
   * @param siirecd
   */
  const {
    mutate: mutationUpdateStockReceive,
    isLoading: loadingUpdateStockReceive,
  } = useUpdateStockReceive();

  const onSubmit = () => {
    let request = record.meisai
      ?.filter((item: any) => !item.del)
      ?.map((item: any) => {
        return {
          code24: item.code24,
          code10: item.cd10,
          nyukaDt: moment(record.header.nyukaDt).format('YYMMDD'),
          siirecd: record.header.siirecd,
          code5: item.code5,
          suryo: item.suryo,
          kanm: item.kanm,
          chuNo2: item.chuNo2,
          nyCnt: item.nyCnt,
          hatchuzan: item.hachuzan,
        };
      });
    messagePopup({
      type: 'question',
      content: '登録します。よろしいですか?',
      onOk: () => {
        mutationUpdateStockReceive(
          { arr: request },
          {
            onSuccess(data, variables, context) {
              // Download file excel
              // let fileName = data.headers['content-disposition'] || 'errorName';
              // const file = new Blob([data?.data], {
              //   type: 'application/octet-stream',
              // });
              // const fileURL = URL.createObjectURL(file);
              // const link = document.createElement('a');
              // link.href = fileURL;

              // link.download = decodeURIComponent(
              //   fileName.split('filename*=')[1].split(';')[0],
              // ).substring(7);
              // link.click();

              logAccess([
                {
                  tokuiCd: userInfo.torihikicd,
                  gyoumuId: '入荷入力',
                  pgName: '呼出モード',
                  logType: '2',
                  action: '呼出モード',
                },
              ] as any);
              messagePopup({
                type: 'success',
                content: MSG_ERROR['MSG_UPDATE_RECEIVE_SUCCESS'],
                onOk: () => {
                  setIsModalVisible(false);
                  onResetPage();
                },
              });
            },
            async onError(error, variables, context) {
              const errorMsg = JSON.parse(
                await error.response.data.text(),
              )?.message;

              messagePopup({
                type: 'error',
                content:
                  MSG_ERROR[errorMsg] ||
                  errorMsg,
              });
            },
          },
        );
      },
      onCancel: () => btnSubmitRef.current.focus(),
    });
  };

  const columns = [
    {
      title: () => <div style={{ minWidth: 40 }}>№</div>,
      dataIndex: 'index',
      key: 'index',
      render: (_value: any) => (
        <div style={{ textAlign: 'center', minWidth: 40 }}>{_value}</div>
      ),
    },
    {
      title: () => <div style={{ minWidth: 60 }}>発注日</div>,
      dataIndex: 'hachubi',
      key: 'hachubi',
      render: (_value: any) => <div style={{ minWidth: 60 }}>{_value}</div>,
    },
    {
      title: () => <div style={{ minWidth: 80 }}>発注№</div>,
      dataIndex: 'chuNo2',
      key: 'chu_no2',
      render: (_value: any, record: any) => (
        <div style={{ textAlign: 'center', minWidth: 80 }}>{_value}</div>
      ),
    },
    {
      title: () => <div style={{ minWidth: 70 }}>入荷保留</div>,
      dataIndex: 'del',
      key: 'del',
      render: (_value: any, record: any) => {
        return (
          <div
            style={{ display: 'flex', justifyContent: 'center', minWidth: 70 }}
          >
            <Checkbox checked={_value} disabled />
          </div>
        );
      },
    },
    {
      title: () => <div style={{ minWidth: 80 }}>品名CD</div>,
      dataIndex: 'code24',
      key: 'code24',
      render: (_value: any) => (
        <div style={{ minWidth: 80, textAlign: 'center' }}>{_value}</div>
      ),
    },
    {
      title: () => <div style={{ minWidth: 370 }}>品名</div>,
      dataIndex: 'nnm',
      key: 'nnm',
      render: (_value: any) => <div style={{ minWidth: 370 }}>{_value}</div>,
    },
    {
      title: () => <div style={{ minWidth: 60 }}>発注数</div>,
      dataIndex: 'hSuu',
      key: 'hSuu',
      render: (_value: any) => (
        <div style={{ textAlign: 'right', minWidth: 60 }}>{_value}</div>
      ),
    },
    {
      title: () => <div style={{ minWidth: 60 }}>入荷数</div>,
      dataIndex: 'suryo',
      key: 'suryo',
      render: (_value: any) => (
        <div style={{ textAlign: 'right', minWidth: 60 }}>{_value}</div>
      ),
    },
    {
      title: () => <div style={{ minWidth: 60 }}>発注残</div>,
      dataIndex: 'hachuzan',
      key: 'hachuzan',
      render: (_value: any) => (
        <div style={{ textAlign: 'right', minWidth: 60 }}>
          {Math.round(_value)}
        </div>
      ),
    },
    {
      title: () => <div style={{ minWidth: 70 }}>ﾛｹｰｼｮﾝ</div>,
      dataIndex: 'location',
      key: 'location',
      render: (_value: any) => (
        <div style={{ textAlign: 'center', minWidth: 70 }}>{_value}</div>
      ),
    },
  ];

  return (
    <ModalCustom
      titleModal="入荷入力確認"
      size="medium"
      isModalVisible={isModalVisible}
      setIsModalVisible={setIsModalVisible}
      afterClose={() => {
        afterClose();
      }}
      hideButtonClose={true}
    >
      {loadingUpdateStockReceive && <LoadingCallAPI />}
      <BodyContent className="order-confirm">
        <FlexColumn>
          <h2>内容をご確認ください</h2>
          <DetailsOrder style={{ width: 990 }}>
            <DetailsOrderItem span={24}>
              <Label>仕入先</Label>
              <Content>
                {record?.header?.siirecd} {record?.header?.siirenm}
              </Content>
            </DetailsOrderItem>
            <DetailsOrderItem span={24}>
              <Label>入荷日</Label>
              <Content>
                <span>{record?.header?.nyukaDtFull}</span>
              </Content>
            </DetailsOrderItem>
          </DetailsOrder>
        </FlexColumn>
        <FlexColumn style={{ marginTop: 20 }}>
          <TableCustom
            rowKey="index"
            height={'230px'}
            dataSource={record?.meisai}
            columns={columns}
            checkChangeValue={(record: any) => {
              if (record?.hachuzan !== record?.suryo) return 'new-data';
              return '';
            }}
          />
        </FlexColumn>
        <FlexEnd style={{ marginTop: 20 }}>
          <Space>
            <Button
              ref={btnSubmitRef}
              autoFocus
              type="primary"
              onClick={onSubmit}
            >
              登録します
            </Button>
            <Button
              type="default"
              onClick={() => {
                setIsModalVisible(false);
              }}
            >
              訂正します（戻る）
            </Button>
          </Space>
        </FlexEnd>
      </BodyContent>
    </ModalCustom>
  );
};

export default memo(StockRefillReceiveConfirm);
