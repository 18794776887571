import { Button, Form, Input, Radio, Space } from 'antd';
import {
  useGetCheckOrderBacklogDetail,
  useGetCheckOrderBacklogSum,
} from 'api/checkOrderBacklog';
import RangeDatePickerCustom from 'components/RangePickerCustom';
import { PATH } from 'configs/routes';
import {
  checkForSpecialChar,
  getKeyboardFocusableElements,
  hasKanji,
  IsHankakuEisu,
  ToASCII,
} from 'constants/common';
import { MSG_ERROR, TEXT_ALL } from 'constants/text';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectUserInfo } from 'slice/app';
import messagePopup from 'utils/message/mesage';
import { FormWrapper } from 'views/login/FormLogin/formLogin.style';
import { ControlFilter, MenuWrapper, Wrapper } from '../webSearchMenu.style';
import ModalResultCheckOrderBacklogDetail from './modalResultDetails';
import {
  useGetCheckOrderBacklogDetailExcel,
  useGetCheckOrderBacklogSumExcel,
} from 'api/checkOrderBacklog';

const dateFormat = 'YYMMDD';

const CkeckOrderBacklog = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const userInfo = useSelector(selectUserInfo);
  const labelTokuisaki =
    (userInfo.kaisya ?? '') + (userInfo.tensyo ?? '') + TEXT_ALL.SAMA;
  const refForm = useRef<any>({});
  const [disableInput, setDisableInput] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<any>({
    count: 0,
    listData: [],
  });
  const [visibleModalResult, setVisibleModalResult] = useState<any>(false);

  const preValue = useRef({
    fromCd24: '',
    toCd24: '',
  });

  /**
   * *API GetSyohin
   * todo Get Syohin info to fill input code24
   * @param cd24
   */

  /**
   * *API GetCheckOrderBacklogDetail
   * todo Search List Check Order Backlog Detail
   * @param {...input}
   */
  const {
    mutate: mutateDetail,
    isLoading: isLoadingDetail,
    reset: resetDetail,
  } = useGetCheckOrderBacklogDetail();

  /**
   * *API GetCheckOrderBacklogSum
   * todo Search List Check Order Backlog Sum
   * @param {}
   */
  const {
    mutate: mutateSum,
    isLoading: isLoadingSum,
    reset: resetSum,
  } = useGetCheckOrderBacklogSum();

  const {
    mutate: excelDetailMutation,
    isLoading: isLoadingDetailExcel,
    reset: resetDetailExcel,
  } = useGetCheckOrderBacklogDetailExcel();

  const {
    mutate: excelSumMutation,
    isLoading: isLoadingSumExcel,
    reset: resetSumExcel,
  } = useGetCheckOrderBacklogSumExcel();

  const keyboardFocusElement: any = useRef([]);
  const focusNextEle = (e: any) => {
    const index = keyboardFocusElement.current.findIndex(
      (element: any) => element === e.target,
    );
    if (index !== -1) {
      if (e.key === 'Enter' || (e.key === 'Tab' && !e.shiftKey)) {
        e.preventDefault();
        keyboardFocusElement.current[index + 1]
          ? keyboardFocusElement.current[index + 1]?.focus()
          : keyboardFocusElement.current[0]?.focus();
      } else if (e.shiftKey && e.key === 'Tab') {
        e.preventDefault();
        keyboardFocusElement.current[index - 1]
          ? keyboardFocusElement.current[index - 1]?.focus()
          : keyboardFocusElement.current[
              keyboardFocusElement.current.length
            ]?.focus();
      }
    }
  };

  useEffect(() => {
    keyboardFocusElement.current = getKeyboardFocusableElements();
  });

  // Func reset form and clear cache data
  function resetPage() {
    form.resetFields();
    setDisableInput(false);
    resetDetail();
    resetSum();
    resetDetailExcel();
    resetSumExcel();
    setDataSource({
      count: 0,
      listData: [],
    });
  }

  // In first render
  useEffect(() => {
    resetPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // onSubmit to get response list  hat product delivery
  const onFinish = async (record: any) => {
    const request = {
      fromDt: record.fromDate ? moment(record.fromDate).format(dateFormat) : '',
      toDt: record.toDate ? moment(record.toDate).format(dateFormat) : '',
      fromCode24: record.fromCode24 || '',
      toCode24: record.toCode24 || '',
    };

    if (record.radio === '明細') {
      mutateDetail(request, {
        onSuccess(data) {
          const { data: dataList } = data;
          if (dataList?.count > 0) {
            setDataSource({
              count: dataList?.count,
              listData: dataList?.data?.map((order: any, index: number) => ({
                id: index + 1,
                ...order,
              })),
            });
            setVisibleModalResult(true);
          } else {
            messagePopup({
              type: 'error',
              content: MSG_ERROR['MSG_NO_DATA'],
              onOk: () => {
                refForm?.current?.fromDate?.focus();
              },
            });
          }
        },
        onError(error) {
          messagePopup({
            type: 'error',
            content:
              MSG_ERROR[(error as any)?.response?.data?.message] ||
              (error as any)?.response?.data?.message,
            onOk: () => {
              refForm?.current?.fromDate?.focus();
            },
          });
        },
      });
    } else {
      mutateSum(
        {},
        {
          onSuccess(data) {
            const { data: dataList } = data;
            if (dataList?.count > 0) {
              setDataSource({
                count: dataList?.count,
                listData: dataList?.data?.map((order: any, index: number) => ({
                  id: index + 1,
                  ...order,
                })),
              });
              setVisibleModalResult(true);
            } else {
              messagePopup({
                type: 'error',
                content: MSG_ERROR['MSG_NO_DATA'],
                onOk: () => {
                  refForm?.current?.fromDate?.focus();
                },
              });
            }
          },
          onError(error) {
            messagePopup({
              type: 'error',
              content:
                MSG_ERROR[(error as any)?.response?.data?.message] ||
                (error as any)?.response?.data?.message,
              onOk: () => {
                refForm?.current?.fromDate?.focus();
              },
            });
          },
        },
      );
    }
  };

  // Validate form input
  const onFinishFailed = ({ values, errorFields }: any) => {
    messagePopup({
      type: 'info',
      content: errorFields[0]?.errors[0],
      onOk: () => {
        refForm?.current?.[errorFields[0]?.name[0]]?.focus();
        !!refForm?.current?.[errorFields[0]?.name[0]]?.select?.toString() &&
          refForm?.current?.[errorFields[0]?.name[0]]?.select();
      },
    });
  };

  //Handle Excel
  const handleExcel = () => {
    const request = {
      fromDt: form.getFieldValue('fromDate')
        ? moment(form.getFieldValue('fromDate')).format(dateFormat)
        : '',
      toDt: form.getFieldValue('toDate')
        ? moment(form.getFieldValue('toDate')).format(dateFormat)
        : '',
      fromCode24: form.getFieldValue('fromCode24') || '',
      toCode24: form.getFieldValue('toCode24') || '',
      // fromDt: form.getFieldValue('fromDate'),
      // toDt: form.getFieldValue('toDate'),
      // code24: form.getFieldValue('code24'),
    };
    if (form.getFieldValue('radio') === '明細') {
      form
        .validateFields()
        .then(() =>
          excelDetailMutation(request, {
            onSuccess(data, variables, context) {
              let fileName = data.headers['content-disposition'] || 'errorName';
              const file = new Blob([data?.data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              });
              const fileURL = URL.createObjectURL(file);
              const link = document.createElement('a');
              link.href = fileURL;
              link.download = decodeURIComponent(
                fileName?.split('filename*=')[1].split(';')[0],
              ).substring(7);
              link.click();
            },
            onError: async (error, variables, context) => {
              const errorMsg = JSON.parse(
                await error.response.data.text(),
              )?.message;
              messagePopup({
                type: 'error',
                content: MSG_ERROR[errorMsg] || errorMsg,
                onOk: () => {
                  refForm?.current?.fromDate?.focus();
                },
              });
            },
          }),
        )
        .catch(({ errorFields }) => {
          messagePopup({
            type: 'info',
            content: errorFields[0]?.errors[0],
            onOk: () => {
              refForm?.current?.[errorFields[0]?.name[0]]?.focus();
              !!refForm?.current?.[
                errorFields[0]?.name[0]
              ]?.select?.toString() &&
                refForm?.current?.[errorFields[0]?.name[0]]?.select();
            },
          });
        });
    } else {
      excelSumMutation(
        {},
        {
          onSuccess(data, variables, context) {
            let fileName = data.headers['content-disposition'] || 'errorName';
            const file = new Blob([data?.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            const fileURL = URL.createObjectURL(file);
            const link = document.createElement('a');
            link.href = fileURL;
            link.download = decodeURIComponent(
              fileName?.split('filename*=')[1].split(';')[0],
            ).substring(7);
            link.click();
          },
          onError: async (error, variables, context) => {
            const errorMsg = JSON.parse(
              await error.response.data.text(),
            )?.message;
            messagePopup({
              type: 'error',
              content: MSG_ERROR[errorMsg] || errorMsg,
              onOk: () => {
                refForm?.current?.fromDate?.focus();
              },
            });
          },
        },
      );
    }
  };

  return (
    <Wrapper>
      <MenuWrapper style={{ marginBottom: 8 }}>
        <span>{labelTokuisaki}</span>
      </MenuWrapper>

      <FormWrapper
        form={form}
        className="form-mainte"
        name="websearch-mainte"
        labelCol={{ flex: '180px' }}
        wrapperCol={{ flex: 1 }}
        requiredMark={false}
        labelAlign="left"
        colon={false}
        validateTrigger="onSubmit"
        scrollToFirstError
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{
          radio: '明細',
        }}
        onKeyPress={(e: any) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
      >
        <Form.Item
          label={<label>{'発注日'}</label>}
          className={disableInput ? 'disable-input' : ''}
        >
          <RangeDatePickerCustom
            title={'発注日'}
            nextFocus="fromCode24"
            form={form}
            refForm={refForm}
            autoFocus={true}
            disabled={disableInput}
          />
        </Form.Item>
        <Form.Item
          label="品名コード（前方一致）"
          className={disableInput ? 'disable-input' : ''}
        >
          <Space>
            <Form.Item
              noStyle
              name="fromCode24"
              rules={[
                () => ({
                  validator(_: any, value: string) {
                    if (value && checkForSpecialChar(value)) {
                      return Promise.reject(
                        new Error('品名コード' + MSG_ERROR['MSG_INVALID_CHAR']),
                      );
                    }
                    if (value && (IsHankakuEisu(value) || hasKanji(value))) {
                      return Promise.reject(
                        new Error('品名コード' + MSG_ERROR['MSG_HANKAKU_EISU']),
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input
                maxLength={11}
                disabled={disableInput}
                ref={el => (refForm.current.fromCode24 = el)}
                style={{ width: 150 }}
                onKeyUp={event =>
                  (event.key === 'Enter' || event.key === 'Tab') &&
                  event.currentTarget.select()
                }
                onFocus={event =>
                  (preValue.current.fromCd24 = event.target.value)
                }
                onBlur={event => {
                  event.preventDefault();
                  let value = ToASCII(event.target.value.trim());
                  form.setFieldsValue({
                    fromCode24: value,
                  });

                  setTimeout(() => {
                    value !== preValue.current.fromCd24 &&
                      form
                        .validateFields(['fromCode24'])
                        .catch(({ errorFields }) =>
                          messagePopup({
                            type: 'info',
                            content: errorFields[0]?.errors[0],
                            onOk: () => {
                              refForm?.current?.[
                                errorFields[0]?.name[0]
                              ]?.focus();
                              refForm?.current?.[
                                errorFields[0]?.name[0]
                              ]?.select();
                            },
                          }),
                        );
                  }, 0);
                }}
                onKeyDown={focusNextEle}
              ></Input>
            </Form.Item>
            <span>～</span>
            <Form.Item
              noStyle
              name="toCode24"
              rules={[
                () => ({
                  validator(_: any, value: string) {
                    if (value && checkForSpecialChar(value)) {
                      return Promise.reject(
                        new Error('品名コード' + MSG_ERROR['MSG_INVALID_CHAR']),
                      );
                    }
                    if (value && (IsHankakuEisu(value) || hasKanji(value))) {
                      return Promise.reject(
                        new Error('品名コード' + MSG_ERROR['MSG_HANKAKU_EISU']),
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input
                maxLength={11}
                disabled={disableInput}
                ref={el => (refForm.current.toCode24 = el)}
                style={{ width: 150 }}
                onFocus={event =>
                  (preValue.current.toCd24 = event.target.value)
                }
                onKeyUp={event =>
                  (event.key === 'Enter' || event.key === 'Tab') &&
                  event.currentTarget.select()
                }
                onBlur={event => {
                  event.preventDefault();
                  let value = ToASCII(event.target.value.trim());
                  form.setFieldsValue({
                    toCode24: value,
                  });

                  setTimeout(() => {
                    value !== preValue.current.toCd24 &&
                      form
                        .validateFields(['toCode24'])
                        .catch(({ errorFields }) =>
                          messagePopup({
                            type: 'info',
                            content: errorFields[0]?.errors[0],
                            onOk: () => {
                              refForm?.current?.[
                                errorFields[0]?.name[0]
                              ]?.focus();
                              refForm?.current?.[
                                errorFields[0]?.name[0]
                              ]?.select();
                            },
                          }),
                        );
                  }, 0);
                }}
                onKeyDown={focusNextEle}
              ></Input>
            </Form.Item>
          </Space>
        </Form.Item>
        <Form.Item label="抽出区分" name="radio">
          <Radio.Group
            onChange={event => {
              setDisableInput(event.target.value === '集計');
              if (event.target.value === '集計') {
                form.resetFields([
                  'fromDate',
                  'toDate',
                  'fromCode24',
                  'toCode24',
                ]);
              }
            }}
          >
            <Radio
              ref={el => (refForm.current.mode1 = el)}
              onKeyDown={event => {
                event.preventDefault();
                (event.key === 'Enter' ||
                  (!event.shiftKey && event.keyCode === 9)) &&
                  refForm.current?.mode2?.focus();
                event.shiftKey &&
                  event.keyCode === 9 &&
                  refForm.current?.toCode24?.focus();
                focusNextEle(event);
              }}
              value="明細"
            >
              明細
            </Radio>
            <Radio
              ref={el => (refForm.current.mode2 = el)}
              onKeyDown={event => {
                event.preventDefault();
                (event.key === 'Enter' ||
                  (!event.shiftKey && event.keyCode === 9)) &&
                  refForm.current?.btnSubmit?.focus();
                event.shiftKey &&
                  event.keyCode === 9 &&
                  refForm.current?.mode1?.focus();
                focusNextEle(event);
              }}
              value="集計"
            >
              集計
            </Radio>
          </Radio.Group>
        </Form.Item>
      </FormWrapper>

      <ControlFilter style={{ marginTop: 15 }}>
        <Space>
          <Button
            style={{ minWidth: 100 }}
            loading={isLoadingDetail || isLoadingSum}
            type="primary"
            ref={el => (refForm.current.btnSubmit = el)}
            onClick={e => {
              e.preventDefault();
              setDataSource({
                count: 0,
                listData: [],
              });
              setTimeout(() => {
                form.submit();
              }, 50);
            }}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                e.preventDefault();
                e.currentTarget.click();
              }
            }}
          >
            検索
          </Button>
          <Button
            onClick={e => {
              e.preventDefault();
              resetDetailExcel();
              resetSumExcel();
              handleExcel();
            }}
            loading={isLoadingDetailExcel || isLoadingSumExcel}
            type="default"
            style={{ width: 180 }}
          >
            EXCEL作成（XLSX）
          </Button>
          <Button
            type="default"
            ref={el => (refForm.current.btnReturn = el)}
            onClick={event => {
              event.preventDefault();
              resetPage();
            }}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                e.preventDefault();
                e.currentTarget.click();
              }
            }}
          >
            画面クリア
          </Button>
          <Button
            type="default"
            onClick={event => {
              event.preventDefault();
              resetPage();
              navigate(PATH.WEBSEARCH_MENU);
            }}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                e.preventDefault();
                e.currentTarget.click();
              }
            }}
          >
            メニューへ戻る
          </Button>
        </Space>
      </ControlFilter>

      <ModalResultCheckOrderBacklogDetail
        form={form}
        isModalVisible={visibleModalResult}
        setIsModalVisible={setVisibleModalResult}
        dataSource={dataSource}
        afterClose={() => {
          refForm.current.btnSubmit.focus();
          resetDetail();
        }}
      />
    </Wrapper>
  );
};

export default CkeckOrderBacklog;
