export const columns = [
  {
    title: () => <div style={{ minWidth: 60 }}>納入日</div>,
    dataIndex: 'nohinDt',
    key: 'nohinDt',
    render: (_value: any) => (
      <div style={{ minWidth: 60, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 40 }}>便CD</div>,
    dataIndex: 'binCd',
    key: 'binCd',
    render: (_value: any) => (
      <div style={{ minWidth: 40, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 100 }}>協力企業名</div>,
    dataIndex: 'n2Atesaki1',
    key: 'n2Atesaki1',
    render: (_value: any) => (
      <div style={{ minWidth: 100, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 60 }}>現場名</div>,
    dataIndex: 'genba',
    key: 'genba',
    render: (_value: any) => (
      <div style={{ minWidth: 60, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 80 }}>配送住所</div>,
    dataIndex: 'sendAdr',
    key: 'sendAdr',
    render: (_value: any) => (
      <div style={{ minWidth: 80, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 65 }}>商品分類</div>,
    dataIndex: 'bunrui',
    key: 'bunrui',
    align: 'right',
    render: (_value: any) => (
      <div style={{ minWidth: 65, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 65 }}>重量合計</div>,
    dataIndex: 'sumJyuryoGoukei',
    key: 'sumJyuryoGoukei',
    align: 'right',
    render: (_value: any, record: any) => (
      <div
        style={{
          minWidth: 65,
          fontWeight: record.highlightRow ? 'bold' : 'normal',
        }}
      >
        {record.highlightRow ? record.totalJyuryoGoukei : _value}
      </div>
    ),
  },
  {
    title: () => <div style={{ width: 65 }}>変更重量</div>,
    dataIndex: 'emptyField1',
    key: 'emptyField1',
    render: (_value: any) => <div style={{ width: 65 }}>{_value}</div>,
  },
  {
    title: () => <div style={{ width: 85 }}>変更先 便名</div>,
    dataIndex: 'emptyField2',
    key: 'emptyField2',
    render: (_value: any) => <div style={{ width: 85 }}>{_value}</div>,
  },
  {
    title: () => <div style={{ width: 90 }}>配送重量合計</div>,
    dataIndex: 'emptyField3',
    key: 'emptyField3',
    render: (_value: any) => <div style={{ width: 90 }}>{_value}</div>,
  },
];
