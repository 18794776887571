import { Button, Checkbox, Form, Input, Space, Tabs } from 'antd';
import { useLogAccess } from 'api/auth';
import {
  useGetCalendarData,
  useInsertCalendar,
  useUpdateCalendar,
} from 'api/calendar';
import { useGetKigyoGroup } from 'api/customer';
import LoadingCallAPI from 'components/LoadingCallAPI/LoadingCallAPI';
import DatePickerCustom from 'components/RangePickerCustom/DatePickerCustom';
import { SwitchModeTabs } from 'components/StyleCommon';
import { PATH } from 'configs/routes';
import {
  Hankaku2Zenkaku,
  isNumeric,
  ToASCII,
  UseFocus,
} from 'constants/common';
import { MSG_ERROR } from 'constants/text';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectUserInfo } from 'slice/app';
import messagePopup from 'utils/message/mesage';
import {
  FormWrapper,
  TitleMainte,
} from 'views/login/FormLogin/formLogin.style';
import {
  BodyContent,
  CalendarTable,
  Footer,
  Header,
  Wrapper,
} from './calendarMainte.style';

const MODE_UPDATE_INSERT = '登録／更新モード';
const MODE_VIEW = '参照モード';
const MAXLENGTH_NENGETSU = 6;

interface FormNengetsuProps {
  onSubmit: (nengetsu: string) => void;
  refNengetsu: any;
  setFocusNengetsu: any;
  isHasCalendar: boolean;
  refresh: boolean;
  status: string;
  valuesChange: boolean;
  submitCalender: any;
}

const FormNengetsu = ({
  onSubmit,
  // refNengetsu,
  valuesChange,
  setFocusNengetsu,
  isHasCalendar,
  refresh,
  status,
  submitCalender,
}: FormNengetsuProps) => {
  const [form] = Form.useForm();
  const refForm = useRef<any>({});

  const refSubmitButton = useRef<any>(null);

  const onFinish = (data: any) => {
    const nengetsu = data.nengetsu
      ? moment(data.nengetsu).format('YYYYMM')
      : null;
    if (!nengetsu) {
      messagePopup({
        type: 'info',
        content: '対象年月' + MSG_ERROR['MSG_INPUT'],
        onOk: () => {
          setFocusNengetsu();
        },
      });
      return;
    }
    if (!isNumeric(nengetsu)) {
      messagePopup({
        type: 'info',
        content: '対象年月' + MSG_ERROR['MSG_010'],
        onOk: () => {
          setFocusNengetsu();
        },
      });
      return;
    }
    if (nengetsu?.length !== MAXLENGTH_NENGETSU) {
      messagePopup({
        type: 'info',
        content: MSG_ERROR['MSG_NENGETU_INVALID'],
        onOk: () => {
          setFocusNengetsu();
        },
      });
      return;
    }
    let iYear = parseInt(nengetsu?.substring(0, 4));
    let iMonth = parseInt(nengetsu?.substring(4, 6));
    if (1 > iYear || iYear > 9999) {
      messagePopup({
        type: 'info',
        content: '対象年月' + MSG_ERROR['MSG_DATE_FORMAT_YEAR'],
        onOk: () => {
          setFocusNengetsu();
        },
      });
      return;
    }
    if (1 > iMonth || iMonth > 12) {
      messagePopup({
        type: 'info',
        content: '対象年月' + MSG_ERROR['MSG_DATE_FORMAT_MONTH'],
        onOk: () => {
          setFocusNengetsu();
        },
      });
      return;
    }
    onSubmit(nengetsu);
  };

  useEffect(() => {
    form.resetFields();
    setTimeout(() => {
      setFocusNengetsu();
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  return (
    <FormWrapper
      form={form}
      className="form-feature"
      labelAlign="left"
      onFinish={onFinish}
      labelCol={{ flex: '250px' }}
      colon={false}
      initialValues={{
        nengetsu: '',
      }}
      style={{ justifyContent: 'center' }}
    >
      <Form.Item label="１．対象年月(西暦年月6桁)">
        <Space>
          <Form.Item name="nengetsu" noStyle>
            <DatePickerCustom
              placeholder="YYYYMM"
              // disabled={isHasCalendar}
              autoFocus={true}
              allowClear={false}
              refForm={refForm}
              formName={'nengetsu'}
              picker="month"
              nextFocus={() => {
                refSubmitButton && refSubmitButton.current.focus();
              }}
              title={'対象年月'}
              style={{ width: 150, padding: '2px 10px' }}
              format={'YYYYMM'}
              getPopupContainer={(trigger: HTMLElement) =>
                trigger.parentNode as HTMLElement
              }
            />
            {/* <Input
              disabled={isHasCalendar}
              ref={refNengetsu}
              maxLength={6}
              style={{ width: 120 }}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  refSubmitButton && refSubmitButton.current.focus();
                }
              }}
              onBlur={event => {
                form.setFieldsValue({
                  nengetsu: ToASCII(event.target.value, false),
                });
              }}
            /> */}
          </Form.Item>
          <Button
            ref={refSubmitButton}
            // hidden={isHasCalendar}
            style={{ marginLeft: 0 }}
            type="primary"
            // htmlType="submit"
            onClick={() => {
              if (valuesChange) {
                messagePopup({
                  type: 'warning',
                  content: '登録した情報を確定しますか',
                  onOk: () => {
                    submitCalender(() => form.submit());
                  },
                });
              } else {
                form.submit();
              }
            }}
          >
            {'カレンダー表示'}
          </Button>
          {isHasCalendar ? (
            <b
              style={{
                color: status === '未登録' ? 'red' : 'black',
                marginLeft: 0,
              }}
            >
              {status}
            </b>
          ) : (
            <></>
          )}
        </Space>
      </Form.Item>
    </FormWrapper>
  );
};

const CalendarMainte = () => {
  let popMsg: boolean = true;
  const userInfo = useSelector(selectUserInfo);
  const eigyoRef = useRef<any>([]);
  const kigyoGroupRef = useRef<any>([]);
  const gasRef = useRef<any>([]);
  const btnSubmitRef = useRef<any>(null);
  const [mode, setMode] = useState<any>(MODE_UPDATE_INSERT);
  const [dataSource, setDataSource] = useState<Array<any>>([]);
  const [columns, setColumns] = useState<Array<any>>([]);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [defaultFormValues, setDefaultFormValues] = useState<any>();
  const [valuesChange, setValuesChange] = useState(false);
  const [form] = Form.useForm();
  const [refNengetsu, setFocusNengetsu] = UseFocus();

  const navigate = useNavigate();

  /**
   * *API LogAccess
   * todo log
   * @param {...}
   */
  const { mutate: logAccess } = useLogAccess();

  /**
   * *API GetKigyogroupm
   * todo Get Kigyo groups to init dropdown
   * @param
   */
  const {
    mutate: getKigyoGroup,
    data: dataKigyoGroup,
    isLoading: loadingKigyoGroup,
  } = useGetKigyoGroup();

  const {
    mutate: getCalendarData,
    data: dataCalendar,
    error,
    isLoading,
  } = useGetCalendarData();

  const {
    mutate: insertCalendar,
    isSuccess: isInsertSuccess,
    error: errorInsert,
    isLoading: loadingInsert,
  } = useInsertCalendar();

  const {
    mutate: updateCalendar,
    isSuccess: isUpdateSuccess,
    error: errorUpdate,
    isLoading: loadingUpdate,
  } = useUpdateCalendar();

  const clearScreen = () => {
    setDataSource([]);
    setRefresh(!refresh);
    setValuesChange(false);
  };

  useEffect(() => {
    getKigyoGroup(
      {},
      {
        onError(error, variables, context) {
          messagePopup({
            type: 'info',
            content:
              MSG_ERROR[(error as any)?.response?.data?.message] ||
              (error as any)?.response?.data?.message,
          });
        },
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getGasMeter = (gas: string, date: string) => {
    if ([undefined, null, ''].includes(gas)) {
      return gas;
    }
    const iDate = parseInt(date?.slice(-2));
    let checkingNengetsu = date?.slice(0, 6);
    if (iDate > parseInt(gas)) {
      checkingNengetsu = moment(checkingNengetsu)
        .add(1, 'months')
        .format('YYYYMM');
    }
    return checkingNengetsu + gas.toString().padStart(2, '0');
  };

  const ConfirmDataWhenChangeCalender = (nextAction: any) => {
    const { days, youbis, eigyoubis, gass, ...rest } =
      form.getFieldsValue(true);
    let meisai: any[] = [];
    for (let index = 1; index < 32; index++) {
      if (!days[index]) {
        continue;
      }
      let kigyoGroups: any = [];

      Object.keys(rest).forEach(key => {
        if (rest[key][index] === true) {
          kigyoGroups.push(key);
        }
      });

      meisai.push({
        dt: days[index],
        youbi: youbis[index],
        eigyo: eigyoubis[index] ? '1' : '0',
        hikitori: eigyoubis[index] ? '1' : '0',
        kigyoGroups,
        gasDt: getGasMeter(gass[index], days[index]),
        nowDate: new Date(),
      });
    }
    const submitData = { nengetsu: days[1]?.slice(0, 6), request: meisai };
    if (dataCalendar?.data?.status === '未登録') {
      insertCalendar(submitData, {
        onSuccess() {
          nextAction();
        },
      });
    } else {
      updateCalendar(submitData, {
        onSuccess() {
          nextAction();
        },
      });
    }
  };

  const onFinish = (data: any) => {
    const { days, youbis, eigyoubis, gass, ...rest } = data;
    let meisai: any[] = [];
    for (let index = 1; index < 32; index++) {
      if (!days[index]) {
        continue;
      }
      let kigyoGroups: any = [];

      Object.keys(rest).forEach(key => {
        if (rest[key][index] === true) {
          kigyoGroups.push(key);
        }
      });

      meisai.push({
        dt: days[index],
        youbi: youbis[index],
        eigyo: eigyoubis[index] ? '1' : '0',
        hikitori: eigyoubis[index] ? '1' : '0',
        kigyoGroups,
        gasDt: getGasMeter(gass[index], days[index]),
        nowDate: new Date(),
      });
    }
    const submitData = { nengetsu: days[1]?.slice(0, 6), request: meisai };
    if (popMsg) {
      messagePopup({
        type: 'question',
        content: 'カレンダー情報を登録してもよろしいですか',
        onOk: () => {
          if (dataCalendar?.data?.status === '未登録') {
            insertCalendar(submitData);
          } else {
            updateCalendar(submitData);
          }
        },
      });
    } else {
      popMsg = true;
      if (dataCalendar?.data?.status === '未登録') {
        insertCalendar(submitData);
      } else {
        updateCalendar(submitData);
      }
    }
  };

  const onFinishFailed = ({ values, errorFields }: any) => {
    messagePopup({
      type: 'info',
      content: errorFields[0]?.errors[0],
      onOk: () => {
        gasRef?.current[errorFields[0]?.name[1]]?.select();
      },
    });
  };

  useEffect(() => {
    clearScreen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  useEffect(() => {
    if (!error) {
      return;
    }
    messagePopup({
      type: 'error',
      content:
        MSG_ERROR[error?.response?.data?.message] ||
        error?.response?.data?.message,
      onOk: () => {
        setFocusNengetsu();
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (!errorInsert) {
      return;
    }
    messagePopup({
      type: 'error',
      content:
        MSG_ERROR[errorInsert?.response?.data?.message] ||
        errorInsert?.response?.data?.message,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorInsert]);

  useEffect(() => {
    if (!errorUpdate) {
      return;
    }
    messagePopup({
      type: 'error',
      content:
        MSG_ERROR[errorUpdate?.response?.data?.message] ||
        errorUpdate?.response?.data?.message,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorUpdate]);

  useEffect(() => {
    if (!isInsertSuccess) return;
    messagePopup({
      type: 'success',
      content: MSG_ERROR['MSG_CALENDAR_INSERT_SUCCESS'],
      onOk: () => {
        setValuesChange(false);
        clearScreen();
      },
    });
    logAccess([
      {
        tokuiCd: userInfo.torihikicd,
        gyoumuId: 'カレンダーメンテ',
        pgName: MODE_UPDATE_INSERT,
        logType: '2',
        action: '確定ボタン',
      },
    ] as any);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInsertSuccess]);

  useEffect(() => {
    if (!isUpdateSuccess) return;
    messagePopup({
      type: 'success',
      content: MSG_ERROR['MSG_CALENDAR_UPDATE_SUCCESS'],
      onOk: () => {
        setValuesChange(false);
        // clearScreen();
      },
    });
    logAccess([
      {
        tokuiCd: userInfo.torihikicd,
        gyoumuId: 'カレンダーメンテ',
        pgName: MODE_UPDATE_INSERT,
        logType: '2',
        action: '確定ボタン',
      },
    ] as any);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateSuccess]);

  useEffect(() => {
    if (!dataCalendar) {
      return;
    }
    const { listCalendar } = dataCalendar.data;
    let defaultFormValue = {};
    let tempColumns: Array<any> = [];
    let days: any = { id: 'days' };
    let youbis: any = { id: 'youbis' };
    let eigyoubis: any = { id: 'eigyoubis' };
    let kigyoGroups: any = dataKigyoGroup?.data?.map(
      (kigyoGroup: any, index: number) => {
        const indexRow = index + 3;
        return {
          id: kigyoGroup.kigyogroupId,
          0: Hankaku2Zenkaku(
            `${indexRow < 10 ? '　' + indexRow : indexRow}．荷積日(${
              kigyoGroup.kigyogroupNm
            })`,
          ),
        };
      },
    );
    let gass: any = { id: 'gass' };
    for (let index = 0; index < 32; index++) {
      if (index === 0) {
        days = { ...days, 0: '' };
        youbis = { ...youbis, 0: '曜　日' };
        eigyoubis = { ...eigyoubis, 0: '　２．YHK営業日' };
        gass = {
          ...gass,
          0: Hankaku2Zenkaku(`${kigyoGroups?.length + 3}．ガスメーター`),
        };
      } else {
        const eigyo = listCalendar[index - 1]?.eigyo;
        const gasmeter = listCalendar[index - 1]?.gasmeter;
        days = { ...days, [index]: listCalendar[index - 1]?.dt };
        youbis = { ...youbis, [index]: listCalendar[index - 1]?.youbi };
        eigyoubis = {
          ...eigyoubis,
          [index]: eigyo ? !!parseInt(eigyo) : eigyo,
        };
        // eslint-disable-next-line no-loop-func
        kigyoGroups = kigyoGroups?.map((kigyoGroup: any) => {
          return {
            ...kigyoGroup,
            [index]:
              youbis[index] === undefined
                ? undefined
                : (listCalendar[index - 1]?.kigyoGroups || [])?.includes(
                    kigyoGroup.id,
                  ),
          };
        });
        gass = {
          ...gass,
          [index]: gasmeter
            ? parseInt(gasmeter?.slice(-2)).toString()
            : gasmeter?.slice(-2),
        };
      }
      tempColumns.push({
        title: index,
        dataIndex: index,
        key: index,
        align: index === 0 ? 'left' : 'center',
        // eslint-disable-next-line no-loop-func
        render: (value: any, _record: any, idx: number) => {
          switch (idx) {
            case 0:
              return index !== 0 && value ? (
                <>
                  <span>{value ? parseInt(value.slice(-2)) : null}</span>
                  <Form.Item name={['days', `${index}`]} hidden>
                    <Input />
                  </Form.Item>
                </>
              ) : (
                value
              );

            case 1:
              return index !== 0 && value ? (
                <>
                  <span
                    style={{
                      color: listCalendar[index - 1]?.isHoliday
                        ? 'red'
                        : 'black',
                    }}
                  >
                    {value ? value : null}
                  </span>
                  <Form.Item name={['youbis', `${index}`]} hidden>
                    <Input />
                  </Form.Item>
                </>
              ) : (
                <div
                  style={{
                    textAlign: index === 0 && idx === 1 ? 'center' : 'inherit',
                  }}
                >
                  {value}
                </div>
              );

            case 2:
              return index !== 0 && [true, false].includes(value) ? (
                <Form.Item
                  name={['eigyoubis', `${index}`]}
                  valuePropName={'checked'}
                >
                  <Checkbox
                    ref={el => (eigyoRef.current[index] = el)}
                    onKeyPress={e => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        eigyoRef.current[index + 1]
                          ? eigyoRef.current[index + 1].focus()
                          : kigyoGroupRef.current[
                              `${kigyoGroups[0]?.id}-1`
                            ].focus();
                      }
                    }}
                    disabled={mode === MODE_VIEW}
                  />
                </Form.Item>
              ) : (
                value
              );

            case kigyoGroups?.length + 3:
              return index !== 0 && ![undefined, null].includes(value) ? (
                <Form.Item
                  name={['gass', `${index}`]}
                  rules={[
                    () => ({
                      validator(_: any, value: string) {
                        if (!value) {
                          return Promise.resolve();
                        }
                        if (!isNumeric(value)) {
                          return Promise.reject(
                            new Error('ガスメーター' + MSG_ERROR['MSG_010']),
                          );
                        }
                        const iDate = parseInt(
                          form.getFieldValue(['days', `${index}`])?.slice(-2),
                        );
                        let checkingNengetsu = form
                          .getFieldValue(['days', `${index}`])
                          ?.slice(0, 6);
                        if (iDate > parseInt(value)) {
                          checkingNengetsu = moment(checkingNengetsu)
                            .add(1, 'months')
                            .format('YYYYMM');
                        }
                        const maxDay = moment(checkingNengetsu)
                          .endOf('month')
                          .format('DD');
                        if (parseInt(value) > parseInt(maxDay)) {
                          return Promise.reject(
                            new Error(MSG_ERROR['MSG_GASMATER_INVALID']),
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input
                    ref={el => (gasRef.current[index] = el)}
                    onKeyPress={e => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        gasRef.current[index + 1]
                          ? gasRef.current[index + 1].focus()
                          : btnSubmitRef.current.focus();
                      }
                    }}
                    onKeyUp={event =>
                      (event.key === 'Enter' || event.key === 'Tab') &&
                      event.currentTarget.select()
                    }
                    onBlur={event => {
                      form.setFields([
                        {
                          name: ['gass', `${index}`],
                          value: ToASCII(event.target.value, false),
                        },
                      ]);
                    }}
                    disabled={mode === MODE_VIEW}
                    style={{
                      width: 34,
                      padding: '2px 3px',
                      textAlign: 'right',
                    }}
                    maxLength={2}
                  />
                </Form.Item>
              ) : (
                value
              );

            default:
              const currentKigyoGroupIdx = kigyoGroups.findIndex(
                (kigyoGroup: any) => kigyoGroup.id === _record.id,
              );
              const nextKigyoGroupId =
                kigyoGroups[currentKigyoGroupIdx + 1]?.id || null;
              return index !== 0 && [true, false].includes(value) ? (
                <Form.Item
                  name={[`${_record.id}`, `${index}`]}
                  valuePropName={'checked'}
                >
                  <Checkbox
                    ref={el =>
                      (kigyoGroupRef.current[`${_record.id}-${index}`] = el)
                    }
                    onKeyPress={e => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        kigyoGroupRef.current[`${_record.id}-${index + 1}`]
                          ? kigyoGroupRef.current[
                              `${_record.id}-${index + 1}`
                            ].focus()
                          : kigyoGroupRef.current[`${nextKigyoGroupId}-1`]
                          ? kigyoGroupRef.current[
                              `${nextKigyoGroupId}-1`
                            ].focus()
                          : gasRef.current[1].focus();
                      }
                    }}
                    disabled={mode === MODE_VIEW}
                  />
                </Form.Item>
              ) : (
                value
              );
          }
        },
      });
    }
    let kigyoGroupsObj = {};
    kigyoGroups.forEach((kigyoGroup: any) => {
      kigyoGroupsObj = { ...kigyoGroupsObj, [kigyoGroup.id]: kigyoGroup };
    });
    defaultFormValue = {
      ...defaultFormValue,
      days,
      youbis,
      eigyoubis,
      ...kigyoGroupsObj,
      gass,
    };
    form.setFieldsValue(defaultFormValue);
    setTimeout(() => {
      setDefaultFormValues(form.getFieldsValue());
      eigyoRef.current[1].focus();
    }, 0);

    const dataSource = [...[], days, youbis, eigyoubis, ...kigyoGroups, gass];
    setDataSource(dataSource);
    setColumns(tempColumns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCalendar]);

  const getCalendarByNengetsu = (nengetsu: string) => {
    getCalendarData({
      nengetsu,
      mode: mode === MODE_UPDATE_INSERT ? 'updateInsert' : 'view',
    });
  };

  // Render label with mode
  function labelModeOnChange() {
    switch (mode) {
      case MODE_UPDATE_INSERT:
        return '登録／更新モード';
      case MODE_VIEW:
        return '参照モード';
      default:
        break;
    }
  }

  return (
    <Wrapper>
      {(isLoading || loadingInsert || loadingUpdate || loadingKigyoGroup) && (
        <LoadingCallAPI />
      )}
      <Header>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 30,
          }}
        >
          <TitleMainte>
            <span className="label-mode">{labelModeOnChange()}</span>
          </TitleMainte>
          <Space>
            <b>モード選択：</b>
            <SwitchModeTabs
              activeKey={mode}
              onChange={key => {
                function changeKey(toKey: any) {
                  logAccess([
                    {
                      tokuiCd: userInfo.torihikicd,
                      gyoumuId: 'カレンダーメンテ',
                      pgName: mode,
                      logType: '2',
                      action:
                        toKey === MODE_UPDATE_INSERT
                          ? '登録／更新ボタン'
                          : '参照ボタン',
                    },
                  ] as any);
                  setMode(toKey);
                }
                if (valuesChange) {
                  messagePopup({
                    type: 'warning',
                    content: MSG_ERROR['MSG_VALUES_CHANGE'],
                    onOk: () => {
                      changeKey(key);
                      setValuesChange(false);
                    },
                  });
                } else {
                  changeKey(key);
                }
              }}
            >
              <Tabs.TabPane tab={'登録／更新モード'} key={MODE_UPDATE_INSERT} />
              <Tabs.TabPane tab={'参照モード'} key={MODE_VIEW} />
            </SwitchModeTabs>
          </Space>
        </div>
        <FormNengetsu
          onSubmit={getCalendarByNengetsu}
          refNengetsu={refNengetsu}
          setFocusNengetsu={setFocusNengetsu}
          isHasCalendar={!!dataSource.length}
          refresh={refresh}
          status={dataCalendar?.data?.status || ''}
          valuesChange={valuesChange}
          submitCalender={(nextAction: any) => {
            ConfirmDataWhenChangeCalender(nextAction);
          }}
        />
      </Header>
      <BodyContent>
        {!!dataSource.length && (
          <>
            {/* <div
              style={{
                textAlign: 'right',
                fontWeight: 600,
                width: 990,
                margin: 'auto',
              }}
            >
              <pre style={{ margin: 0 }}>
                {mode === MODE_VIEW ? ' ' : dataCalendar?.data?.status}
              </pre>
            </div> */}
            <br />
            <Form
              id={'calendar-form'}
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              validateTrigger={['onBlur']}
              onBlur={() => {
                if (
                  JSON.stringify(defaultFormValues) !==
                  JSON.stringify(form.getFieldsValue())
                ) {
                  setValuesChange(true);
                } else {
                  setValuesChange(false);
                }
              }}
            >
              <CalendarTable
                rowKey={'id'}
                size="small"
                bordered
                showHeader={false}
                dataSource={dataSource}
                columns={columns}
                pagination={false}
              />
            </Form>
          </>
        )}
      </BodyContent>
      <Footer>
        <Button
          ref={btnSubmitRef}
          form={'calendar-form'}
          type="primary"
          htmlType="submit"
          hidden={mode === MODE_VIEW || !dataSource.length}
        >
          {'確定'}
        </Button>
        <Button onClick={() => window.print()}>{'印刷'}</Button>
        <Button
          onClick={event => {
            if (valuesChange) {
              messagePopup({
                type: 'warning',
                content: MSG_ERROR['MSG_VALUES_CHANGE'],
                onOk: () => {
                  clearScreen();
                },
                onCancel: () => {
                  (
                    (event.target as HTMLElement)
                      .offsetParent as HTMLButtonElement
                  ).focus();
                },
              });
            } else {
              clearScreen();
            }
          }}
        >
          {'画面クリア'}
        </Button>
        <Button
          onClick={event => {
            if (valuesChange) {
              messagePopup({
                type: 'warning',
                content: '登録した情報を確定しますか',
                onOk: () => {
                  popMsg = false;
                  form.submit();
                  navigate(PATH.TOP);
                },
                onCancel: () => {
                  (
                    (event.target as HTMLElement)
                      .offsetParent as HTMLButtonElement
                  ).focus();
                },
              });
            } else {
              navigate(PATH.TOP);
            }
            // if (valuesChange) {
            //   messagePopup({
            //     type: 'warning',
            //     content: MSG_ERROR['MSG_VALUES_CHANGE'],
            //     onOk: () => {
            //       navigate(PATH.TOP);
            //     },
            //     onCancel: () => {
            //       (
            //         (event.target as HTMLElement)
            //           .offsetParent as HTMLButtonElement
            //       ).focus();
            //     },
            //   });
            // } else {
            //   navigate(PATH.TOP);
            // }
          }}
        >
          {'メニューへ戻る'}
        </Button>
      </Footer>
    </Wrapper>
  );
};

export default CalendarMainte;
