import { Button, Space } from 'antd';
import ModalCustom from 'components/ModalCustom';
import { BodyContent, FlexBetween, WrapperTable } from 'components/StyleCommon';
import TableCustom from 'components/TableCommon';
import { EditCanma } from 'constants/common';
import { memo } from 'react';
import { columns } from './columnsTable';

interface Props {
  isModalVisible: boolean;
  setIsModalVisible: any;
  dataSource: any;
  form: any;
  afterClose: any;
}

const ModalResultShippingDetailsAggregation = ({
  isModalVisible,
  setIsModalVisible,
  dataSource,
  form,
  afterClose,
}: Props) => {
  return (
    <ModalCustom
      titleModal="検索結果画面"
      size="medium"
      isModalVisible={isModalVisible}
      setIsModalVisible={setIsModalVisible}
      afterClose={() => {
        afterClose();
      }}
      hideButtonClose={true}
    >
      <BodyContent className="order-confirm">
        <WrapperTable>
          <TableCustom
            className="table-websearch"
            height={'calc(100vh - 10vh - 180px)'}
            rowKey="id"
            columns={columns}
            dataSource={dataSource.listData}
          />
        </WrapperTable>

        <FlexBetween style={{ marginTop: 8 }}>
          <div className="error_maxlength">
            {dataSource?.count > 2000
              ? `表示可能な最大件数を超えました。該当データは、${EditCanma(
                  dataSource?.count,
                )}件です。上位2,000件を表示します。`
              : `${EditCanma(
                  dataSource?.count || 0,
                )}件のデータが検索されました`}
          </div>
          <Space>
            <Button
              autoFocus
              type="default"
              onClick={() => {
                setIsModalVisible(false);
              }}
              style={{ width: 100 }}
            >
              戻る
            </Button>
          </Space>
        </FlexBetween>
      </BodyContent>
    </ModalCustom>
  );
};

export default memo(ModalResultShippingDetailsAggregation);
