import { API_URL } from 'constants/api';
import { useMutation } from 'react-query';
import AxiosPost from 'utils/axios/axiosPost';
import AxiosPut from 'utils/axios/axiosPut';

interface GetCalendarParams {
  nengetsu: string;
  mode: string;
}

export const useGetCalendarData = () => {
  /**
   * *API GET CALENDAR DATA
   * todo Hooks get calendar data
   * @param API
   */
  const getCalendar = async (data: GetCalendarParams) => {
    return await AxiosPost(API_URL.GET_CALENDAR_DATA, data);
  };

  const mutation = useMutation(getCalendar as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });

  return mutation;
};

interface InsertCalendarParams {
  request: any[];
  nengetsu: string;
}

export const useInsertCalendar = () => {
  /**
   * *API INSERT CALENDAR DATA
   * todo Hooks insert calendar data
   * @param API
   */
  const insertCalendar = async (data: InsertCalendarParams) => {
    return await AxiosPost(API_URL.UPDATE_INSERT_CALENDAR, data);
  };

  const mutation = useMutation(insertCalendar as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });

  return mutation;
};

export const useUpdateCalendar = () => {
  /**
   * *API UPDATE CALENDAR DATA
   * todo Hooks update calendar data
   * @param API
   */
  const updateCalendar = async (data: InsertCalendarParams) => {
    return await AxiosPut(API_URL.UPDATE_INSERT_CALENDAR, data);
  };

  const mutation = useMutation(updateCalendar as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });

  return mutation;
};
