import AxiosDelete from 'utils/axios/axiosDelete';
import { API_URL } from 'constants/api';
import { useMutation } from 'react-query';
import AxiosPost from 'utils/axios/axiosPost';
import AxiosPut from 'utils/axios/axiosPut';

interface requestHan {
  torihikiCd: String;
  hanCd: String;
}
export function useGetHanInfo() {
  const getHanInfo = async ({ torihikiCd, hanCd }: requestHan) => {
    const params = {
      torihikiCd: torihikiCd,
      hanCd: hanCd,
    };
    return await AxiosPost(API_URL.GET_HAN_INFO, params);
  };

  const mutation = useMutation(getHanInfo as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });

  return mutation;
}

export function useGetListBin() {
  const getListBin = async () => {
    return await AxiosPost(API_URL.GET_LISTBIN, {});
  };

  const mutation = useMutation(getListBin as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });

  return mutation;
}

export function useUpsertHanOtodokesaki() {
  const mutationUpsertHanOtodokesaki = async ({
    torihikiCd,
    hanBinInfoRequest,
    hanInfoRequest,
    otodokesakiInfos,
    type = 'create',
  }: any) => {
    const params = {
      torihikiCd,
      hanBinInfoRequest,
      hanInfoRequest,
      otodokesakiInfos,
    };
    if (type === 'create') {
      return await AxiosPost(API_URL.POST_PUT_HAN, params);
    } else if (type === 'update') {
      return await AxiosPut(API_URL.POST_PUT_HAN, params);
    }
  };

  const mutation = useMutation(mutationUpsertHanOtodokesaki as any, {
    onMutate: data => {
      // // console.log(data);
    },
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });

  return mutation;
}
interface OtodokesakiRequest {
  torihikiCd: String;
  hanCd: String;
  genbaCd: String;
}
export function useDeleteOtodokesaki() {
  const DeleteOtodokesaki = async ({
    torihikiCd,
    hanCd,
    genbaCd,
  }: OtodokesakiRequest) => {
    const params = {
      torihikiCd,
      hanCd,
      genbaCd,
    };
    return await AxiosDelete(API_URL.DELETE_OTODOKESAKI, {}, params);
  };

  const mutation = useMutation(DeleteOtodokesaki as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });

  return mutation;
}

interface HanBinDel {
  torihikiCd: String;
  hanCd: String;
  genbaCds: any;
}
export function useDeleteHanBinCds() {
  const DeleteHanBinCds = async ({
    torihikiCd,
    hanCd,
    genbaCds,
  }: HanBinDel) => {
    const params = {
      torihikiCd,
      hanCd,
      genbaCds,
    };
    return await AxiosDelete(API_URL.DELETE_HAN_BINCDS, {}, params);
  };

  const mutation = useMutation(DeleteHanBinCds as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });

  return mutation;
}

export function useRestoreOtodokesaki() {
  const RestoreOtodokesaki = async ({
    torihikiCd,
    hanCd,
    genbaCd,
  }: OtodokesakiRequest) => {
    const params = {
      torihikiCd,
      hanCd,
      genbaCd,
    };
    return await AxiosPost(API_URL.RESTORE_OTODOKESAKI, params);
  };

  const mutation = useMutation(RestoreOtodokesaki as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });

  return mutation;
}
export function useRestoreHan() {
  const RestoreHan = async ({ torihikiCd, hanCd }: requestHan) => {
    const params = {
      torihikiCd,
      hanCd,
    };
    return await AxiosPost(API_URL.RESTORE_HAN, params);
  };

  const mutation = useMutation(RestoreHan as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });

  return mutation;
}
