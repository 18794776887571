/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Space,
  Tabs,
} from 'antd';
import { useLogAccess } from 'api/auth';
import { iDefinedValues } from 'api/common';
// import { iKigyoGroup, useGetKigyoGroup } from 'api/customer';
import { iKigyoType, useGetKigyoType } from 'api/customer';
import { useGetSiirem } from 'api/siirem';
import {
  useCheckHatCodeSyohinMainte,
  useCreateSyohinMainte,
  useDeleteSyohinMainte,
  useGetSyohinKbn,
  useGetSyohinMainte,
  useUpdateSyohinMainte,
} from 'api/syohin';
import LoadingCallAPI from 'components/LoadingCallAPI/LoadingCallAPI';
import { SwitchModeTabs } from 'components/StyleCommon';
import { PATH } from 'configs/routes';
import {
  checkForSpecialChar,
  Hankaku2Zenkaku,
  isDouble,
  IsHankaku,
  IsHankakuEisu,
  isNumeric,
  IsZenkaku,
  ToASCII,
} from 'constants/common';
import { MSG_ERROR } from 'constants/text';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectDefinedValues, selectUserInfo } from 'slice/app';
import messagePopup from 'utils/message/mesage';
import {
  BodyForm,
  FooterForm,
  FormWrapper,
  HeaderForm,
  TitleMainte,
  WrapperMainte,
} from 'views/login/FormLogin/formLogin.style';
import SyohinSearch from '../common/syohinSearch';
const { Option } = Select;

const MODE_CREATE = '登録モード';
const MODE_UPDATE = '修正モード';
const MODE_DELETE = '削除モード';

const TANICD_METER = 'M'; // 換算あり：メーター
const PICKING_NORMAL = ' '; // ピッキング出力条件：標準
const PICKING_ITIMAI = 'S'; // ピッキング出力条件：1品1枚
const PICKING_DIVISION = 'K'; // ピッキング出力条件：1品／
const PICKING_MULTIPLICATION = ' '; // ピッキング出力条件：1品×
const PICKING_MULTIPLICATION_FAKE = 'N';

const TEHAISYO_ON = '1'; // 手配書：要
const TEHAISYO_OFF = ''; // 手配書：不要
const HAIBAN_ON = 'X'; // 廃番
const HAIBAN_OFF = ''; // 廃番でない
const ZAIKOKBN_TORITUGI = '0'; // 在庫区分：取次ぎ
const ZAIKOKBN_ZAIKO = '1'; // 在庫区分：在庫
const SYOHINSIYO_ON = '1'; // 商品仕様：ファイルあり
const SYOHINSIYO_OFF = '0'; // 商品仕様：ファイルなし
const LSZ_NOT_METER = '1'; // 換算ありがメーターでない場合の換算係数

const SyohinMainte = () => {
  const definedValues: iDefinedValues = useSelector(selectDefinedValues);
  const AKAGI_SIIRESAKICD = definedValues?.akagi_SiiresakiCd;
  const HAT_SIIRESAKICD = definedValues?.hat_SiiresakiCd;

  const userInfo = useSelector(selectUserInfo);
  const navigate = useNavigate();
  const [mode, setMode] = React.useState(MODE_CREATE);
  const [isVisibleSyohin, setIsVisibleSyohin] = React.useState<boolean>(false);
  const [defaultFormValues, setDefaultFormValues] = React.useState<any>(null);
  const [disableInput, setDisableInput] = React.useState(false);
  const [valuesChange, setValuesChange] = React.useState(false);
  const [disable, setDisable] = React.useState({
    inputCd24: false,
    inputPtypeK: true,
    inputPtypeN: true,
    inputZaikoMaxOrderPoint: false,
    inputLsz: true,
    inputHanCD: true,
  });
  const [kigyoTypeOptions, setKigyoTypeOptions] = React.useState<any>([]);

  const [openDropdown, setOpenDropdown] = React.useState<{
    siirecd: boolean;
    code5Kbn: boolean;
  }>({
    siirecd: false,
    code5Kbn: false,
  });

  const previousHatCd24 = React.useRef<any>();
  const previousCode24 = React.useRef<any>();
  const refForm = React.useRef<any>({});
  const [form] = Form.useForm();

  function getKeyboardFocusableElements(element = document) {
    return [
      ...element.querySelectorAll(
        'a[href], button, input, textarea, select, details,[tabindex]:not([tabindex="-1"])',
      ),
    ].filter(
      el => !el.hasAttribute('disabled') && !el.getAttribute('aria-hidden'),
    );
  }

  const focusNextEle = (e: any) => {
    const keyboardFocusElement: any = getKeyboardFocusableElements();
    const index = keyboardFocusElement.findIndex(
      (element: any) => element === e.target,
    );
    if (index !== -1) {
      if (e.key === 'Enter' || (e.key === 'Tab' && !e.shiftKey)) {
        e.preventDefault();
        keyboardFocusElement[index + 1]
          ? keyboardFocusElement[index + 1]?.focus()
          : keyboardFocusElement[0]?.focus();
      } else if (e.shiftKey && e.key === 'Tab') {
        e.preventDefault();
        keyboardFocusElement[index - 1]
          ? keyboardFocusElement[index - 1]?.focus()
          : keyboardFocusElement[keyboardFocusElement.length]?.focus();
      }
    }
  };

  /**
   * *API LogAccess
   * todo log
   * @param {...}
   */
  const { mutate: logAccess } = useLogAccess();

  // /**
  //  * *API GetKigyogroupm
  //  * todo Get Kigyo groups to init dropdown
  //  * @param
  //  */
  // const {
  //   mutate: getKigyoGroup,
  //   data: dataKigyoGroup,
  //   isLoading: loadingKigyoGroup,
  // } = useGetKigyoGroup();

  /**
   * *API GetKigyotypem
   * todo Get Kigyo types to init dropdown
   * @param
   */
  const {
    mutate: getKigyoType,
    data: dataKigyoType,
    isLoading: loadingKigyoType,
  } = useGetKigyoType();

  /**
   * *API GetSiiresakiInfo
   * todo Get Siiresaki info to init dropdown
   * @param
   */
  const {
    data: dataSiirem,
    error: errorSiirem,
    isLoading: loadingSiirem,
    refetch: refetchSiirem,
    remove: removeSiirem,
  } = useGetSiirem();

  /**
   * *API GetSyohinKbnInfo
   * todo Get SyohinKbn info to init dropdown
   * @param
   */
  const {
    data: dataSyohinKbn,
    error: errorSyohinKbn,
    isLoading: loadingSyohinKbn,
    refetch: refetchSyohinKbn,
    remove: removeSyohinKbn,
  } = useGetSyohinKbn();

  /**
   * *API GET_SYOHIN_BYCD24
   * todo Get Syohin info to update or delete
   * @param cd24
   */
  const {
    mutate: mutationGetSyohinMainte,
    isLoading: loadingGetSyohinMainte,
    isSuccess: isSuccessGetSyohinMainte,
    reset: resetDataSyohinMainte,
  } = useGetSyohinMainte();

  /**
   * *API INSERT_SYOHIN
   * todo Input Syohin info to insert into system
   * @param {...syohin}
   */
  const {
    mutate: mutationCreateSyohinMainte,
    isLoading: loadingCreateSyohinMainte,
  } = useCreateSyohinMainte();

  /**
   * *API UPDATE_SYOHIN
   * todo Update syohin info
   * @param {...syohin}
   */
  const {
    mutate: mutationUpdateSyohinMainte,
    isLoading: loadingUpdateSyohinMainte,
  } = useUpdateSyohinMainte();

  /**
   * *API DELETE_SYOHIN
   * todo Delete syohin info
   * @param cd24
   */
  const {
    mutate: mutationDeleteSyohinMainte,
    isLoading: loadingDeleteSyohinMainte,
  } = useDeleteSyohinMainte();

  /**
   * *API CHECK_HATCODE
   * todo Delete syohin info
   * @param cd24
   */
  const {
    mutate: mutationCheckHatCodeSyohinMainte,
    // data: responseCheckHatCodeSyohinMainte,
    isLoading: loadingCheckHatCodeSyohinMainte,
  } = useCheckHatCodeSyohinMainte();

  //Init form in entry mode
  const initialValues = {
    cd24: '',
    nnm: '',
    nnmsz: '',
    anm: '',
    kpd: '',
    jyuryo: '',
    st3: '',
    bold: '',
    siirecd: null,
    nok: '1',
    arrivalcontrol: '0',
    tnicd: '',
    lsz: '1',
    code5Kbn: null,
    zaikokbn: '1',
    ptype: PICKING_NORMAL,
    location: '',
    zaikomax: '',
    orderpoint: '',
    hachutani: '',
    tninm: '',
    kigyoTypes: [],
    ordermax: '',
    siyo: '',
    hatcd24: '',
    captyCode24: '',
    st2: '',
    pbiasuN: '',
    pbiasuK: '',
  };

  // Set Mode
  const setModeNohinKbn = (mode: any) => {
    setMode(mode);
    setDisable({
      ...disable,
      inputCd24: false,
    });
    resetForm();
  };
  // Render label with mode
  function labelModeOnChange() {
    switch (mode) {
      case MODE_CREATE:
        return '登録モード';
      case MODE_UPDATE:
        return '修正モード';
      case MODE_DELETE:
        return '削除モード';
      default:
        break;
    }
  }

  // Click button done
  const onFinish = (values: any) => {
    const {
      // st1,
      st2,
      st3,
      cd24,
      // mkey,
      code5Kbn,
      anm,
      nnm,
      nnmsz,
      // vszyu,
      // tni,
      kpd,
      bold,
      nok,
      tnicd,
      tninm,
      jyuryo,
      lsz,
      kigyoTypes,
      ordermax,
      hatcd24,
      siirecd,
      ptype,
      zaikomax,
      orderpoint,
      hachutani,
      zaikokbn,
      location,
      captyCode24,
      siyo,
      arrivalcontrol,
      pbiasuN,
      pbiasuK,
    } = values;
    let request = {
      // st1: st1  null,
      st2: st2 ? HAIBAN_ON : HAIBAN_OFF,
      st3: st3 ? TEHAISYO_ON : TEHAISYO_OFF,
      cd24,
      // mkey: mkey ?? null,
      code5: code5Kbn?.split('-')[0],
      anm,
      kanm: anm,
      nnm,
      nnmsz,
      // vszyu: vszyu ?? 0,
      // cd10: null,
      // tni: tni ?? null,
      kpd: Number(kpd),
      bold: Number(bold),
      nok,
      tnicd: tnicd ? TANICD_METER : '',
      tninm,
      jyuryo: Number(jyuryo),
      lsz: Number(lsz),
      kigyoTypes,
      ordermax: Number(ordermax),
      hatcd24: hatcd24 ?? null,
      siirecd,
      syohinkbn: code5Kbn?.split('-')[1],
      ptype:
        ptype === PICKING_MULTIPLICATION_FAKE ? PICKING_MULTIPLICATION : ptype,
      pbiasu:
        ptype === PICKING_DIVISION
          ? Number(pbiasuK)
          : ptype === PICKING_MULTIPLICATION_FAKE
          ? Number(pbiasuN)
          : 0,
      zaikomax: Number(zaikomax),
      orderpoint: Number(orderpoint),
      hachutani: Number(hachutani),
      zaikokbn,
      location,
      captyCode24,
      siyo: siyo ? SYOHINSIYO_ON : SYOHINSIYO_OFF,
      arrivalcontrol: Number(arrivalcontrol),
    };

    function requestMutateSyohin() {
      switch (mode) {
        case MODE_CREATE:
          messagePopup({
            type: 'question',
            content: MSG_ERROR.MSG_CONFIRM_INSERT_SYOHIN_MAINTE,
            onOk: () =>
              mutationCreateSyohinMainte(request, {
                // Display msg when insert syohin success
                onSuccess(data, variables, context) {
                  logAccess([
                    {
                      tokuiCd: userInfo.torihikicd,
                      gyoumuId: '商品メンテ',
                      pgName: '登録モード',
                      logType: '2',
                      action: '確定ボタン',
                    },
                  ] as any);
                  messagePopup({
                    type: 'success',
                    content: MSG_ERROR.MSG_INSERT_SYOHIN_MAINTE_SUCCESS,
                    onOk: () => resetForm(),
                  });
                },
                // Display msg when insert syohin info failed
                onError(error, variables, context) {
                  messagePopup({
                    type: 'error',
                    content:
                      MSG_ERROR[(error as any)?.response?.data?.message] ||
                      error?.response?.data?.message,
                  });
                },
              }),
          });
          break;
        case MODE_UPDATE:
          messagePopup({
            type: 'question',
            content: MSG_ERROR.MSG_CONFIRM_UPDATE_SYOHIN_MAINTE,
            onOk: () =>
              mutationUpdateSyohinMainte(request, {
                // Display msg when update syohin success
                onSuccess(data, variables, context) {
                  logAccess([
                    {
                      tokuiCd: userInfo.torihikicd,
                      gyoumuId: '商品メンテ',
                      pgName: '修正モード',
                      logType: '2',
                      action: '確定ボタン',
                    },
                  ] as any);
                  messagePopup({
                    type: 'success',
                    content: MSG_ERROR.MSG_UPDATE_SYOHIN_MAINTE_SUCCESS,
                    onOk: () => resetForm(),
                  });
                },
                // Display msg when update syohin info failed
                onError(error, variables, context) {
                  messagePopup({
                    type: 'error',
                    content:
                      MSG_ERROR[(error as any)?.response?.data?.message] ||
                      error?.response?.data?.message,
                  });
                },
              }),
          });

          break;
        case MODE_DELETE:
          messagePopup({
            type: 'question',
            content: MSG_ERROR.MSG_CONFIRM_DELETE_SYOHIN_MAINTE,
            onOk: () =>
              mutationDeleteSyohinMainte(cd24, {
                // Display msg when delete syohin success
                onSuccess(data, variables, context) {
                  logAccess([
                    {
                      tokuiCd: userInfo.torihikicd,
                      gyoumuId: '商品メンテ',
                      pgName: '削除モード',
                      logType: '2',
                      action: '確定ボタン',
                    },
                  ] as any);
                  messagePopup({
                    type: 'success',
                    content: MSG_ERROR.MSG_DELETE_SYOHIN_MAINTE_SUCCESS,
                    onOk: () => resetForm(),
                  });
                },
                // Display msg when delete syohin info failed
                onError(error, variables, context) {
                  messagePopup({
                    type: 'error',
                    content:
                      MSG_ERROR[(error as any)?.response?.data?.message] ||
                      error?.response?.data?.message,
                  });
                },
              }),
          });
          break;
        default:
          break;
      }
    }
    if (!disable.inputHanCD) {
      mutationCheckHatCodeSyohinMainte(hatcd24, {
        onSuccess(data) {
          if (data?.data) {
            requestMutateSyohin();
          } else {
            messagePopup({
              type: 'info',
              content: MSG_ERROR['MSG_HAT_SYOHIN_NOT_EXIST'],
              onOk: () => {
                refForm?.current?.hatcd24?.focus();
                refForm?.current?.hatcd24?.select();
              },
            });
          }
        },
        // Display msg when check hatcode24 failed
        onError(error, variables, context) {
          messagePopup({
            type: 'error',
            content:
              MSG_ERROR[(error as any)?.response?.data?.message] ||
              error?.response?.data?.message,
            onOk: () => {
              refForm?.current?.hatcd24?.focus();
              refForm?.current?.hatcd24?.select();
            },
          });
        },
      });
    } else {
      requestMutateSyohin();
    }
  };

  // Display msg error validate
  const onFinishFailed = ({ values, errorFields }: any) => {
    // // console.log(values, errorFields);
    messagePopup({
      type: 'info',
      content: errorFields[0]?.errors[0],
      onOk: () => {
        refForm?.current?.[errorFields[0]?.name[0]]?.focus();
        !!refForm?.current?.[errorFields[0]?.name[0]]?.select?.toString() &&
          refForm?.current?.[errorFields[0]?.name[0]]?.select();
      },
    });
  };

  React.useEffect(() => {
    refetchSiirem();
    refetchSyohinKbn();
    getKigyoType(
      { fromCustomerMainte: false },
      {
        onSuccess({ data }, variables, context) {
          let options = {};
          data?.forEach((kigyotype: iKigyoType) => {
            if (!(kigyotype.kigyoKbn in options)) {
              options = {
                ...options,
                [`${kigyotype.kigyoKbn}`]: data?.filter(
                  (item: iKigyoType) => item.kigyoKbn === kigyotype.kigyoKbn,
                ),
              };
            }
          });
          setKigyoTypeOptions(options);
          if (mode === MODE_CREATE) {
            let formValues = {
              ...form.getFieldsValue(),
              kigyoTypes: data?.map(
                (kigyoType: iKigyoType) => kigyoType.kigyotypeId,
              ),
            };
            form.setFieldsValue(formValues);
            setDefaultFormValues(formValues);
          }
        },
        onError(error, variables, context) {
          messagePopup({
            type: 'info',
            content:
              MSG_ERROR[(error as any)?.response?.data?.message] ||
              (error as any)?.response?.data?.message,
          });
        },
      },
    );

    // getKigyoGroup(
    //   {},
    //   {
    //     onSuccess({ data }, variables, context) {
    //       setKigyoTypeOptions(data);
    //       if (mode === MODE_CREATE) {
    //         let formValues = {
    //           ...form.getFieldsValue(),
    //           kigyoGroups: data?.map(
    //             (kigyoType: iKigyoGroup) => kigyoType.kigyogroupId,
    //           ),
    //         };
    //         form.setFieldsValue(formValues);
    //         setDefaultFormValues(formValues);
    //       }
    //     },
    //     onError(error, variables, context) {
    //       messagePopup({
    //         type: 'info',
    //         content:
    //           MSG_ERROR[(error as any)?.response?.data?.message] ||
    //           (error as any)?.response?.data?.message,
    //       });
    //     },
    //   },
    // );
  }, []);

  // Set status disabled input with mode
  React.useEffect(() => {
    resetForm();
  }, [mode]);

  // Display msg when get Siirem failed
  React.useEffect(() => {
    if (!errorSiirem) {
      return;
    }
    messagePopup({
      type: 'error',
      content:
        MSG_ERROR[(errorSiirem as any)?.response?.data?.message] ||
        (errorSiirem as any)?.response?.data?.message,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorSiirem]);

  // Display msg when get SyohinKbn failed
  React.useEffect(() => {
    if (!errorSyohinKbn) {
      return;
    }
    messagePopup({
      type: 'error',
      content:
        MSG_ERROR[(errorSyohinKbn as any)?.response?.data?.message] ||
        (errorSyohinKbn as any)?.response?.data?.message,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorSyohinKbn]);

  // Reset form
  function resetForm() {
    form.resetFields();
    setValuesChange(false);
    if (mode === MODE_CREATE) {
      let formValues = {
        ...initialValues,
        kigyoTypes: dataKigyoType?.data?.map(
          (kigyoType: iKigyoType) => kigyoType.kigyotypeId,
        ),
      };
      form.setFieldsValue(formValues);
      setDefaultFormValues(formValues);
      setDisableInput(false);
    } else {
      setDisableInput(true);
      setDefaultFormValues(null);
    }
    resetDataSyohinMainte();
    setDisable({
      inputCd24: false,
      inputPtypeK: true,
      inputPtypeN: true,
      inputZaikoMaxOrderPoint: false,
      inputLsz: true,
      inputHanCD: true,
    });
    setTimeout(() => {
      refForm.current?.cd24.focus();
      refForm.current?.cd24.select();
    }, 300);
  }

  // Func call API GetSyohinDataBySyohinCd
  const getInfoSyohin = (cd24: string) => {
    const isChangedValue = cd24 !== previousCode24.current;
    if (!isChangedValue) return;
    if (mode !== MODE_CREATE) {
      if (cd24) {
        if (!IsHankaku(cd24)) {
          messagePopup({
            type: 'info',
            content: '品名コード' + MSG_ERROR['MSG_ZENKAKU'],
            onOk: () => {
              refForm?.current?.cd24?.focus();
              refForm?.current?.cd24?.select();
            },
          });
          return;
        } else if (checkForSpecialChar(cd24)) {
          messagePopup({
            type: 'info',
            content: '品名コード' + MSG_ERROR['MSG_INVALID_CHAR'],
            onOk: () => {
              refForm?.current?.cd24?.focus();
              refForm?.current?.cd24?.select();
            },
          });
        } else {
          mutationGetSyohinMainte(cd24, {
            // Display Syohin info when call API GET_SYOHIN_BYCD24 success
            onSuccess(data, variables, context) {
              const {
                st2,
                st3,
                cd24,
                anm,
                kanm,
                nnm,
                nnmsz,
                kpd,
                bold,
                nok,
                tnicd,
                tninm,
                jyuryo,
                lsz,
                kigyoTypes,
                ordermax,
                hatcd24,
                siirecd,
                syohinkbn,
                ptype,
                pbiasu,
                zaikomax,
                orderpoint,
                hachutani,
                zaikokbn,
                location,
                siyo,
                captyCode24,
                code5,
                arrivalcontrol,
              } = data?.data;

              //Trường hợp syohin có code5 nhưng syohinKbn = ' ' -> Replace để lấy syohinKbn từ listSyohinKbn
              let code5Kbn = syohinkbn.replace(' ', '')
                ? `${code5}-${syohinkbn}`
                : code5
                ? `${code5}-${
                    dataSyohinKbn?.data.find(
                      (syohinKbn: any) => syohinKbn.code5 === code5,
                    ).kbn
                  }`
                : null;

              let ptypeValue =
                ptype === PICKING_MULTIPLICATION && pbiasu.toString() !== '0'
                  ? PICKING_MULTIPLICATION_FAKE
                  : ptype;

              form.setFieldsValue({
                st2: st2 === HAIBAN_ON ? true : false,
                st3: st3 === TEHAISYO_ON ? true : false,
                cd24,
                anm,
                kanm,
                nnm,
                nnmsz,
                kpd: kpd.toString(),
                bold: bold.toString(),
                nok,
                tnicd: tnicd === TANICD_METER ? true : false,
                tninm,
                jyuryo: jyuryo.toString(),
                lsz: lsz.toString(),
                kigyoTypes,
                ordermax: ordermax.toString(),
                hatcd24,
                siirecd,
                zaikomax: zaikomax.toString(),
                orderpoint: orderpoint.toString(),
                hachutani: hachutani.toString(),
                zaikokbn,
                location,
                siyo: siyo === SYOHINSIYO_ON ? true : false,
                captyCode24,
                code5Kbn: code5Kbn,
                arrivalcontrol: arrivalcontrol.toString(),
                ptype: ptypeValue,
                pbiasuK:
                  ptypeValue === PICKING_DIVISION ? pbiasu.toString() : '',
                pbiasuN:
                  ptypeValue === PICKING_MULTIPLICATION_FAKE
                    ? pbiasu.toString()
                    : '',
              });

              mode === MODE_UPDATE && setDisableInput(false);
              setDisable({
                ...disable,
                inputCd24: cd24 ? true : false,
                inputPtypeK: ptypeValue !== PICKING_DIVISION ? true : false,
                inputPtypeN:
                  ptypeValue !== PICKING_MULTIPLICATION_FAKE ? true : false,
                inputZaikoMaxOrderPoint:
                  zaikokbn !== ZAIKOKBN_ZAIKO ? true : false,
                inputLsz: tnicd !== TANICD_METER ? true : false,
                inputHanCD:
                  siirecd === HAT_SIIRESAKICD || siirecd === AKAGI_SIIRESAKICD
                    ? false
                    : true,
              });
              setTimeout(() => {
                refForm.current?.nnm.focus();
                refForm.current?.nnm.select();
                setDefaultFormValues(form.getFieldsValue());
              }, 300);
            },
            onError(error, variables, context) {
              // Display msg when get syohin info failed
              messagePopup({
                type: 'error',
                content:
                  MSG_ERROR[(error as any)?.response?.data?.message] ||
                  error?.response?.data?.message,
                onOk: () => {
                  refForm?.current?.cd24?.focus();
                  refForm?.current?.cd24?.select();
                },
              });
            },
          });
        }
      } else {
        form.resetFields();
      }
    }
  };

  // Render element
  return (
    <WrapperMainte style={{ width: 990 }}>
      {(loadingSiirem ||
        loadingSyohinKbn ||
        loadingGetSyohinMainte ||
        loadingCreateSyohinMainte ||
        loadingUpdateSyohinMainte ||
        loadingCheckHatCodeSyohinMainte ||
        loadingDeleteSyohinMainte ||
        loadingKigyoType) && <LoadingCallAPI />}
      <FormWrapper
        form={form}
        className="form-mainte"
        name="syohin-mainte"
        labelCol={{ flex: '275px' }}
        wrapperCol={{ flex: 1 }}
        onFinish={onFinish}
        requiredMark={false}
        labelAlign="left"
        colon={false}
        validateTrigger="onSubmit"
        onFinishFailed={onFinishFailed}
        scrollToFirstError
        onKeyPress={e => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
        onBlur={() => {
          if (
            defaultFormValues &&
            JSON.stringify(defaultFormValues) !==
              JSON.stringify(form.getFieldsValue())
          ) {
            setValuesChange(true);
          } else {
            setValuesChange(false);
          }
        }}
      >
        <HeaderForm>
          <TitleMainte>
            <span className="label-mode">{labelModeOnChange()}</span>
          </TitleMainte>
          <Space>
            <b>モード選択：</b>
            <SwitchModeTabs
              activeKey={mode}
              onChange={key => {
                function changeKey(toKey: any) {
                  switch (toKey) {
                    case MODE_CREATE:
                      logAccess([
                        {
                          tokuiCd: userInfo.torihikicd,
                          gyoumuId: '商品メンテ',
                          pgName: mode,
                          logType: '2',
                          action: '登録ボタン',
                        },
                      ] as any);
                      break;
                    case MODE_UPDATE:
                      logAccess([
                        {
                          tokuiCd: userInfo.torihikicd,
                          gyoumuId: '商品メンテ',
                          pgName: mode,
                          logType: '2',
                          action: '修正ボタン',
                        },
                      ] as any);
                      break;
                    case MODE_DELETE:
                      logAccess([
                        {
                          tokuiCd: userInfo.torihikicd,
                          gyoumuId: '商品メンテ',
                          pgName: mode,
                          logType: '2',
                          action: '削除ボタン',
                        },
                      ] as any);
                      break;
                    default:
                      break;
                  }
                  setModeNohinKbn(toKey);
                }
                if (valuesChange) {
                  messagePopup({
                    type: 'warning',
                    content: MSG_ERROR['MSG_VALUES_CHANGE'],
                    onOk: () => {
                      changeKey(key);
                      setValuesChange(false);
                    },
                    onCancel: () => refForm?.current?.cd24?.focus(),
                  });
                } else {
                  changeKey(key);
                }
              }}
            >
              <Tabs.TabPane tab={'登録モード'} key={MODE_CREATE} />
              <Tabs.TabPane tab={'修正モード'} key={MODE_UPDATE} />
              <Tabs.TabPane tab={'削除モード'} key={MODE_DELETE} />
            </SwitchModeTabs>
          </Space>
        </HeaderForm>
        <BodyForm>
          <span>設定情報</span>
          <Form.Item
            label="　１．品名コード(半角11桁)"
            style={{ marginBottom: 24 }}
          >
            <Space size={4}>
              <Form.Item
                name="cd24"
                noStyle
                rules={[
                  () => ({
                    validator(_: any, value: string) {
                      if (!disable.inputCd24) {
                        // 必須チェック
                        if (!value) {
                          return Promise.reject(
                            new Error('品名コード' + MSG_ERROR['MSG_INPUT']),
                          );
                        }
                        // 禁則文字チェック
                        if (checkForSpecialChar(value)) {
                          return Promise.reject(
                            new Error(
                              '品名コード' + MSG_ERROR['MSG_INVALID_CHAR'],
                            ),
                          );
                        }
                        // 半角チェック
                        if (!IsHankaku(value)) {
                          return Promise.reject(
                            new Error('品名コード' + MSG_ERROR['MSG_ZENKAKU']),
                          );
                        }
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input
                  ref={el => (refForm.current.cd24 = el)}
                  disabled={disable.inputCd24 || loadingGetSyohinMainte}
                  onBlur={event => {
                    getInfoSyohin(ToASCII(event.target.value.trim()));
                    form.setFieldsValue({
                      cd24: ToASCII(event.target.value.trim()),
                    });
                  }}
                  onKeyUp={event =>
                    (event.key === 'Enter' || event.key === 'Tab') &&
                    event.currentTarget.select()
                  }
                  onFocus={event => {
                    previousCode24.current = event.target.value;
                  }}
                  onKeyDown={focusNextEle}
                  maxLength={11}
                  style={{ width: 170 }}
                />
              </Form.Item>
              {mode !== MODE_CREATE && (
                <Button
                  ref={el => (refForm.current.buttonSearch = el)}
                  disabled={disable.inputCd24}
                  type="default"
                  onClick={() => {
                    logAccess([
                      {
                        tokuiCd: userInfo.torihikicd,
                        gyoumuId: '商品メンテ',
                        pgName: '商品メンテ',
                        logType: '2',
                        action: '商品検索ボタン',
                      },
                      {
                        tokuiCd: userInfo.torihikicd,
                        gyoumuId: '商品メンテ',
                        pgName: '商品検索',
                        logType: '1',
                        action: '',
                      },
                    ] as any);
                    setIsVisibleSyohin(true);
                  }}
                  onKeyDown={focusNextEle}
                >
                  商品検索
                </Button>
              )}
            </Space>
          </Form.Item>
          <Form.Item
            className={disableInput ? 'disable-input' : ''}
            label="　２．品名(全角漢字15桁)"
            name="nnm"
            rules={[
              () => ({
                validator(_: any, value: string) {
                  if (!disableInput) {
                    // 必須チェック
                    if (!value) {
                      return Promise.reject(
                        new Error('品名' + MSG_ERROR['MSG_INPUT']),
                      );
                    }
                    // 全角チェック
                    if (!IsZenkaku(value)) {
                      return Promise.reject(
                        new Error('品名' + MSG_ERROR['MSG_HANKAKU']),
                      );
                    }
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              ref={el => (refForm.current.nnm = el)}
              disabled={disableInput}
              maxLength={15}
              style={{ width: 300 }}
              onBlur={event => {
                form.setFieldsValue({
                  nnm: Hankaku2Zenkaku(event.target.value.trimEnd()),
                });
              }}
              onKeyUp={event =>
                (event.key === 'Enter' || event.key === 'Tab') &&
                event.currentTarget.select()
              }
              onKeyDown={focusNextEle}
            />
          </Form.Item>
          <Form.Item
            className={disableInput ? 'disable-input' : ''}
            label="　３．規格(全角15桁)"
            name="nnmsz"
            rules={[
              () => ({
                validator(_: any, value: string) {
                  if (!disableInput) {
                    if (!value) {
                      return Promise.resolve();
                    }
                    // 全角チェック
                    if (!IsZenkaku(value)) {
                      return Promise.reject(
                        new Error('規格' + MSG_ERROR['MSG_HANKAKU']),
                      );
                    }
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              ref={el => (refForm.current.nnmsz = el)}
              disabled={disableInput}
              maxLength={15}
              style={{ width: 300 }}
              onBlur={event => {
                form.setFieldsValue({
                  nnmsz: Hankaku2Zenkaku(event.target.value.trimEnd()),
                });
              }}
              onKeyUp={event =>
                (event.key === 'Enter' || event.key === 'Tab') &&
                event.currentTarget.select()
              }
              onKeyDown={focusNextEle}
            />
          </Form.Item>
          <Form.Item
            className={disableInput ? 'disable-input' : ''}
            label="　４．品名かな(半角50桁)"
            name="anm"
            rules={[
              () => ({
                validator(_: any, value: string) {
                  if (!disableInput) {
                    // 必須チェック
                    if (!value) {
                      return Promise.reject(
                        new Error('品名かな' + MSG_ERROR['MSG_INPUT']),
                      );
                    }
                    // 全角チェック
                    if (!IsHankaku(value)) {
                      return Promise.reject(
                        new Error('品名かな' + MSG_ERROR['MSG_ZENKAKU']),
                      );
                    }
                    // 禁則文字チェック
                    if (checkForSpecialChar(value)) {
                      return Promise.reject(
                        new Error('品名かな' + MSG_ERROR['MSG_INVALID_CHAR']),
                      );
                    }
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              ref={el => (refForm.current.anm = el)}
              disabled={disableInput}
              maxLength={50}
              style={{ width: 600 }}
              onBlur={event => {
                form.setFieldsValue({
                  anm: ToASCII(event.target.value.trimEnd(), true),
                });
              }}
              onKeyUp={event =>
                (event.key === 'Enter' || event.key === 'Tab') &&
                event.currentTarget.select()
              }
              onKeyDown={focusNextEle}
            />
          </Form.Item>
          <Form.Item
            className={disableInput ? 'disable-input' : ''}
            label="　５．入数(半角数字4桁)"
            name="kpd"
            rules={[
              () => ({
                validator(_: any, value: string) {
                  if (!disableInput) {
                    // 必須チェック
                    if (!value) {
                      return Promise.reject(
                        new Error('入数' + MSG_ERROR['MSG_INPUT']),
                      );
                    }
                    // 数値チェック
                    if (!isNumeric(value)) {
                      return Promise.reject(
                        new Error('入数' + MSG_ERROR['MSG_NUMERIC']),
                      );
                    }
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              className="input-number"
              ref={el => (refForm.current.kpd = el)}
              disabled={disableInput}
              maxLength={4}
              style={{ width: 80 }}
              onBlur={event => {
                form.setFieldsValue({
                  kpd: ToASCII(
                    event.target.value.trim().replaceAll(',', ''),
                    false,
                  ),
                });
              }}
              onKeyUp={event =>
                (event.key === 'Enter' || event.key === 'Tab') &&
                event.currentTarget.select()
              }
              onKeyDown={focusNextEle}
            />
          </Form.Item>
          <Form.Item
            className={disableInput ? 'disable-input' : ''}
            label="　６．重量(半角数字7.2桁)"
            name="jyuryo"
            rules={[
              () => ({
                validator(_: any, value: string) {
                  if (!disableInput) {
                    // 必須チェック
                    if (!value) {
                      return Promise.reject(
                        new Error('重量' + MSG_ERROR['MSG_INPUT']),
                      );
                    }

                    // 数値チェック
                    if (!/^[0-9.]+$/.test(value)) {
                      return Promise.reject(
                        new Error('重量' + MSG_ERROR['MSG_DOUBLE']),
                      );
                    }

                    // 重量入力範囲チェック
                    if (!isDouble(value)) {
                      return Promise.reject(
                        new Error(
                          '重量' +
                            MSG_ERROR['MSG_DOUBLE_LENGTH']
                              .replace('%整数部%', '7')
                              .replace('%小数部%', '2'),
                        ),
                      );
                    }
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              className="input-number"
              ref={el => (refForm.current.jyuryo = el)}
              addonAfter="Kg"
              maxLength={10}
              disabled={disableInput}
              style={{ width: 150 }}
              onBlur={event => {
                form.setFieldsValue({
                  jyuryo: ToASCII(
                    event.target.value.trim().replaceAll(',', ''),
                    false,
                  ),
                });
              }}
              onKeyUp={event =>
                (event.key === 'Enter' || event.key === 'Tab') &&
                event.currentTarget.select()
              }
              onKeyDown={focusNextEle}
            />
          </Form.Item>
          <Form.Item
            className={disableInput ? 'disable-input' : ''}
            label="　７．手配書"
            name="st3"
            valuePropName="checked"
          >
            <Checkbox
              ref={el => (refForm.current.st3 = el)}
              disabled={disableInput}
              onKeyDown={(event: any) => {
                event.preventDefault();

                if (event.keyCode === 32) {
                  form.setFieldsValue({
                    st3: !event.target.checked,
                  });
                }
                focusNextEle(event);
              }}
            >
              要手配
            </Checkbox>
          </Form.Item>
          <Form.Item
            className={disableInput ? 'disable-input' : ''}
            label="　８．販売単価(半角数字7.2桁)"
            name="bold"
            rules={[
              () => ({
                validator(_: any, value: string) {
                  if (!disableInput) {
                    // 必須チェック
                    if (!value) {
                      return Promise.reject(
                        new Error('販売単価' + MSG_ERROR['MSG_INPUT']),
                      );
                    }

                    // 数値チェック
                    if (!/^[0-9.]+$/.test(value)) {
                      return Promise.reject(
                        new Error('販売単価' + MSG_ERROR['MSG_DOUBLE']),
                      );
                    }

                    // 販売単価入力範囲チェック
                    if (!isDouble(value)) {
                      return Promise.reject(
                        new Error(
                          '販売単価' +
                            MSG_ERROR['MSG_DOUBLE_LENGTH']
                              .replace('%整数部%', '7')
                              .replace('%小数部%', '2'),
                        ),
                      );
                    }
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              className="input-number"
              ref={el => (refForm.current.bold = el)}
              disabled={disableInput}
              maxLength={10}
              style={{ width: 150 }}
              onBlur={event => {
                form.setFieldsValue({
                  bold: ToASCII(
                    event.target.value.trim().replaceAll(',', ''),
                    false,
                  ),
                });
              }}
              onKeyUp={event =>
                (event.key === 'Enter' || event.key === 'Tab') &&
                event.currentTarget.select()
              }
              onKeyDown={focusNextEle}
            />
          </Form.Item>
          <Form.Item
            className={disableInput ? 'disable-input' : ''}
            label="　９．仕入先"
            name="siirecd"
            rules={[
              () => ({
                validator(_: any, value: string) {
                  if (!disableInput) {
                    // 必須チェック
                    if (!value) {
                      return Promise.reject(
                        new Error('仕入先' + MSG_ERROR['MSG_SELECT']),
                      );
                    }
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Select
              open={openDropdown.siirecd}
              onDropdownVisibleChange={open =>
                setOpenDropdown({ ...openDropdown, siirecd: open })
              }
              onKeyDown={(event: any) => {
                focusNextEle(event);
                if (event.key === 'Enter') {
                  setOpenDropdown({ ...openDropdown, siirecd: false });
                } else if (event.key === ' ') {
                  event.preventDefault();
                  setOpenDropdown({ ...openDropdown, siirecd: true });
                }
              }}
              placeholder={'選択してください'}
              ref={el => (refForm.current.siirecd = el)}
              disabled={disableInput}
              style={{ width: 250 }}
              // showAction={['focus']}
              onChange={value => {
                setDisable({
                  ...disable,
                  inputHanCD:
                    value === HAT_SIIRESAKICD || value === AKAGI_SIIRESAKICD
                      ? false
                      : true,
                });
                if (!(value === HAT_SIIRESAKICD || value === AKAGI_SIIRESAKICD))
                  form.setFieldsValue({
                    hatcd24: null,
                  });
                if (value) {
                  refForm.current.nok.focus();
                }
              }}
              getPopupContainer={(trigger: HTMLElement) =>
                trigger.parentNode as HTMLElement
              }
            >
              {dataSiirem?.data?.map((item: any) => {
                return (
                  <Option value={item.siirecd} key={item.siirecd}>
                    {`${item.siirecd} ${item.siirenm}`}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            className={disableInput ? 'disable-input' : ''}
            label="１０．リードタイム(半角数字2桁)"
            name="nok"
            rules={[
              () => ({
                validator(_: any, value: string) {
                  if (!disableInput) {
                    // 必須チェック
                    if (!value) {
                      return Promise.reject(
                        new Error('リードタイム' + MSG_ERROR['MSG_INPUT']),
                      );
                    }
                    // -1から99までを登録許可する
                    if (
                      !isNumeric(value, true) ||
                      Number(value) < -1 ||
                      Number(value) > 99
                    ) {
                      return Promise.reject(
                        new Error(MSG_ERROR['MSG_LEADTIME_INPUT_OVER']),
                      );
                    }
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              className="input-number"
              ref={el => (refForm.current.nok = el)}
              disabled={disableInput}
              maxLength={2}
              style={{ width: 40 }}
              onBlur={event => {
                form.setFieldsValue({
                  nok: ToASCII(
                    event.target.value.trim().replaceAll(',', ''),
                    false,
                  ),
                });
              }}
              onKeyUp={event =>
                (event.key === 'Enter' || event.key === 'Tab') &&
                event.currentTarget.select()
              }
              onKeyDown={focusNextEle}
            />
          </Form.Item>
          <Form.Item
            className={disableInput ? 'disable-input' : ''}
            label="１１．入荷調整日(半角数字2桁)"
          >
            <Space>
              <Form.Item
                name="arrivalcontrol"
                noStyle
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_: any, value: string) {
                      let nok = Number(getFieldValue('nok'));
                      if (!disableInput) {
                        // 必須チェック
                        if (!value) {
                          return Promise.reject(
                            new Error('入荷調整日' + MSG_ERROR['MSG_INPUT']),
                          );
                        }
                        // 数値チェック
                        if (!isNumeric(value)) {
                          return Promise.reject(
                            new Error('入荷調整日' + MSG_ERROR['MSG_NUMERIC']),
                          );
                        }
                        // 入力範囲チェック(リードタイム < 入荷調整日の場合はエラー)
                        if (nok >= 0) {
                          if (nok < Number(value)) {
                            return Promise.reject(
                              new Error(
                                MSG_ERROR['MSG_ARRIVALCONTROL_INPUT_OVER'],
                              ),
                            );
                          }
                        } else {
                          if (Number(value) !== 0) {
                            return Promise.reject(
                              new Error(
                                MSG_ERROR[
                                  'MSG_ARRIVALCONTROL_INPUT_OVER_MINUS'
                                ],
                              ),
                            );
                          }
                        }
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input
                  className="input-number"
                  ref={el => (refForm.current.arrivalcontrol = el)}
                  disabled={disableInput}
                  maxLength={2}
                  style={{ width: 40 }}
                  onBlur={event => {
                    form.setFieldsValue({
                      arrivalcontrol: ToASCII(
                        event.target.value.trim().replaceAll(',', ''),
                        false,
                      ),
                    });
                  }}
                  onKeyUp={event =>
                    (event.key === 'Enter' || event.key === 'Tab') &&
                    event.currentTarget.select()
                  }
                  onKeyDown={focusNextEle}
                />
              </Form.Item>
              <span>※指定日分入荷日を早めます</span>
            </Space>
          </Form.Item>
          <Form.Item
            className={disableInput ? 'disable-input' : ''}
            label="１２．換算あり"
            name="tnicd"
            valuePropName="checked"
          >
            <Checkbox
              ref={el => (refForm.current.tnicd = el)}
              onChange={e => {
                let checked = e.target.checked;
                form.setFieldsValue({
                  lsz: checked ? '' : LSZ_NOT_METER,
                });
                setDisable({
                  ...disable,
                  inputLsz: !checked ? true : false,
                });
              }}
              disabled={disableInput}
              onKeyDown={focusNextEle}
            >
              必要
            </Checkbox>
          </Form.Item>
          <Form.Item
            className={disableInput ? 'disable-input' : ''}
            label="１３．換算係数(半角数字7.2桁)"
          >
            <Space>
              <Form.Item
                name="lsz"
                noStyle
                rules={[
                  () => ({
                    validator(_: any, value: string) {
                      if (!(disable.inputLsz || disableInput)) {
                        // 換算あり＝メーターがチェックされている場合、必須
                        if (!value) {
                          return Promise.reject(
                            new Error('換算係数' + MSG_ERROR['MSG_INPUT']),
                          );
                        }

                        // 数値チェック
                        if (!/^[0-9.]+$/.test(value)) {
                          return Promise.reject(
                            new Error('換算係数' + MSG_ERROR['MSG_DOUBLE']),
                          );
                        }

                        // 換算係数入力範囲チェック
                        if (!isDouble(value)) {
                          return Promise.reject(
                            new Error(
                              '換算係数' +
                                MSG_ERROR['MSG_DOUBLE_LENGTH']
                                  .replace('%整数部%', '7')
                                  .replace('%小数部%', '2'),
                            ),
                          );
                        }
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input
                  className="input-number"
                  maxLength={10}
                  ref={el => (refForm.current.lsz = el)}
                  addonAfter="m"
                  disabled={disable.inputLsz || disableInput}
                  style={{ width: 150 }}
                  onBlur={event => {
                    form.setFieldsValue({
                      lsz: ToASCII(
                        event.target.value.trim().replaceAll(',', ''),
                        false,
                      ),
                    });
                  }}
                  onKeyUp={event =>
                    (event.key === 'Enter' || event.key === 'Tab') &&
                    event.currentTarget.select()
                  }
                  onKeyDown={focusNextEle}
                />
              </Form.Item>
              <span>※換算必要の場合、入力</span>
            </Space>
          </Form.Item>
          <Form.Item
            className={disableInput ? 'disable-input' : ''}
            label="１４．商品区分"
            name="code5Kbn"
            rules={[
              () => ({
                validator(_: any, value: string) {
                  if (!disableInput) {
                    // 必須チェック
                    if (!value) {
                      return Promise.reject(
                        new Error('商品区分' + MSG_ERROR['MSG_SELECT']),
                      );
                    }
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Select
              open={openDropdown.code5Kbn}
              onDropdownVisibleChange={open =>
                setOpenDropdown({ ...openDropdown, code5Kbn: open })
              }
              onKeyDown={(event: any) => {
                if (event.key === 'Enter') {
                  setOpenDropdown({ ...openDropdown, code5Kbn: false });
                } else if (event.key === ' ') {
                  event.preventDefault();
                  setOpenDropdown({ ...openDropdown, code5Kbn: true });
                }
                focusNextEle(event);
              }}
              placeholder={'選択してください'}
              ref={el => (refForm.current.code5Kbn = el)}
              disabled={disableInput}
              style={{ width: 250 }}
              onChange={(value, option) => {
                if (value) {
                  refForm.current.location.focus();
                }
              }}
              getPopupContainer={(trigger: HTMLElement) =>
                trigger.parentNode as HTMLElement
              }
            >
              {dataSyohinKbn?.data?.map((item: any) => {
                return (
                  <Option key={item.code5} value={`${item.code5}-${item.kbn}`}>
                    {item.code5nm}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            className={disableInput ? 'disable-input' : ''}
            label="１５．在庫区分"
            name="zaikokbn"
          >
            <Radio.Group
              onChange={e => {
                let zaikokbn = e.target.value;
                setDisable({
                  ...disable,
                  inputZaikoMaxOrderPoint:
                    zaikokbn !== ZAIKOKBN_ZAIKO ? true : false,
                });
                form.setFieldsValue({
                  orderpoint: zaikokbn === ZAIKOKBN_TORITUGI ? 0 : '',
                  hachutani: zaikokbn === ZAIKOKBN_TORITUGI ? 0 : '',
                  zaikomax: zaikokbn === ZAIKOKBN_TORITUGI ? 0 : '',
                });
              }}
              disabled={disableInput}
            >
              <Radio value={ZAIKOKBN_ZAIKO} onKeyDown={focusNextEle}>
                貯蔵品
              </Radio>
              <Radio value={ZAIKOKBN_TORITUGI} onKeyDown={focusNextEle}>
                取寄品
              </Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            className={disableInput ? 'disable-input' : ''}
            label="１６．ピッキングシート出力条件"
            name="ptype"
          >
            <Radio.Group
              disabled={disableInput}
              onChange={e => {
                let ptype = e.target.value;
                form.setFieldsValue({
                  pbiasuN: '',
                  pbiasuK: '',
                });
                setDisable({
                  ...disable,
                  inputPtypeK: ptype !== PICKING_DIVISION ? true : false,
                  inputPtypeN:
                    ptype !== PICKING_MULTIPLICATION_FAKE ? true : false,
                });
                setTimeout(() => {
                  switch (ptype) {
                    case PICKING_MULTIPLICATION_FAKE:
                      refForm.current.pbiasuN.focus();
                      break;
                    case PICKING_DIVISION:
                      refForm.current.pbiasuK.focus();
                      break;
                    default:
                      break;
                  }
                }, 0);
              }}
            >
              <Radio value={PICKING_NORMAL} onKeyDown={focusNextEle}>
                標準
              </Radio>
              <Radio value={PICKING_ITIMAI} onKeyDown={focusNextEle}>
                1品目で1枚
              </Radio>
              <Radio
                value={PICKING_MULTIPLICATION_FAKE}
                onKeyDown={focusNextEle}
              >
                1個で
                <Form.Item
                  name="pbiasuN"
                  noStyle
                  rules={[
                    () => ({
                      validator(_: any, value: string) {
                        if (!(disable.inputPtypeN || disableInput)) {
                          // 必須チェック
                          if (!value) {
                            return Promise.reject(
                              new Error(
                                'ピッキング出力条件の倍数' +
                                  MSG_ERROR['MSG_INPUT'],
                              ),
                            );
                          }
                          // 数値チェック
                          if (!isNumeric(value)) {
                            return Promise.reject(
                              new Error(
                                'ピッキング出力条件の倍数' +
                                  MSG_ERROR['MSG_NUMERIC'],
                              ),
                            );
                          }
                          // 入力範囲チェック
                          if (Number(value) === 0) {
                            return Promise.reject(
                              new Error(MSG_ERROR['MSG_PBAISU_NOT_INPUT_ZERO']),
                            );
                          }
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input
                    ref={el => (refForm.current.pbiasuN = el)}
                    className="input-number"
                    maxLength={2}
                    disabled={disable.inputPtypeN || disableInput}
                    style={{ width: 40, margin: '0 2px' }}
                    onBlur={event => {
                      form.setFieldsValue({
                        pbiasuN: ToASCII(
                          event.target.value.trim().replaceAll(',', ''),
                          false,
                        ),
                      });
                    }}
                    onKeyUp={event =>
                      (event.key === 'Enter' || event.key === 'Tab') &&
                      event.currentTarget.select()
                    }
                    onKeyDown={focusNextEle}
                  />
                </Form.Item>
                枚
              </Radio>
              <Radio value={PICKING_DIVISION} onKeyDown={focusNextEle}>
                <Form.Item
                  name="pbiasuK"
                  noStyle
                  rules={[
                    () => ({
                      validator(_: any, value: string) {
                        if (!(disable.inputPtypeK || disableInput)) {
                          // 必須チェック
                          if (!value) {
                            return Promise.reject(
                              new Error(
                                'ピッキング出力条件の倍数' +
                                  MSG_ERROR['MSG_INPUT'],
                              ),
                            );
                          }
                          // 数値チェック
                          if (!isNumeric(value)) {
                            return Promise.reject(
                              new Error(
                                'ピッキング出力条件の倍数' +
                                  MSG_ERROR['MSG_NUMERIC'],
                              ),
                            );
                          }
                          // 入力範囲チェック
                          if (Number(value) === 0) {
                            return Promise.reject(
                              new Error(MSG_ERROR['MSG_PBAISU_NOT_INPUT_ZERO']),
                            );
                          }
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input
                    ref={el => (refForm.current.pbiasuK = el)}
                    className="input-number"
                    maxLength={2}
                    disabled={disable.inputPtypeK || disableInput}
                    style={{ width: 40, margin: '0 2px' }}
                    onBlur={event => {
                      form.setFieldsValue({
                        pbiasuK: ToASCII(
                          event.target.value.trim().replaceAll(',', ''),
                          false,
                        ),
                      });
                    }}
                    onKeyUp={event =>
                      (event.key === 'Enter' || event.key === 'Tab') &&
                      event.currentTarget.select()
                    }
                    onKeyDown={focusNextEle}
                  />
                </Form.Item>
                個で1枚
              </Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            className={disableInput ? 'disable-input' : ''}
            label="１７．ロケーション(半角英数字5桁)"
            name="location"
            rules={[
              () => ({
                validator(_: any, value: string) {
                  if (!disableInput) {
                    // 必須チェック
                    if (!value) {
                      return Promise.reject(
                        new Error('ロケーション' + MSG_ERROR['MSG_INPUT']),
                      );
                    }
                    // 禁則文字チェック
                    if (checkForSpecialChar(value)) {
                      return Promise.reject(
                        new Error(
                          'ロケーション' + MSG_ERROR['MSG_INVALID_CHAR'],
                        ),
                      );
                    }
                    // 半角英数字チェック
                    if (IsHankakuEisu(value)) {
                      return Promise.reject(
                        new Error(
                          'ロケーション' + MSG_ERROR['MSG_HANKAKU_EISU'],
                        ),
                      );
                    }
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              ref={el => (refForm.current.location = el)}
              disabled={disableInput}
              maxLength={5}
              style={{ width: 120 }}
              onBlur={event => {
                form.setFieldsValue({
                  location: ToASCII(event.target.value.trim(), true),
                });
              }}
              onKeyUp={event =>
                (event.key === 'Enter' || event.key === 'Tab') &&
                event.currentTarget.select()
              }
              onKeyDown={focusNextEle}
            />
          </Form.Item>
          <Form.Item
            className={disableInput ? 'disable-input' : ''}
            label="１８．最大在庫数(半角数字6桁)"
            name="zaikomax"
            rules={[
              () => ({
                validator(_: any, value: string) {
                  if (!(disable.inputZaikoMaxOrderPoint || disableInput)) {
                    // 必須チェック
                    if (!value) {
                      return Promise.reject(
                        new Error('最大在庫数' + MSG_ERROR['MSG_INPUT']),
                      );
                    }
                    // 数値チェック
                    if (!isNumeric(value)) {
                      return Promise.reject(
                        new Error('最大在庫数' + MSG_ERROR['MSG_NUMERIC']),
                      );
                    }
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              className="input-number"
              ref={el => (refForm.current.zaikomax = el)}
              disabled={disable.inputZaikoMaxOrderPoint || disableInput}
              maxLength={6}
              style={{ width: 120 }}
              onBlur={event => {
                form.setFieldsValue({
                  zaikomax: ToASCII(
                    event.target.value.trim().replaceAll(',', ''),
                    false,
                  ),
                });
              }}
              onKeyUp={event =>
                (event.key === 'Enter' || event.key === 'Tab') &&
                event.currentTarget.select()
              }
              onKeyDown={focusNextEle}
            />
          </Form.Item>
          <Form.Item
            className={disableInput ? 'disable-input' : ''}
            label="１９．発注点(半角数字6桁)"
            name="orderpoint"
            rules={[
              () => ({
                validator(_: any, value: string) {
                  if (!(disable.inputZaikoMaxOrderPoint || disableInput)) {
                    // 必須チェック
                    if (!value) {
                      return Promise.reject(
                        new Error('発注点' + MSG_ERROR['MSG_INPUT']),
                      );
                    }
                    // 数値チェック
                    if (!isNumeric(value)) {
                      return Promise.reject(
                        new Error('発注点' + MSG_ERROR['MSG_NUMERIC']),
                      );
                    }
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              className="input-number"
              ref={el => (refForm.current.orderpoint = el)}
              disabled={disable.inputZaikoMaxOrderPoint || disableInput}
              maxLength={6}
              style={{ width: 120 }}
              onBlur={event => {
                form.setFieldsValue({
                  orderpoint: ToASCII(
                    event.target.value.trim().replaceAll(',', ''),
                    false,
                  ),
                });
              }}
              onKeyUp={event =>
                (event.key === 'Enter' || event.key === 'Tab') &&
                event.currentTarget.select()
              }
              onKeyDown={focusNextEle}
            />
          </Form.Item>
          <Form.Item
            className={disableInput ? 'disable-input' : ''}
            label="２０．発注単位(半角数字6桁)"
            name="hachutani"
            rules={[
              () => ({
                validator(_: any, value: string) {
                  if (!(disable.inputZaikoMaxOrderPoint || disableInput)) {
                    // 必須チェック
                    if (!value) {
                      return Promise.reject(
                        new Error('発注単位' + MSG_ERROR['MSG_INPUT']),
                      );
                    }
                    // 数値チェック
                    if (!isNumeric(value)) {
                      return Promise.reject(
                        new Error('発注単位' + MSG_ERROR['MSG_NUMERIC']),
                      );
                    }
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              className="input-number"
              ref={el => (refForm.current.hachutani = el)}
              disabled={disable.inputZaikoMaxOrderPoint || disableInput}
              maxLength={6}
              style={{ width: 120 }}
              onBlur={event => {
                form.setFieldsValue({
                  hachutani: ToASCII(
                    event.target.value.trim().replaceAll(',', ''),
                    false,
                  ),
                });
              }}
              onKeyUp={event =>
                (event.key === 'Enter' || event.key === 'Tab') &&
                event.currentTarget.select()
              }
              onKeyDown={focusNextEle}
            />
          </Form.Item>
          <Form.Item
            className={disableInput ? 'disable-input' : ''}
            label="２１．最低注文単位(全角10桁)"
            name="tninm"
            rules={[
              () => ({
                validator(_: any, value: string) {
                  if (!disableInput) {
                    // 必須チェック
                    if (!value) {
                      return Promise.reject(
                        new Error('最低注文単位' + MSG_ERROR['MSG_INPUT']),
                      );
                    }
                    // 全角チェック
                    if (!IsZenkaku(value)) {
                      return Promise.reject(
                        new Error('最低注文単位' + MSG_ERROR['MSG_HANKAKU']),
                      );
                    }
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              ref={el => (refForm.current.tninm = el)}
              disabled={disableInput}
              maxLength={10}
              style={{ width: 200 }}
              onBlur={event => {
                form.setFieldsValue({
                  tninm: Hankaku2Zenkaku(event.target.value.trimEnd()),
                });
              }}
              onKeyUp={event =>
                (event.key === 'Enter' || event.key === 'Tab') &&
                event.currentTarget.select()
              }
              onKeyDown={focusNextEle}
            />
          </Form.Item>
          <Form.Item
            className={disableInput ? 'disable-input' : ''}
            label="２２．注文受付許可"
            style={{ alignItems: 'baseline' }}
          >
            <Space style={{ marginBottom: 8 }}>
              <Button
                disabled={disableInput}
                onClick={() => {
                  form.setFieldsValue({
                    kigyoTypes: dataKigyoType?.data?.map(
                      (kigyoType: iKigyoType) => kigyoType.kigyotypeId,
                    ),
                  });
                }}
                onKeyDown={e => {
                  e.key === 'Enter' && (e.target as any).click();
                }}
              >
                全チェック
              </Button>
              <Button
                disabled={disableInput}
                onClick={() => {
                  form.setFieldsValue({
                    kigyoTypes: [],
                  });
                }}
                onKeyDown={e => {
                  e.key === 'Enter' && (e.target as any).click();
                }}
              >
                全チェック解除
              </Button>
            </Space>
            {/* <Form.Item
              className={disableInput ? 'disable-input' : ''}
              name="checkAll"
              valuePropName={'checked'}
            >
              <Checkbox
                disabled={disableInput}
                onChange={e => {
                  if (e.target.checked) {
                    form.setFieldsValue({
                      kigyoGroups: dataKigyoGroup?.data?.map(
                        (kigyoType: iKigyoGroup) => kigyoType.kigyogroupId,
                      ),
                    });
                  } else {
                    form.setFieldsValue({
                      kigyoGroups: [],
                    });
                  }
                }}
              >
                全て選択
              </Checkbox>
            </Form.Item> */}
            {/* <Form.Item
              className={disableInput ? 'disable-input' : ''}
              name="kigyoGroups"
            >
              <Checkbox.Group style={{ width: '100%' }}>
                <Row gutter={[0, 2]} style={{ flexWrap: 'wrap' }}>
                  {kigyoTypeOptions.map((kigyo: any) => {
                    return (
                      <Col
                        key={kigyo.kigyogroupId}
                        style={{
                          flex: '0 0 33%',
                        }}
                      >
                        <Checkbox
                          value={kigyo.kigyogroupId}
                          disabled={disableInput}
                          key={kigyo.kigyogroupId}
                          onKeyDown={focusNextEle}
                        >
                          {kigyo.kigyogroupNm}
                        </Checkbox>
                      </Col>
                    );
                  })}
                </Row>
              </Checkbox.Group>
            </Form.Item> */}
            <Form.Item name="kigyoTypes" noStyle>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row gutter={[0, 2]} style={{ flexWrap: 'wrap' }}>
                  {Object.keys(kigyoTypeOptions).map(key => {
                    return (
                      <Col
                        key={key}
                        flex={1}
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        {kigyoTypeOptions[key].map((kigyo: iKigyoType) => {
                          if (kigyo.kigyotypeId === '21')
                            return (
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}
                                key={kigyo.kigyotypeId}
                              >
                                <Checkbox
                                  style={{ margin: 0, marginBottom: 4 }}
                                  value={kigyo.kigyotypeId}
                                  disabled={disableInput}
                                >
                                  {kigyo.kigyotypeNm}
                                </Checkbox>
                                <Checkbox
                                  style={{
                                    margin: 0,
                                    marginBottom: 4,
                                    visibility: 'hidden',
                                  }}
                                  key={kigyo.kigyotypeId + '1'}
                                >
                                  {kigyo.kigyotypeNm}
                                </Checkbox>
                                <Checkbox
                                  style={{
                                    margin: 0,
                                    marginBottom: 4,
                                    visibility: 'hidden',
                                  }}
                                  key={kigyo.kigyotypeId + '2'}
                                >
                                  {kigyo.kigyotypeNm}
                                </Checkbox>
                              </div>
                            );
                          else
                            return (
                              <Checkbox
                                style={{ margin: 0, marginBottom: 4 }}
                                value={kigyo.kigyotypeId}
                                disabled={disableInput}
                                key={kigyo.kigyotypeId}
                              >
                                {kigyo.kigyotypeNm}
                              </Checkbox>
                            );
                        })}
                      </Col>
                    );
                  })}
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Form.Item>
          <Form.Item
            className={disableInput ? 'disable-input' : ''}
            label="２３．異常発注警告数(半角数字6桁)"
            name="ordermax"
            rules={[
              () => ({
                validator(_: any, value: string) {
                  if (!disableInput) {
                    // 必須チェック
                    if (!value) {
                      return Promise.reject(
                        new Error('異常発注警告数' + MSG_ERROR['MSG_INPUT']),
                      );
                    }
                    // 数値チェック
                    if (!isNumeric(value)) {
                      return Promise.reject(
                        new Error('異常発注警告数' + MSG_ERROR['MSG_NUMERIC']),
                      );
                    }
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              className="input-number"
              ref={el => (refForm.current.ordermax = el)}
              disabled={disableInput}
              maxLength={6}
              style={{ width: 120 }}
              onBlur={event => {
                form.setFieldsValue({
                  ordermax: ToASCII(
                    event.target.value.trim().replaceAll(',', ''),
                    false,
                  ),
                });
              }}
              onKeyUp={event =>
                (event.key === 'Enter' || event.key === 'Tab') &&
                event.currentTarget.select()
              }
              onKeyDown={focusNextEle}
            />
          </Form.Item>
          <Form.Item
            className={disableInput ? 'disable-input' : ''}
            label="２４．仕様ファイル"
            name="siyo"
            valuePropName="checked"
          >
            <Checkbox
              ref={el => (refForm.current.siyo = el)}
              disabled={disableInput}
              onKeyDown={focusNextEle}
            >
              商品仕様ファイルあり
            </Checkbox>
          </Form.Item>
          <Form.Item
            className={disableInput ? 'disable-input' : ''}
            label="２５．HAT商品コード(半角24桁)"
          >
            <Space>
              <Form.Item
                noStyle
                name="hatcd24"
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_: any, value: string) {
                      if (!(disable.inputHanCD || disableInput)) {
                        let siirecd = getFieldValue('siirecd');
                        // 仕入先がHATの場合、必須
                        if (
                          (siirecd === HAT_SIIRESAKICD ||
                            siirecd === AKAGI_SIIRESAKICD) &&
                          !value
                        ) {
                          return Promise.reject(
                            new Error('HAT商品コード' + MSG_ERROR['MSG_INPUT']),
                          );
                        }
                        // 半角チェック
                        if (!IsHankaku(value)) {
                          return Promise.reject(
                            new Error(
                              'HAT商品コード' + MSG_ERROR['MSG_ZENKAKU'],
                            ),
                          );
                        }
                        // 禁則文字チェック
                        if (checkForSpecialChar(value)) {
                          return Promise.reject(
                            new Error(
                              'HAT商品コード' + MSG_ERROR['MSG_INVALID_CHAR'],
                            ),
                          );
                        }
                        // // 仕入先がHATの場合、必須
                        // if (responseCheckHatCodeSyohinMainte?.data === false) {
                        //   return Promise.reject(
                        //     new Error(MSG_ERROR['MSG_HAT_SYOHIN_NOT_EXIST']),
                        //   );
                        // }
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input
                  ref={el => (refForm.current.hatcd24 = el)}
                  maxLength={24}
                  disabled={disable.inputHanCD || disableInput}
                  style={{ width: 400 }}
                  onBlur={event => {
                    let hatcd24 = ToASCII(event.target.value.trim(), true);
                    form.setFieldsValue({
                      hatcd24,
                    });
                  }}
                  onKeyUp={event =>
                    (event.key === 'Enter' || event.key === 'Tab') &&
                    event.currentTarget.select()
                  }
                  onFocus={event => {
                    previousHatCd24.current = event.target.value;
                  }}
                  onKeyDown={focusNextEle}
                />
              </Form.Item>
              <span>※仕入先がアカギの場合、必須</span>
            </Space>
          </Form.Item>
          <Form.Item
            className={disableInput ? 'disable-input' : ''}
            label="２６．キャプティ品名コード(半角6桁)"
            name="captyCode24"
            rules={[
              () => ({
                validator(_: any, value: string) {
                  if (!disableInput) {
                    // 仕入先がHAT以外の場合、必須
                    if (!value) {
                      return Promise.reject(
                        new Error(
                          'キャプティ品名コード' + MSG_ERROR['MSG_INPUT'],
                        ),
                      );
                    }
                    // 半角チェック
                    if (!IsHankaku(value)) {
                      return Promise.reject(
                        new Error(
                          'キャプティ品名コード' + MSG_ERROR['MSG_ZENKAKU'],
                        ),
                      );
                    }
                    // 禁則文字チェック
                    if (checkForSpecialChar(value)) {
                      return Promise.reject(
                        new Error(
                          'キャプティ品名コード' +
                            MSG_ERROR['MSG_INVALID_CHAR'],
                        ),
                      );
                    }
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              ref={el => (refForm.current.captyCode24 = el)}
              disabled={disableInput}
              maxLength={6}
              style={{ width: 120 }}
              onBlur={event => {
                form.setFieldsValue({
                  captyCode24: ToASCII(event.target.value.trim(), true),
                });
              }}
              onKeyUp={event =>
                (event.key === 'Enter' || event.key === 'Tab') &&
                event.currentTarget.select()
              }
              onKeyDown={focusNextEle}
            />
          </Form.Item>
          <Form.Item
            className={disableInput ? 'disable-input' : ''}
            label="２７．フラグ立て"
            name="st2"
            valuePropName="checked"
          >
            <Checkbox
              ref={el => (refForm.current.st2 = el)}
              disabled={disableInput}
              onKeyDown={focusNextEle}
            >
              フラグ立て
            </Checkbox>
          </Form.Item>
        </BodyForm>
      </FormWrapper>
      <FooterForm>
        <Space>
          {(mode !== MODE_CREATE && !isSuccessGetSyohinMainte) || (
            <Button
              form="syohin-mainte"
              ref={el => (refForm.current.btnSubmit = el)}
              disabled={
                loadingCreateSyohinMainte ||
                loadingUpdateSyohinMainte ||
                loadingDeleteSyohinMainte
              }
              type="primary"
              htmlType="submit"
              style={{ minWidth: 100 }}
            >
              確定
            </Button>
          )}
          <Button
            onClick={() => window.print()}
            type="default"
            style={{ minWidth: 100 }}
          >
            印刷
          </Button>
          <Button
            ref={el => (refForm.current.btnReset = el)}
            type="default"
            style={{ minWidth: 100 }}
            onClick={() => {
              if (valuesChange) {
                messagePopup({
                  type: 'warning',
                  content: '画面をクリアします。よろしいですか?',
                  onOk: () => {
                    resetForm();
                  },
                  onCancel: () => refForm?.current?.btnReset?.focus(),
                });
              } else resetForm();
            }}
          >
            画面クリア
          </Button>
          <Button
            ref={el => (refForm.current.btnClose = el)}
            type="default"
            style={{ minWidth: 100 }}
            onClick={() => {
              if (valuesChange) {
                messagePopup({
                  type: 'warning',
                  content: MSG_ERROR['MSG_VALUES_CHANGE'],
                  onOk: () => {
                    removeSiirem();
                    removeSyohinKbn();
                    navigate(PATH.TOP);
                    setValuesChange(false);
                  },
                  onCancel: () => refForm?.current?.btnClose?.focus(),
                });
              } else {
                removeSiirem();
                removeSyohinKbn();
                navigate(PATH.TOP);
              }
            }}
          >
            メニューへ戻る
          </Button>
        </Space>
      </FooterForm>
      <SyohinSearch
        isModalVisible={isVisibleSyohin}
        setIsModalVisible={setIsVisibleSyohin}
        setCd24={(cd24: any) => {
          if (cd24 === form.getFieldValue('cd24')) return;
          // previousCode24.current = cd24;
          form.setFieldsValue({
            cd24,
          });
          getInfoSyohin(cd24);
        }}
        focusAfterClose={() => {
          refForm?.current?.buttonSearch?.focus();
        }}
      />
    </WrapperMainte>
  );
};

export default SyohinMainte;
