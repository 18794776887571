import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    ::selection {
        color: #fff;
        background: #4081ec;
    }
    .ant-select {
      /* width: auto !important; */
    }
    .ant-select-dropdown {
      /* width: auto !important; */
    }
    .ant-picker-cell-disabled::before {
        background: rgba(0, 0, 0, 0.2);
    }
    .message-popup{
        min-width: 450px;
        max-width: 550px;
        width: max-content !important;
        top: 150px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        padding-bottom: 0;
        .ant-modal-confirm-body{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            & > .anticon{
                font-size: 40px;
                margin: 0;
                margin-bottom: 10px;
            }
            .ant-modal-confirm-title{
                font-size: 20px;
                font-weight: 600;
                margin-bottom: 5px;
            }
            .ant-modal-confirm-content{
                margin: 0 !important;
                font-size: 14px;
            }
        }
        .ant-modal-confirm-btns{
            float: unset;
            margin-top: 20px;
            width: 100%;
            /* display: flex; */
            justify-content: center;
            align-items: center;
            flex-direction: row-reverse;
            display: none;
            .ant-btn{
                min-width: 100px;
                font-weight: 500;
            }
            .ant-btn-primary{
                margin-right: 8px;
                margin-left: 0px;
            }
        }
    }
    .ant-btn {
        &:focus {
            box-shadow: 0 0 0 2px rgba(25, 40, 218, 0.5);
        }
    }
    .ant-checkbox-input:focus + .ant-checkbox-inner {
        box-shadow: 0 0 0 2px rgba(25, 40, 218, 0.2);
    }
    .manual-link {
        color: #FFF;
        line-height: normal;
        border: solid 1px transparent;
        transition: all 0.3s;
        &:focus {
            color: #FFF;
            border-radius: 5px;
            box-shadow: 0 0 0 2px rgba(255, 255, 255 ,0.7);
        }
        &:hover{
            color: #FFF;
            opacity: 0.7;
        }
    }
    .btn-logout{
        &:focus {
            border-radius: 5px;
            box-shadow: 0 0 0 2px rgba(255, 255, 255 ,0.7);
        }
    }
    .readonly-column {
        background-color: #e0e0e0 !important;
        color: rgba(55, 55, 55, 1) !important;
    }
    .handsontable td.htInvalid {
        background-color: unset !important;
    }
    .row-dragging {
        display: flex;
        align-items: center;
        border: 1px solid #ccc;
        overflow: hidden;
        &.orirase-future,
        &.orirase-future:hover {
          background-color: #fff !important;
        }
        &.orirase-present,
        &.orirase-present:hover {
          background-color: #d0daff !important;
        }
        &.orirase-past,
        &.orirase-past:hover {
          background-color: #cccccc !important;
        }
    }

    .row-dragging td {
        padding: 2px;
    }

    .row-dragging .drag-visible {
        visibility: visible;
    }

    .ckeditor-wrapper {
        width: 210mm;
      .document-editor {
        border: 1px solid var(--ck-color-base-border);
        border-radius: var(--ck-border-radius);

        /* Set vertical boundaries for the document editor. */
        max-height: 297mm;

        /* This element is a flex container for easier rendering. */
        display: flex;
        flex-flow: column nowrap;
      }

      .document-editor__toolbar {
        /* Make sure the toolbar container is always above the editable. */
        z-index: 1;

        /* Create the illusion of the toolbar floating over the editable. */
        box-shadow: 0 0 5px hsla(0, 0%, 0%, 0.2);

        /* Use the CKEditor CSS variables to keep the UI consistent. */
        border-bottom: 1px solid var(--ck-color-toolbar-border);
      }

      /* Adjust the look of the toolbar inside the container. */
      .document-editor__toolbar .ck-toolbar {
        border: 0;
        border-radius: 0;
      }

      .document-editor__editable-container {
        padding: calc(2 * var(--ck-spacing-large));
        background: var(--ck-color-base-foreground);

        /* Make it possible to scroll the "page" of the edited content. */
        /* overflow-y: scroll; */
      }

      .document-editor__editable-container .ck-editor__editable {
        /* Set the dimensions of the "page". */
        width: 28cm;
        min-height: 22cm;

        /* Keep the "page" off the boundaries of the container. */
        padding: 0.5cm 1cm;

        border: 1px hsl(0, 0%, 82.7%) solid;
        border-radius: var(--ck-border-radius);
        background: white;

        /* The "page" should cast a slight shadow (3D illusion). */
        box-shadow: 0 0 5px hsla(0, 0%, 0%, 0.1);

        /* Center the "page". */
        margin: 0 auto;
      }

      .document-editor .ck-content,
      .document-editor .ck-heading-dropdown .ck-list .ck-button__label {
        font: 16px/1.6 'Helvetica Neue', Helvetica, Arial, sans-serif;
      }

      /* Adjust the headings dropdown to host some larger heading styles. */
      .document-editor .ck-heading-dropdown .ck-list .ck-button__label {
        line-height: calc(
          1.7 * var(--ck-line-height-base) * var(--ck-font-size-base)
        );
        min-width: 6em;
      }

      /* Scale down all heading previews because they are way too big to be presented in the UI.
  Preserve the relative scale, though. */
      .document-editor
        .ck-heading-dropdown
        .ck-list
        .ck-button:not(.ck-heading_paragraph)
        .ck-button__label {
        transform: scale(0.8);
        transform-origin: left;
      }

      /* Set the styles for "Heading 1". */
      .document-editor .ck-content h2,
      .document-editor
        .ck-heading-dropdown
        .ck-heading_heading1
        .ck-button__label {
        font-size: 2.18em;
        font-weight: normal;
      }

      .document-editor .ck-content h2 {
        line-height: 1.37em;
        padding-top: 0.342em;
        margin-bottom: 0.142em;
      }

      /* Set the styles for "Heading 2". */
      .document-editor .ck-content h3,
      .document-editor
        .ck-heading-dropdown
        .ck-heading_heading2
        .ck-button__label {
        font-size: 1.75em;
        font-weight: normal;
        /* color: hsl(203, 100%, 50%); */
      }

      .document-editor
        .ck-heading-dropdown
        .ck-heading_heading2.ck-on
        .ck-button__label {
        color: var(--ck-color-list-button-on-text);
      }

      /* Set the styles for "Heading 2". */
      .document-editor .ck-content h3 {
        line-height: 1.86em;
        padding-top: 0.171em;
        margin-bottom: 0.357em;
      }

      /* Set the styles for "Heading 3". */
      .document-editor .ck-content h4,
      .document-editor
        .ck-heading-dropdown
        .ck-heading_heading3
        .ck-button__label {
        font-size: 1.31em;
        font-weight: bold;
      }

      .document-editor .ck-content h4 {
        line-height: 1.24em;
        padding-top: 0.286em;
        margin-bottom: 0.952em;
      }

      /* Set the styles for "Paragraph". */
      .document-editor .ck-content p {
        font-size: 1em;
        line-height: 1.63em;
        padding-top: 0.5em;
        margin-bottom: 1.13em;
      }

      .document-editor .ck-content blockquote {
        font-family: Georgia, serif;
        margin-left: calc(2 * var(--ck-spacing-large));
        margin-right: calc(2 * var(--ck-spacing-large));
      }
    }
`;
