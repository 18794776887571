import { API_URL } from 'constants/api';
import { useQuery } from 'react-query';
import AxiosPost from 'utils/axios/axiosPost';

export function useGetSiirem() {
  const getSiirem = async () => {
    return await AxiosPost(API_URL.GET_SIIREM);
  };

  const response = useQuery(['siirem'], getSiirem, {
    enabled: false,
    retry: 1,
  });

  return response;
}
