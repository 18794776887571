import { Input } from 'antd';
import { ToASCII } from 'constants/common';
import { MSG_ERROR } from 'constants/text';
import { useEffect, useState } from 'react';
import messagePopup from 'utils/message/mesage';

interface Props {
  setLstMeisai?: any;
  dfValue?: any;
  lstMeisai?: any;
  record?: any;
  setValuesChange?: any;
  inputRef?: any;
  formRef?: any;
}

const InputRefill = ({
  lstMeisai,
  setLstMeisai,
  dfValue,
  record,
  setValuesChange,
  inputRef,
  formRef,
}: Props) => {
  const [valueInput, setValueInput] = useState<string | null>(null);
  const [previousValue, setPreviousValue] = useState<string | null>(null);

  function focusInput() {
    inputRef?.current[record.index].focus();
    inputRef?.current[record.index].select();
  }

  const handleValue = (value: any) => {
    let updateArray: any;
    let suryo = value?.toString().replaceAll(',', '').trim();
    setValueInput(suryo);

    if (suryo !== previousValue) {
      setValuesChange(true);
      if (suryo) {
        if (!/^[0-9]+$/.test(suryo)) {
          let updateSyohin = {
            ...record,
            suryo: suryo,
            previousSuryo: suryo,
            errorBara: true,
            msgErrorBara: '入荷数' + MSG_ERROR['MSG_010'],
            focusBara: focusInput,
          };
          updateArray = lstMeisai?.map((u: any) =>
            u?.index !== updateSyohin.index ? u : updateSyohin,
          );
          messagePopup({
            type: 'info',
            content: '入荷数' + MSG_ERROR['MSG_010'],
            onOk: () => focusInput(),
          });
        } else {
          let valueSuryo = Number(suryo);
          if (valueSuryo === 0) {
            let updateSyohin = {
              ...record,
              suryo: valueSuryo,
              previousSuryo: valueSuryo,
              errorBara: true,
              msgErrorBara: '入荷数に0は入力できません',
              focusBara: focusInput,
            };
            updateArray = lstMeisai?.map((u: any) =>
              u?.index !== updateSyohin.index ? u : updateSyohin,
            );
            messagePopup({
              type: 'info',
              content: '入荷数に0は入力できません',
              onOk: () => focusInput(),
            });
          } else if (valueSuryo > record.hachuzan) {
            let updateSyohin = {
              ...record,
              suryo: valueSuryo,
              previousSuryo: valueSuryo,
              errorBara: true,
              msgErrorBara: MSG_ERROR['MSG_OVER_HACHUZAN'],
              focusBara: () => focusInput(),
            };
            updateArray = lstMeisai?.map((u: any) =>
              u?.index !== updateSyohin.index ? u : updateSyohin,
            );
            messagePopup({
              type: 'info',
              content: MSG_ERROR['MSG_OVER_HACHUZAN'],
              onOk: () => focusInput(),
            });
          } else {
            let updateSyohin = {
              ...record,
              suryo: valueSuryo,
              previousSuryo: valueSuryo,
              errorBara: false,
              msgErrorBara: '',
              focusBara: () => focusInput(),
            };
            updateArray = lstMeisai?.map((u: any) =>
              u?.index !== updateSyohin.index ? u : updateSyohin,
            );
          }
        }
      } else {
        let updateSyohin = {
          ...record,
          previousSuryo: suryo,
          suryo: suryo,
          errorBara: true,
          msgErrorBara: '入荷数' + MSG_ERROR['MSG_INPUT'],
          focusBara: () => focusInput(),
        };
        updateArray = lstMeisai?.map((u: any) =>
          u?.index !== updateSyohin.index ? u : updateSyohin,
        );
        messagePopup({
          type: 'info',
          content: '入荷数' + MSG_ERROR['MSG_INPUT'],
          onOk: () => focusInput(),
        });
      }
      setLstMeisai(updateArray);
    }
  };

  useEffect(() => {
    setValueInput(dfValue?.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dfValue]);

  return (
    <Input
      ref={el => (inputRef.current[record.index] = el)}
      style={{ textAlign: 'right', margin: '2px 0', width: 60 }}
      maxLength={6}
      size="small"
      value={valueInput ?? ''}
      disabled={record?.del}
      onChange={e => {
        setValueInput(e.target.value);
      }}
      onBlur={(event: any) => {
        event.preventDefault();
        let value = ToASCII(event.target.value);
        handleValue(value);
      }}
      onKeyUp={event =>
        (event.key === 'Enter' || event.key === 'Tab') &&
        event.currentTarget.select()
      }
      onFocus={event => {
        setPreviousValue(event.target.value);
      }}
      onPressEnter={event => {
        event.preventDefault();
        if (inputRef.current[record.index + 1]) {
          inputRef.current[record.index + 1].focus();
        } else formRef.current.btnSubmit.focus();
      }}
    />
  );
};

export default InputRefill;
