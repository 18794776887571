import { Button, Layout, Space } from 'antd';
import { PATH } from 'configs/routes';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { logout } from 'slice/app';
import messagePopup from 'utils/message/mesage';
import { Container, Div, Flex, LogoText, LogoTitle } from './layout.style';
import Timer from './Timer';
const { Header, Content } = Layout;

interface Props {
  children: any;
}

function getPropsWithPathName(path: string) {
  let subTitle = '';
  let hash = '';

  switch (path) {
    case PATH.LOGIN:
      subTitle = '';
      break;
    case PATH.TOP:
      subTitle = 'YHK器材メニュー';
      break;
    case PATH.CALENDAR_MAINTE:
      subTitle = 'カレンダーメンテ';
      hash = '#CalendarMainte';
      break;
    case PATH.TORIHIKISAKI_MAINTE:
      subTitle = '取引先メンテ';
      hash = '#TorihikisakiMainte';
      break;
    case PATH.SYOHIN_MAINTE:
      subTitle = '商品メンテ';
      hash = '#SyohinMainte';
      break;
    case PATH.TANTOU_MAINTE:
      subTitle = 'ご注文担当メンテ';
      hash = '#TantouMainte';
      break;
    case PATH.MASTER_CHECK:
      subTitle = 'マスタチェック';
      hash = '#MasterCheck';
      break;
    case PATH.HANOTODOKESAKI_MAINTE:
      subTitle = '班・お届け先（便）メンテ';
      hash = '#HanOtodokesakiMainte';
      break;
    case PATH.OSIRASE_MAINTE:
      subTitle = 'お知らせメンテ';
      hash = '#OsiraseMainte';
      break;
    case PATH.YHK_MANUAL:
      subTitle = '操作マニュアル';
      break;
    case PATH.WEBSEARCH_MENU:
      subTitle = '情報検索';
      break;
    case PATH.HATPRODUCTDELIVERY:
      subTitle = 'HAT商品の納入日調査';
      break;
    case PATH.MONITORDELIVERY:
      subTitle = '便別配送モニター';
      break;
    case PATH.STOCKDETAILS:
      subTitle = '入荷明細情報 在庫入荷のみ';
      break;
    case PATH.VEHICLEALLOCATIONLIST:
      subTitle = '便別配車リスト';
      break;
    case PATH.COLLECTINGGOODSBYPRODUCT:
      subTitle = '商品別入荷集計';
      break;
    case PATH.COOPERATINGSTOREINFORMATION:
      subTitle = '協力店情報';
      break;
    case PATH.MONITORDELIVERYBYFLIGHT:
      subTitle = '便別配送モニター≪印刷用≫';
      break;
    case PATH.SHIPMENTTOTALBYPRODUCT:
      subTitle = '商品別出荷集計';
      break;
    case PATH.SHIPPEDLINES:
      subTitle = '出荷行数集計';
      break;
    case PATH.SHIPPINGDETAILSAGGREGATE:
      subTitle = '出荷明細商品別集計情報';
      break;
    case PATH.SHIPPINGINFORMATION:
      subTitle = '出荷明細情報';
      break;
    case PATH.STOCKINFORMATIONPRODUCTS:
      subTitle = '在庫情報 ○○商品用';
      break;
    case PATH.INVENTORYINFORMATION:
      subTitle = '在庫情報';
      break;
    case PATH.SHIPPINGDETAILSINFORMATION:
      subTitle = '出荷明細情報 ★入力日時表示版';
      break;
    case PATH.PARTNERSHIPSTORE:
      subTitle = '協力店情報（班・便なし）';
      break;
    case PATH.CONFIRMLISTVOUCHER:
      subTitle = '発行伝票枚数 確認リスト≪印刷用≫';
      break;
    case PATH.LISTREVOKEDVOUCHERS:
      subTitle = '伝票回収チェックリスト≪印刷用≫';
      break;
    case PATH.SHIPPINGINFORMATIONFORREPORT:
      subTitle = '出荷明細情報（報告用）';
      break;
    case PATH.PREDELIVERY:
      subTitle = '先納期 大口リスト';
      break;
    case PATH.FLIGHTDISPATCHLIST:
      subTitle = '便配車リスト≪印刷用≫';
      break;
    case PATH.VOUCHERCOLLECTIONCHECKLIST:
      subTitle = '伝票回収チェックリスト';
      break;
    case PATH.ORDERPROCESSINGCHECK:
      subTitle = '発注処理チェック';
      break;
    case PATH.CHECKORDERBACKLOG:
      subTitle = '注残チェック';
      break;
    case PATH.SLIPCOLLECTIONCHECKLIST:
      subTitle = '伝票回収チェックリスト（伝票調査用）';
      break;
    case PATH.SHIPPING_CORRECTION:
      subTitle = '出荷訂正';
      break;
    case PATH.RETURN_INPUT:
      subTitle = '返品入力';
      break;
    case PATH.INPUT_STOCKREFILLORDER:
      subTitle = '発注入力';
      break;
    case PATH.CREATE_STOCKREFILLRECEIVE:
      subTitle = '入荷入力';
      break;
    case PATH.BACKLOG_DELETION:
      subTitle = '注残削除処理';
      break;
    case PATH.STOCK_INQUIRY:
      subTitle = '在庫問合せ';
      break;
    case PATH.INVENTORYLIST:
      subTitle = '棚卸用一覧リスト';
      break;
    default:
      subTitle = '';
      hash = '';
      break;
  }
  return { subTitle, hash };
}

const LayoutAdmin = (props: Props) => {
  const { pathname } = useLocation();
  const { children } = props;
  const dispatch = useDispatch();
  const { subTitle, hash } = getPropsWithPathName(pathname);
  const pathnameHideManual = [PATH.LOGIN, PATH.TOP, PATH.ORDER_MANUAL];

  const handleClickLogout = () => {
    messagePopup({
      type: 'question',
      content: 'ログアウトします。よろしいですか？',
      onOk: () => {
        dispatch(logout());
      },
    });
  };

  return (
    <Div>
      <Layout>
        <Header style={{ width: '100%', height: 70 }}>
          <Container
            style={{ minWidth: '100vw', height: '100%', alignItems: 'center' }}
          >
            <Timer pathname={pathname} />
            <Flex className="logo">
              <LogoText>ＹＨＫ業務メニュー</LogoText>
              <LogoTitle>{subTitle}</LogoTitle>
            </Flex>
            <Flex className="manual">
              <Space size={20}>
                {pathnameHideManual.includes(pathname) || (
                  <Button
                    type="text"
                    className="manual-link"
                    onClick={() => {
                      let widthScreen = window.screen.width;
                      let heightScreen = window.screen.height;
                      window.open(
                        process.env.PUBLIC_URL + PATH.YHK_MANUAL + hash,
                        '_blank',
                        `resizable=yes, width=${widthScreen}, height=${heightScreen}, top=${heightScreen}, left=${widthScreen}`,
                      );
                    }}
                  >
                    操作マニュアル
                  </Button>
                )}
                {pathname === PATH.TOP && (
                  <div
                    style={{
                      display: 'flex',
                    }}
                  >
                    <Button type="primary" danger onClick={handleClickLogout}>
                      {'ログアウト'}
                    </Button>
                  </div>
                )}
              </Space>
            </Flex>
          </Container>
        </Header>
        <Content className="site-layout">
          <Container>
            <div
              className="site-layout-background"
              style={{ padding: '8px 0' }}
            >
              {children}
            </div>
          </Container>
        </Content>
      </Layout>
    </Div>
  );
};
export default LayoutAdmin;
