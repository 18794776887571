import styled from 'styled-components';

export const Flex = styled.div`
  flex: 1;
  max-width: 300px;
  height: 60px;
  &.logo {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 390px;
    gap: 4px;
  }
  &.manual {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    font-weight: 500;
    color: #fff;
  }
  &.time {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    font-weight: 500;
    color: #fff;
  }
`;

export const Container = styled.div`
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  justify-content: center;
`;

export const Div = styled.div`
  .ant-layout {
    width: fit-content;
    min-width: 1130px;
    /* min-width: 1550px; */
    margin: 0 auto;
    background: url('bg/bg-test.png');
    background-repeat: repeat;
    @media (min-width: 1180px) {
      height: 100vh;
    }
    .ant-layout-header {
      padding: 0px;
      background: url('bg/bg-header.svg');
    }
  }
  #components-layout-demo-fixed .logo {
    float: left;
    width: 120px;
    height: 31px;
    margin: 16px 24px 16px 0;
    background: #fff;
  }
  .site-layout {
    padding: 8px 0px !important;
    @media (min-width: 1180px) {
      overflow: overlay;
    }
    .site-layout-background {
      background: transparent !important;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
`;

export const LogoText = styled.span`
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  line-height: 22px;
`;
export const LogoTitle = styled.span`
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
`;
