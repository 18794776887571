import { Button, Checkbox, Col, Form, Input, Row, Select, Space } from 'antd';
import {
  useGetShippingDetailsAggregation,
  useGetShippingDetailsAggregationExcel,
} from 'api/shippingDetailsAggregate';
import RangeDatePickerCustom from 'components/RangePickerCustom';
import { PATH } from 'configs/routes';
import {
  checkForSpecialChar,
  getKeyboardFocusableElements,
  Hankaku2Zenkaku,
  hasKanji,
  IsHankaku,
  IsHankakuEisu,
  isNumeric,
  onlyLettersAndNumbers,
  ToASCII,
} from 'constants/common';
import { MSG_ERROR, TEXT_ALL } from 'constants/text';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectUserInfo } from 'slice/app';
import messagePopup from 'utils/message/mesage';
import { FormWrapper } from 'views/login/FormLogin/formLogin.style';
import { ControlFilter, MenuWrapper, Wrapper } from '../webSearchMenu.style';
import ModalResultShippingDetailsAggregation from './modalResult';
import { iKigyoGroup, useGetKigyoGroup } from 'api/customer';
import LoadingCallAPI from 'components/LoadingCallAPI/LoadingCallAPI';

//Format Date
const dateFormat = 'YYMMDD';

const { Option } = Select;

const ShippingDetailsAggregate = () => {
  const userInfo = useSelector(selectUserInfo);
  const labelTokuisaki =
    (userInfo.kaisya ?? '') + (userInfo.tensyo ?? '') + TEXT_ALL.SAMA;
  const [form] = Form.useForm();
  const refForm = useRef<any>({});
  const refPreviousValue = useRef<any>({
    tokuiCd: '',
    hanCd: '',
    binCd: '',
    code24: '',
    orderNo: '',
    denNo: '',
  });
  const navigate = useNavigate();
  const [dataSource, setDataSource] = useState<any>({
    count: 0,
    listData: [],
  });
  const [visibleModalResult, setVisibleModalResult] = useState<any>(false);
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);
  const [kigyoTypeOptions, setKigyoTypeOptions] = useState<any>([]);
  const { mutate, isLoading, reset } = useGetShippingDetailsAggregation();
  const {
    mutate: excelMutation,
    isLoading: isLoadingExcel,
    reset: resetExcel,
  } = useGetShippingDetailsAggregationExcel();
  const dataDenKbn = [
    { value: '11', label: '在庫' },
    { value: '15', label: '取継' },
    { value: '21', label: '直送' },
    { value: '32', label: '返品、在庫処分' },
  ];
  const {
    mutate: getKigyoGroup,
    data: dataKigyoGroups,
    isLoading: loadingKigyoGroup,
  } = useGetKigyoGroup();

  const keyboardFocusElement: any = useRef([]);
  const focusNextEle = (e: any) => {
    const index = keyboardFocusElement.current.findIndex(
      (element: any) => element === e.target,
    );
    if (index !== -1) {
      if (e.key === 'Enter' || (e.key === 'Tab' && !e.shiftKey)) {
        e.preventDefault();
        keyboardFocusElement.current[index + 1]
          ? keyboardFocusElement.current[index + 1]?.focus()
          : keyboardFocusElement.current[0]?.focus();
      } else if (e.shiftKey && e.key === 'Tab') {
        e.preventDefault();
        keyboardFocusElement.current[index - 1]
          ? keyboardFocusElement.current[index - 1]?.focus()
          : keyboardFocusElement.current[
              keyboardFocusElement.current.length
            ]?.focus();
      }
    }
  };

  useEffect(() => {
    keyboardFocusElement.current = getKeyboardFocusableElements();
  });

  // Func reset form and clear cache data
  function resetPage() {
    form.resetFields();
    reset();
    resetExcel();
    setDataSource({
      count: 0,
      listData: [],
    });
  }

  // In first render
  useEffect(() => {
    resetPage();
    getKigyoGroup(
      {},
      {
        onSuccess({ data }, variables, context) {
          setKigyoTypeOptions(data);
          let formValues = {
            ...form.getFieldsValue(),
            kigyoGroups: data?.map(
              (kigyoType: iKigyoGroup) => kigyoType.kigyogroupId,
            ),
          };
          form.setFieldsValue(formValues);
        },
        onError(error, variables, context) {
          messagePopup({
            type: 'info',
            content:
              MSG_ERROR[(error as any)?.response?.data?.message] ||
              (error as any)?.response?.data?.message,
          });
        },
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // onSubmit to get response list  hat product delivery
  const onFinish = async (record: any) => {
    let params = {
      fromDt: moment(record.fromDate).format(dateFormat),
      toDt: moment(record.toDate).format(dateFormat),
      tokuiCd: record.tokuiCd,
      sendTo1: record.sendTo1,
      hanCd: record.hanCd,
      denKbn: record.denKbn,
      orderNo: record.orderNo,
      denNo: record.denNo,
      binCd: record.binCd,
      code24: record.code24,
      nnm: record.nnm,
      kigyoGroups: record.kigyoGroups,
    };
    mutate(params as any, {
      onSuccess(data, variables, context) {
        const { data: dataList } = data;
        if (dataList?.count > 0) {
          setDataSource({
            count: dataList?.count,
            listData: dataList?.data?.map((order: any, index: number) => ({
              id: index + 1,
              ...order,
            })),
          });
          setVisibleModalResult(true);
        } else {
          messagePopup({
            type: 'error',
            content: MSG_ERROR['MSG_NO_DATA'],
            onOk: () => {
              refForm?.current?.tokuiCd?.focus();
            },
          });
        }
      },
      onError(error, variables, context) {
        messagePopup({
          type: 'error',
          content:
            MSG_ERROR[(error as any)?.response?.data?.message] ||
            (error as any)?.response?.data?.message,
          onOk: () => {
            refForm?.current?.tokuiCd?.focus();
          },
        });
      },
    });
    // keyboardFocusElement = getKeyboardFocusableElements();
  };

  // Validate form input
  const onFinishFailed = ({ values, errorFields }: any) => {
    messagePopup({
      type: 'info',
      content: errorFields[0]?.errors[0],
      onOk: () => {
        refForm?.current?.[errorFields[0]?.name[0]]?.focus();
        !!refForm?.current?.[errorFields[0]?.name[0]]?.select?.toString() &&
          refForm?.current?.[errorFields[0]?.name[0]]?.select();
      },
    });
  };

  //Handle Excel
  const handleExcel = () => {
    const {
      fromDate,
      toDate,
      tokuiCd,
      sendTo1,
      hanCd,
      denKbn,
      orderNo,
      denNo,
      binCd,
      code24,
      nnm,
      kigyoGroups,
    } = form.getFieldsValue();
    const params = {
      fromDt: moment(fromDate).format('YYMMDD'),
      toDt: moment(toDate).format('YYMMDD'),
      tokuiCd: tokuiCd,
      sendTo1: sendTo1,
      hanCd: hanCd,
      denKbn: denKbn,
      orderNo: orderNo,
      denNo: denNo,
      binCd: binCd,
      code24: code24,
      nnm: nnm,
      kigyoGroups,
    };

    form
      .validateFields()
      .then(() =>
        excelMutation(params as any, {
          onSuccess(data, variables, context) {
            let fileName = data.headers['content-disposition'] || 'errorName';
            const file = new Blob([data?.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            const fileURL = URL.createObjectURL(file);
            const link = document.createElement('a');
            link.href = fileURL;
            link.download = decodeURIComponent(
              fileName?.split('filename*=')[1].split(';')[0],
            ).substring(7);
            link.click();
          },
          onError: async (error, variables, context) => {
            const errorMsg = JSON.parse(
              await error.response.data.text(),
            )?.message;
            messagePopup({
              type: 'error',
              content: MSG_ERROR[errorMsg] || errorMsg,
              onOk: () => {
                refForm?.current?.tokuiCd?.focus();
              },
            });
          },
        }),
      )
      .catch(({ errorFields }) => {
        messagePopup({
          type: 'info',
          content: errorFields[0]?.errors[0],
          onOk: () => {
            refForm?.current?.[errorFields[0]?.name[0]]?.focus();
            !!refForm?.current?.[errorFields[0]?.name[0]]?.select?.toString() &&
              refForm?.current?.[errorFields[0]?.name[0]]?.select();
          },
        });
      });
  };

  return (
    <Wrapper>
      {loadingKigyoGroup && <LoadingCallAPI />}
      <MenuWrapper style={{ marginBottom: 8 }}>
        <span>{labelTokuisaki}</span>
      </MenuWrapper>

      <FormWrapper
        form={form}
        className="form-mainte"
        name="websearch-mainte"
        labelCol={{ flex: '180px' }}
        wrapperCol={{ flex: 1 }}
        requiredMark={false}
        labelAlign="left"
        colon={false}
        validateTrigger="onSubmit"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        scrollToFirstError
        initialValues={{
          fromDate: moment(),
          toDate: moment(),
          denKbn: null,
          tokuiCd: '',
          sendTo1: '',
          hanCd: '',
          orderNo: '',
          denNo: '',
          binCd: '',
          code24: '',
          nnm: '',
          kigyoGroups: dataKigyoGroups?.data?.map(
            (kigyoType: iKigyoGroup) => kigyoType.kigyogroupId,
          ),
        }}
        onKeyPress={(e: any) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
      >
        <Form.Item
          label={<label>協力店CD(前方一致)</label>}
          name="tokuiCd"
          rules={[
            () => ({
              validator(_: any, value: string) {
                if (value && (IsHankakuEisu(value) || hasKanji(value))) {
                  return Promise.reject(
                    new Error('協力店CD' + MSG_ERROR['MSG_HANKAKU_EISU']),
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input
            disabled={isLoading}
            autoFocus
            ref={el => (refForm.current.tokuiCd = el)}
            maxLength={6}
            style={{ width: 150 }}
            onKeyUp={event =>
              (event.key === 'Enter' || event.key === 'Tab') &&
              event.currentTarget.select()
            }
            onFocus={event => {
              refPreviousValue.current.tokuiCd = event.target.value;
            }}
            onBlur={event => {
              event.preventDefault();
              let value = ToASCII(event.target.value.trim());
              form.setFieldsValue({
                tokuiCd: value,
              });
              if (value !== refPreviousValue.current.tokuiCd) {
                form.validateFields(['tokuiCd']).catch(({ errorFields }) => {
                  messagePopup({
                    type: 'info',
                    content: errorFields[0]?.errors[0],
                    onOk: () => {
                      refForm?.current?.[errorFields[0]?.name[0]]?.focus();
                      refForm?.current?.[errorFields[0]?.name[0]]?.select();
                    },
                  });
                });
              }
            }}
            onKeyDown={focusNextEle}
          ></Input>
        </Form.Item>
        <Form.Item
          label={<label>{'企業グループ'}</label>}
          style={{ alignItems: 'baseline' }}
        >
          <Space style={{ marginBottom: 8 }}>
            <Button
              disabled={isLoading}
              onClick={() => {
                form.setFieldsValue({
                  kigyoGroups: kigyoTypeOptions?.map(
                    (kigyoType: any) => kigyoType.kigyogroupId,
                  ),
                });
              }}
              onKeyDown={e => {
                e.key === 'Enter' && (e.target as any).click();
              }}
            >
              全チェック
            </Button>
            <Button
              disabled={isLoading}
              onClick={() => {
                form.setFieldsValue({
                  kigyoGroups: [],
                });
              }}
              onKeyDown={e => {
                e.key === 'Enter' && (e.target as any).click();
              }}
            >
              全チェック解除
            </Button>
          </Space>
          <Form.Item name="kigyoGroups" noStyle>
            <Checkbox.Group style={{ width: '100%' }}>
              <Row gutter={[0, 2]} style={{ flexWrap: 'wrap' }}>
                {kigyoTypeOptions.map((kigyo: any) => {
                  return (
                    <Col
                      key={kigyo.kigyogroupId}
                      style={{
                        flex: '0 0 33%',
                      }}
                    >
                      <Checkbox
                        disabled={isLoading}
                        value={kigyo.kigyogroupId}
                        key={kigyo.kigyogroupId}
                        onKeyDown={focusNextEle}
                      >
                        {kigyo.kigyogroupNm}
                      </Checkbox>
                    </Col>
                  );
                })}
              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Form.Item>
        <Form.Item label={<label>会社名(全角)(中間一致)</label>} name="sendTo1">
          <Input
            disabled={isLoading}
            ref={el => (refForm.current.sendTo1 = el)}
            maxLength={20}
            style={{ width: 400 }}
            onKeyUp={event =>
              (event.key === 'Enter' || event.key === 'Tab') &&
              event.currentTarget.select()
            }
            onBlur={event => {
              event.preventDefault();
              form.setFieldsValue({
                sendTo1: Hankaku2Zenkaku(event.target.value.trimEnd()),
              });
            }}
            onKeyDown={focusNextEle}
          ></Input>
        </Form.Item>
        <Form.Item
          label={<label>班CD(前方一致)</label>}
          name="hanCd"
          rules={[
            () => ({
              validator(_: any, value: string) {
                if (value && !onlyLettersAndNumbers(value)) {
                  return Promise.reject(
                    new Error('班CD' + MSG_ERROR['MSG_NUMERICLETTER']),
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input
            disabled={isLoading}
            maxLength={3}
            ref={el => (refForm.current.hanCd = el)}
            style={{ width: 80 }}
            onKeyUp={event =>
              (event.key === 'Enter' || event.key === 'Tab') &&
              event.currentTarget.select()
            }
            onFocus={event => {
              refPreviousValue.current.hanCd = event.target.value;
            }}
            onBlur={event => {
              event.preventDefault();
              let value = ToASCII(event.target.value.trim());
              form.setFieldsValue({
                hanCd: value,
              });
              if (value !== refPreviousValue.current.hanCd) {
                form.validateFields(['hanCd']).catch(({ errorFields }) => {
                  messagePopup({
                    type: 'info',
                    content: errorFields[0]?.errors[0],
                    onOk: () => {
                      refForm?.current?.[errorFields[0]?.name[0]]?.focus();
                      refForm?.current?.[errorFields[0]?.name[0]]?.select();
                    },
                  });
                });
              }
            }}
            onKeyDown={focusNextEle}
          ></Input>
        </Form.Item>
        <Form.Item label={<label>{'納入日'}</label>}>
          <RangeDatePickerCustom
            title={'納入日'}
            autoFocus={false}
            nextFocus="denKbn"
            form={form}
            refForm={refForm}
            disabled={isLoading}
          />
        </Form.Item>
        <Form.Item label={<label>伝票区分</label>} name="denKbn">
          <Select
            open={openDropdown}
            onDropdownVisibleChange={open => setOpenDropdown(open)}
            onKeyDown={(event: any) => {
              if (event.key === 'Enter') {
                setOpenDropdown(false);
              } else if (event.key === ' ') {
                // event.preventDefault();
                setOpenDropdown(true);
              }
              focusNextEle(event);
            }}
            disabled={isLoading}
            allowClear
            placeholder={'選択してください'}
            ref={el => (refForm.current.denKbn = el)}
            style={{ width: 165 }}
            // showAction={['focus']}
            onSelect={(value: any) => {
              if (value) {
                setTimeout(() => {
                  refForm.current?.orderNo?.focus();
                }, 0);
              }
            }}
            getPopupContainer={(trigger: HTMLElement) =>
              trigger.parentNode as HTMLElement
            }
          >
            {dataDenKbn?.map((item: any) => {
              return (
                <Option value={item.value} key={item.value}>
                  {item.value}: {item.label}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          label={<label>受付番号(前方一致)</label>}
          name="orderNo"
          rules={[
            () => ({
              validator(_: any, value: string) {
                if (value && (IsHankakuEisu(value) || hasKanji(value))) {
                  return Promise.reject(
                    new Error('受付番号' + MSG_ERROR['MSG_HANKAKU_EISU']),
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input
            disabled={isLoading}
            ref={el => (refForm.current.orderNo = el)}
            maxLength={10}
            style={{ width: 400 }}
            onKeyUp={event =>
              (event.key === 'Enter' || event.key === 'Tab') &&
              event.currentTarget.select()
            }
            onFocus={event => {
              refPreviousValue.current.orderNo = event.target.value;
            }}
            onBlur={event => {
              event.preventDefault();
              let value = ToASCII(event.target.value.trim());
              form.setFieldsValue({
                orderNo: value,
              });
              if (value !== refPreviousValue.current.orderNo) {
                form.validateFields(['orderNo']).catch(({ errorFields }) => {
                  messagePopup({
                    type: 'info',
                    content: errorFields[0]?.errors[0],
                    onOk: () => {
                      refForm?.current?.[errorFields[0]?.name[0]]?.focus();
                      refForm?.current?.[errorFields[0]?.name[0]]?.select();
                    },
                  });
                });
              }
            }}
            onKeyDown={focusNextEle}
          ></Input>
        </Form.Item>
        <Form.Item
          label={<label>伝票番号(前方一致)</label>}
          name="denNo"
          rules={[
            () => ({
              validator(_: any, value: string) {
                if (value && (IsHankakuEisu(value) || hasKanji(value))) {
                  return Promise.reject(
                    new Error('伝票番号' + MSG_ERROR['MSG_HANKAKU_EISU']),
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input
            disabled={isLoading}
            ref={el => (refForm.current.denNo = el)}
            maxLength={6}
            style={{ width: 180 }}
            onKeyUp={event =>
              (event.key === 'Enter' || event.key === 'Tab') &&
              event.currentTarget.select()
            }
            onFocus={event => {
              refPreviousValue.current.denNo = event.target.value;
            }}
            onBlur={event => {
              event.preventDefault();
              let value = ToASCII(event.target.value.trim());
              form.setFieldsValue({
                denNo: value,
              });
              if (value !== refPreviousValue.current.denNo) {
                form.validateFields(['denNo']).catch(({ errorFields }) => {
                  messagePopup({
                    type: 'info',
                    content: errorFields[0]?.errors[0],
                    onOk: () => {
                      refForm?.current?.[errorFields[0]?.name[0]]?.focus();
                      refForm?.current?.[errorFields[0]?.name[0]]?.select();
                    },
                  });
                });
              }
            }}
            onKeyDown={focusNextEle}
          ></Input>
        </Form.Item>
        <Form.Item
          label={<label>便CD(前方一致)</label>}
          name="binCd"
          rules={[
            () => ({
              validator(_: any, value: string) {
                if (value && (IsHankakuEisu(value) || hasKanji(value))) {
                  return Promise.reject(
                    new Error('便CD' + MSG_ERROR['MSG_HANKAKU_EISU']),
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input
            disabled={isLoading}
            ref={el => (refForm.current.binCd = el)}
            maxLength={3}
            style={{ width: 90 }}
            onKeyUp={event =>
              (event.key === 'Enter' || event.key === 'Tab') &&
              event.currentTarget.select()
            }
            onFocus={event => {
              refPreviousValue.current.binCd = event.target.value;
            }}
            onBlur={event => {
              event.preventDefault();
              let value = ToASCII(event.target.value.trim());
              form.setFieldsValue({
                binCd: value,
              });
              if (value !== refPreviousValue.current.binCd) {
                form.validateFields(['binCd']).catch(({ errorFields }) => {
                  messagePopup({
                    type: 'info',
                    content: errorFields[0]?.errors[0],
                    onOk: () => {
                      refForm?.current?.[errorFields[0]?.name[0]]?.focus();
                      refForm?.current?.[errorFields[0]?.name[0]]?.select();
                    },
                  });
                });
              }
            }}
            onKeyDown={focusNextEle}
          ></Input>
        </Form.Item>
        <Form.Item
          label={<label>品名CD(前方一致)</label>}
          name="code24"
          rules={[
            () => ({
              validator(_: any, value: string) {
                if (value && checkForSpecialChar(value)) {
                  return Promise.reject(
                    new Error('品名CD' + MSG_ERROR['MSG_INVALID_CHAR']),
                  );
                }
                if (value && !IsHankaku(value)) {
                  return Promise.reject(
                    new Error('品名CD' + MSG_ERROR['MSG_ZENKAKU']),
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input
            disabled={isLoading}
            ref={el => (refForm.current.code24 = el)}
            maxLength={11}
            style={{ width: 400 }}
            onKeyUp={event =>
              (event.key === 'Enter' || event.key === 'Tab') &&
              event.currentTarget.select()
            }
            onFocus={event => {
              refPreviousValue.current.code24 = event.target.value;
            }}
            onBlur={event => {
              event.preventDefault();
              let value = ToASCII(event.target.value.trim(), true, false);
              form.setFieldsValue({
                code24: value,
              });
              if (value !== refPreviousValue.current.code24) {
                form.validateFields(['code24']).catch(({ errorFields }) => {
                  let convertValue = ToASCII(
                    event.target.value.trim(),
                    true,
                    true,
                  );
                  form.setFieldsValue({ code24: convertValue });
                  messagePopup({
                    type: 'info',
                    content: errorFields[0]?.errors[0],
                    onOk: () => {
                      refForm?.current?.[errorFields[0]?.name[0]]?.focus();
                      refForm?.current?.[errorFields[0]?.name[0]]?.select();
                    },
                  });
                });
              }
            }}
            onKeyDown={focusNextEle}
          ></Input>
        </Form.Item>
        <Form.Item label={'品名全角(中間一致)'} name="nnm">
          <Input
            disabled={isLoading}
            ref={el => (refForm.current.nnm = el)}
            maxLength={15}
            style={{ width: 400 }}
            onKeyUp={event =>
              (event.key === 'Enter' || event.key === 'Tab') &&
              event.currentTarget.select()
            }
            onBlur={event => {
              event.preventDefault();
              form.setFieldsValue({
                nnm: Hankaku2Zenkaku(event.target.value.trimEnd()),
              });
            }}
            onKeyDown={focusNextEle}
          ></Input>
        </Form.Item>
      </FormWrapper>

      <ControlFilter style={{ marginTop: 15 }}>
        <Space>
          <Button
            style={{ minWidth: 100 }}
            loading={isLoading}
            type="primary"
            ref={el => (refForm.current.btnSubmit = el)}
            onClick={e => {
              setDataSource({
                count: 0,
                listData: [],
              });
              setTimeout(() => {
                form.submit();
              }, 100);
            }}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                e.preventDefault();
                form.submit();
              }
            }}
          >
            検索
          </Button>
          <Button
            onClick={e => {
              e.preventDefault();
              resetExcel();
              handleExcel();
            }}
            loading={isLoadingExcel}
            type="default"
            style={{ width: 180 }}
          >
            EXCEL作成（XLSX）
          </Button>
          <Button
            onClick={event => {
              event.preventDefault();
              resetPage();
            }}
            type="default"
            ref={el => (refForm.current.btnReturn = el)}
          >
            画面クリア
          </Button>
          <Button
            type="default"
            onClick={event => {
              event.preventDefault();
              resetPage();
              navigate(PATH.WEBSEARCH_MENU);
            }}
          >
            メニューへ戻る
          </Button>
        </Space>
      </ControlFilter>

      <ModalResultShippingDetailsAggregation
        form={form}
        isModalVisible={visibleModalResult}
        setIsModalVisible={setVisibleModalResult}
        dataSource={dataSource}
        afterClose={() => {
          refForm.current.btnSubmit.focus();
          reset();
        }}
      />
    </Wrapper>
  );
};

export default ShippingDetailsAggregate;
