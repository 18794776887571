import styled from 'styled-components';

interface Props {
  url: string;
}

export const IconSearch = styled.div<Props>`
  width: 22px;
  height: 22px;
  background: url('${p => p.url}');
  background-size: 120%;
`;

export const IconLogout = styled.div<Props>`
  width: 36px;
  height: 36px;
  background: url('${p => p.url}');
  background-size: 100%;
  transition: all 0.3s;
  &:hover {
    opacity: 0.8;
  }
`;
