import { Button, Col, Space } from 'antd';
import ModalCustom from 'components/ModalCustom';
import { BodyContent, FlexColumn, FlexEnd } from 'components/StyleCommon';
import TableCustom from 'components/TableCommon';
import moment from 'moment';
import messagePopup from 'utils/message/mesage';
import { memo, useRef } from 'react';
import {
  Content,
  DetailsOrder,
  DetailsOrderItem,
  Label,
} from 'views/common/orderSheet/orderSheet.style';
import { useBacklogDelete } from 'api/stockDetails';
import { MSG_ERROR } from 'constants/text';
import { useSelector } from 'react-redux';
import { selectUserInfo } from 'slice/app';
import { useLogAccess } from 'api/auth';

export interface BacklogDeletionData {
  trokno: string;
  cd24: string;
  syohinNm: string;
  hatchubi: string;
  syohinList: Array<{
    cd24: string;
    chuno: string;
    hSuu: number;
    hachubi: string;
    hachuzan: number;
    kSuu: number;
    location: string;
  }>;
}

interface Props {
  isModalVisible: boolean;
  setIsModalVisible: any;
  data: BacklogDeletionData;
  onResetPage: any;
}
const columns = [
  {
    title: () => <div style={{ minWidth: 40 }}>№</div>,
    dataIndex: 'index',
    key: 'index',
    render: (_value: any) => (
      <div style={{ textAlign: 'center', minWidth: 40 }}>{_value + 1}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 60 }}>発注日</div>,
    dataIndex: 'hachubi',
    key: 'hachubi',
    render: (_value: any) => <div style={{ minWidth: 60 }}>{_value}</div>,
  },
  {
    title: () => <div style={{ minWidth: 70 }}>登録番号</div>,
    dataIndex: 'cpuNo',
    key: 'cpuNo',
    render: (_value: any, record: any) => (
      <div style={{ textAlign: 'center', minWidth: 70 }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 80 }}>発注№</div>,
    dataIndex: 'chuno',
    key: 'chuno',
    render: (_value: any, record: any) => (
      <div style={{ textAlign: 'center', minWidth: 80 }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 80 }}>品名CD</div>,
    dataIndex: 'cd24',
    key: 'cd24',
    render: (_value: any) => (
      <div style={{ minWidth: 80, textAlign: 'center' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 370 }}>品名</div>,
    dataIndex: 'nnm',
    key: 'nnm',
    render: (_value: any) => <div style={{ minWidth: 370 }}>{_value}</div>,
  },
  {
    title: () => <div style={{ minWidth: 60 }}>発注数</div>,
    dataIndex: 'hSuu',
    key: 'hSuu',
    render: (_value: any) => (
      <div style={{ textAlign: 'right', minWidth: 60 }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 60 }}>発注残</div>,
    dataIndex: 'hachuzan',
    key: 'hachuzan',
    render: (_value: any) => (
      <div style={{ textAlign: 'right', minWidth: 60 }}>
        {Math.round(_value)}
      </div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 70 }}>ﾛｹｰｼｮﾝ</div>,
    dataIndex: 'location',
    key: 'location',
    render: (_value: any) => (
      <div style={{ textAlign: 'center', minWidth: 70 }}>{_value}</div>
    ),
  },
];

const BacklogDeletionConfirmModal = ({
  isModalVisible,
  setIsModalVisible,
  data,
  onResetPage,
}: Props) => {
  const btnSubmitRef = useRef<any>(null);
  const userInfo = useSelector(selectUserInfo);

  /**
   * *API LogAccess
   * todo log
   * @param {...}
   */
  const { mutate: logAccess } = useLogAccess();
  const { mutate: deleteBacklog, isLoading } = useBacklogDelete();

  const onSubmit = () => {
    let request = data.syohinList.map(({ chuno, hachuzan }: any) => {
      return {
        chuno,
        hachuzan,
      };
    });
    messagePopup({
      type: 'question',
      content: '削除します。よろしいですか?',
      onOk: () => {
        deleteBacklog(
          { arr: request },
          {
            onSuccess(data, variables, context) {
              logAccess([
                {
                  tokuiCd: userInfo.torihikicd,
                  gyoumuId: '注残削除処理',
                  pgName: '確認画面',
                  logType: '2',
                  action: '注残削除実行',
                },
              ] as any);
              messagePopup({
                type: 'success',
                content: MSG_ERROR['MSG_DELETE_BACKLOG_SUCCESS'],
                onOk: () => {
                  onResetPage();
                },
              });
            },
            onError: async (error, variables, context) => {
              messagePopup({
                type: 'error',
                content:
                  MSG_ERROR[(error as any)?.response?.data?.message] ||
                  error?.response?.data?.message,
              });
            },
          },
        );
      },
      onCancel: () => btnSubmitRef.current.focus(),
    });
  };
  return (
    <ModalCustom
      titleModal={'確認'}
      size="medium"
      isModalVisible={isModalVisible}
      setIsModalVisible={setIsModalVisible}
      afterClose={() => {}}
      hideButtonClose={true}
    >
      <BodyContent className="order-confirm">
        <FlexColumn>
          <h2>{'注残削除内容をご確認ください'}</h2>
          <DetailsOrder style={{ width: 950 }}>
            <Col>
              {data?.trokno && (
                <DetailsOrderItem span={24}>
                  <Label>登録番号</Label>
                  <Content>{data.trokno}</Content>
                </DetailsOrderItem>
              )}
              {data.cd24 && (
                <DetailsOrderItem span={24}>
                  <Label>品名コード</Label>
                  <Content>{`${data.cd24}　${data.syohinNm}`}</Content>
                </DetailsOrderItem>
              )}
              {data?.hatchubi && (
                <DetailsOrderItem span={24}>
                  <Label>発注日</Label>
                  <Content>
                    {moment(data.hatchubi).format(
                      'YYYY/MM/DD (ddd) 以前を全て削除',
                    )}
                  </Content>
                </DetailsOrderItem>
              )}
            </Col>
          </DetailsOrder>
        </FlexColumn>
        <FlexColumn style={{ marginTop: 20 }}>
          <TableCustom
            rowKey="index"
            height={'230px'}
            dataSource={data.syohinList || []}
            columns={columns}
          />
        </FlexColumn>
        <FlexEnd style={{ marginTop: 20 }}>
          <Space>
            <Button
              ref={btnSubmitRef}
              autoFocus
              type="primary"
              loading={isLoading}
              onClick={onSubmit}
            >
              削除します
            </Button>
            <Button
              disabled={isLoading}
              type="default"
              onClick={() => {
                setIsModalVisible(false);
              }}
              style={{ minWidth: 100 }}
            >
              戻る
            </Button>
          </Space>
        </FlexEnd>
      </BodyContent>
    </ModalCustom>
  );
};

export default memo(BacklogDeletionConfirmModal);
