import { API_URL } from 'constants/api';
import { useMutation } from 'react-query';
import AxiosDelete from 'utils/axios/axiosDelete';
import AxiosPost from 'utils/axios/axiosPost';
import AxiosPut from 'utils/axios/axiosPut';

interface CUDTantouProps {
  tokucd?: string;
  kmancd?: string;
  kmannm1?: string;
}

export function useInsertTantou() {
  /**
   * *API INSERT TANTOU
   * todo Hooks insert tantou
   * @param API
   */
  const insertTantou = async (data: CUDTantouProps) => {
    return await AxiosPost(API_URL.TANTOU, data);
  };

  const mutation = useMutation(insertTantou as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });

  return mutation;
}

export function useUpdateTantou() {
  /**
   * *API UPDATE TANTOU
   * todo Hooks update tantou
   * @param API
   */
  const updateTantou = async (data: CUDTantouProps) => {
    return await AxiosPut(API_URL.TANTOU, data);
  };

  const mutation = useMutation(updateTantou as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });

  return mutation;
}

export function useDeleteTantou() {
  /**
   * *API DELETE TANTOU
   * todo Hooks delete tantou
   * @param API
   */
  const deleteTantou = async (data: CUDTantouProps) => {
    return await AxiosDelete(API_URL.TANTOU, {}, data);
  };

  const mutation = useMutation(deleteTantou as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });

  return mutation;
}

export function useRestoreTantou() {
  /**
   * *API RESTORE TANTOU
   * todo Hooks restore tantou
   * @param API
   */
  const restoreTantou = async (data: CUDTantouProps) => {
    return await AxiosPost(API_URL.TANTOU_RESTORE, data);
  };

  const mutation = useMutation(restoreTantou as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });

  return mutation;
}
