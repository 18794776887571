export const columns = [
  {
    title: () => <div style={{ minWidth: 100 }}>No</div>,
    dataIndex: 'id',
    key: 'id',
    align: 'right',
    render: (_value: any, record: any, index: any) => (
      <div style={{ minWidth: 100, width: 'max-content' }}>{index + 1}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 250 }}>受付番号数</div>,
    dataIndex: 'countDistinctOrderNo',
    key: 'countDistinctOrderNo',
    align: 'right',
    render: (_value: any) => (
      <div style={{ minWidth: 250, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 250 }}>出荷行数</div>,
    dataIndex: 'countOrderKey',
    key: 'countOrderKey',
    align: 'right',
    render: (_value: any) => (
      <div style={{ minWidth: 250, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 250 }}>伝票番号数</div>,
    dataIndex: 'countDistinctDenNo',
    key: 'countDistinctDenNo',
    align: 'right',
    render: (_value: any) => (
      <div style={{ minWidth: 250, width: 'max-content' }}>{_value}</div>
    ),
  },
];
