import { Link } from 'react-router-dom';
import { Card, Space, Table } from 'antd';
import styled from 'styled-components';
interface StyleProps {
  background?: string;
}

export const LinkMenu = styled(Link)`
  display: block;
  font-size: 16px;
  padding: 4px 16px;
  /* box-shadow: rgba(255, 255, 255, 0.2) 0px 0px 0px 1px inset,
    rgba(0, 0, 0, 0.5) 0px 0px 0px 1px; */
  color: #000;
  transition: all 0.3s;
  text-decoration: underline;
  &:hover,
  &:focus {
    /* background-color: #e0e0e0;
    color: #000; */
    text-decoration: underline;
  }
  /* border: 1px solid black; */
  &:not(:last-child) {
    /* border-bottom: none; */
  }
`;

export const MenuGroup = styled(Card)<StyleProps>`
  box-shadow: rgba(255, 255, 255, 0.2) 0px 0px 0px 1px inset,
    rgba(0, 0, 0, 0.5) 0px 0px 0px 1px;
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px; */
  border-radius: 4px;
  overflow: hidden;
  border: none;
  .ant-card-head {
    background: ${props => props.background || '#FFF'};
    border: none;
    min-height: 0px;
    .ant-card-head-title {
      padding: 8px 0;
      font-weight: 600;
      font-size: 18px;
      color: #000;
    }
  }
  .ant-card-body {
    padding: 0;
    min-height: 0px;
  }
`;

export const TableMenuWebSearch = styled(Table)`
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 0;
  }
`;

export const MenuContainer = styled(Space)`
  width: 100%;
  max-width: 990px;
  .ant-space-item {
    width: 100%;
  }
  padding-bottom: 50px;
`;

export const MenuWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
`;

export const Button = styled.button`
  background: #4753e1;
  color: white;
  cursor: pointer;
  font-size: 1em;
  padding: 0.25em 1em;
  border: 2px solid #4753e1;
  border-radius: 3px;
  &:hover {
    opacity: 0.9;
    transition: all 0.15s linear;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .ant-btn {
    font-weight: 500;
  }
`;

export const BodyContent = styled.div`
  margin-top: 8px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  .textInline {
    width: 100%;
    height: 22px;
  }
  .rpv-open__input-wrapper {
    display: none;
  }
  .error_maxlength {
    margin: 4px 0;
    width: 100%;
    height: 20px;
  }
`;

export const ControlFilter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  button + button {
    margin-left: 10px;
  }
`;
