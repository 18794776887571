import { Form, Space } from 'antd';
import { CheckRangeDate, InputCheck } from 'constants/common';
import { MSG_ERROR } from 'constants/text';
import moment from 'moment';
import React, { useEffect, useRef } from 'react';
import messagePopup from 'utils/message/mesage';
import DatePickerCustom from './DatePickerCustom';

interface Props {
  previousFocus?: string;
  nextFocus: string;
  form: any;
  refForm: any;
  autoFocus: any;
  title: any;
  disabled?: boolean;
}

const DATE_FORMAT = 'YYMMDD';

const RangeDatePickerCustom = ({
  title,
  previousFocus,
  nextFocus,
  form,
  refForm,
  autoFocus,
  disabled,
}: Props) => {
  const focusValidate = useRef<any>('');
  const pressEnterFrom = useRef(false);
  const pressEnterTo = useRef(false);

  const [fromDateValue, setFromDateValue] = React.useState(
    moment(form.getFieldValue('fromDate')).format('dd'),
  );
  const [toDateValue, setToDateValue] = React.useState(
    moment(form.getFieldValue('toDate')).format('dd'),
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setFromDateValue(moment(form.getFieldValue('fromDate')).format('dd'));
    setToDateValue(moment(form.getFieldValue('toDate')).format('dd'));
  });

  return (
    <Space style={{ minWidth: 470 }}>
      <Form.Item
        name="fromDate"
        noStyle
        rules={[
          ({ getFieldValue }) => ({
            validator(_: any, value: string) {
              let _tempValueFrom = moment(value).format(DATE_FORMAT);
              let _tempValueTo = moment(getFieldValue('toDate')).format(
                DATE_FORMAT,
              );

              if (!!InputCheck(_tempValueFrom)) {
                return Promise.reject(
                  new Error(title + MSG_ERROR[InputCheck(_tempValueFrom)]),
                );
              }
              if (
                value &&
                getFieldValue('toDate') &&
                focusValidate.current === 'fromDate' &&
                !!CheckRangeDate(_tempValueFrom, _tempValueTo)
              ) {
                return Promise.reject(
                  new Error(
                    title +
                      MSG_ERROR[CheckRangeDate(_tempValueFrom, _tempValueTo)],
                  ),
                );
              }
              return Promise.resolve();
            },
          }),
        ]}
      >
        <DatePickerCustom
          disabled={disabled}
          form={form}
          title={title}
          pressEnter={pressEnterFrom}
          autoFocus={autoFocus}
          nextFocus={() => {
            refForm.current.toDate.focus();
          }}
          previousFocus={previousFocus}
          allowClear={false}
          refForm={refForm}
          formName="fromDate"
          onChange={(value: any, dateString: any) => {
            setFromDateValue(moment(value).format('dd'));
            focusValidate.current = 'fromDate';
            form
              ?.validateFields(['fromDate'])
              .then(
                (_res: any) =>
                  pressEnterFrom.current && refForm.current.toDate.focus(),
              )
              .catch(({ errorFields }: any) => {
                errorFields?.length > 0 &&
                  messagePopup({
                    type: 'info',
                    content: errorFields[0]?.errors[0],
                    onOk: () => {
                      refForm?.current?.[errorFields[0]?.name[0]]?.focus();
                    },
                  });
              });
          }}
          style={{ width: 150, padding: '2px 10px' }}
          format={DATE_FORMAT}
          getPopupContainer={(trigger: HTMLElement) =>
            trigger.parentNode as HTMLElement
          }
        />
      </Form.Item>
      <span>（{form.getFieldValue('fromDate') ? fromDateValue : '　'}）</span>～
      <Form.Item
        name="toDate"
        noStyle
        rules={[
          ({ getFieldValue }) => ({
            validator(_: any, value: string) {
              let _tempValueTo = moment(value).format(DATE_FORMAT);
              let _tempValueFrom = moment(getFieldValue('fromDate')).format(
                DATE_FORMAT,
              );

              if (!!InputCheck(_tempValueTo)) {
                return Promise.reject(
                  new Error(title + MSG_ERROR[InputCheck(_tempValueTo)]),
                );
              }
              if (
                value &&
                getFieldValue('fromDate') &&
                focusValidate.current === 'toDate' &&
                !!CheckRangeDate(_tempValueFrom, _tempValueTo)
              ) {
                return Promise.reject(
                  new Error(
                    title +
                      MSG_ERROR[CheckRangeDate(_tempValueFrom, _tempValueTo)],
                  ),
                );
              }
              return Promise.resolve();
            },
          }),
        ]}
      >
        <DatePickerCustom
          disabled={disabled}
          pressEnter={pressEnterTo}
          allowClear={false}
          refForm={refForm}
          previousFocus={'fromDate'}
          formName="toDate"
          title={title}
          nextFocus={() => {
            refForm.current[`${nextFocus}`].focus();
          }}
          onChange={(value: any, dateString: any) => {
            setToDateValue(moment(value).format('dd'));
            focusValidate.current = 'toDate';
            form
              ?.validateFields(['toDate'])
              .then(
                (_res: any) =>
                  pressEnterTo.current &&
                  refForm.current[`${nextFocus}`].focus(),
              )
              .catch(({ errorFields }: any) => {
                errorFields?.length > 0 &&
                  messagePopup({
                    type: 'info',
                    content: errorFields[0]?.errors[0],
                    onOk: () => {
                      refForm?.current?.[errorFields[0]?.name[0]]?.focus();
                    },
                  });
              });
          }}
          style={{ width: 150, padding: '2px 10px' }}
          format={DATE_FORMAT}
          getPopupContainer={(trigger: HTMLElement) =>
            trigger.parentNode as HTMLElement
          }
        />
      </Form.Item>
      <span>（{form.getFieldValue('toDate') ? toDateValue : '　'}）</span>
    </Space>
  );
};

export default RangeDatePickerCustom;
