import { Button } from 'antd';
import { useLogAccess } from 'api/auth';
import { useMasterCheck } from 'api/masterCheck';
import LoadingCallAPI from 'components/LoadingCallAPI/LoadingCallAPI';
import { PATH } from 'configs/routes';
import { MSG_ERROR } from 'constants/text';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectUserInfo } from 'slice/app';
import messagePopup from 'utils/message/mesage';
import { Footer, Guidance, Wrapper } from './masterCheck.style';

interface MasterCheckResult {
  content: string;
  level: number;
}

const MasterCheck = () => {
  const userInfo = useSelector(selectUserInfo);

  const navigate = useNavigate();
  const [results, setResults] = useState<Array<MasterCheckResult>>([]);

  const { mutate: masterCheck, data, error, isLoading } = useMasterCheck();

  /**
   * *API LogAccess
   * todo log
   * @param {...}
   */
  const { mutate: logAccess } = useLogAccess();

  useEffect(() => {
    if (!error) {
      return;
    }
    messagePopup({
      type: 'error',
      content:
        MSG_ERROR[error?.response?.data?.message] ||
        error?.response?.data?.message,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (!data) return;
    logAccess([
      {
        tokuiCd: userInfo.torihikicd,
        gyoumuId: 'マスタチェック',
        pgName: 'チェック',
        logType: '2',
        action: 'マスタチェック開始ボタン',
      },
    ] as any);
    setResults(data.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const renderRow = (rowData: MasterCheckResult, index: number) => {
    let spacing = '';
    for (let idx = 0; idx < rowData.level; idx++) {
      if (idx % 2 === 0) spacing += '　　';
    }
    return rowData.content ? (
      <p key={index}>{spacing + `${rowData.content}`}</p>
    ) : (
      <br key={index} />
    );
  };

  return (
    <Wrapper>
      {isLoading && <LoadingCallAPI />}
      <Guidance>
        <div>{'各マスタの整合性をチェックします。'}</div>
        <div>{'「マスタチェック開始」ボタンをクリックして下さい。'}</div>
      </Guidance>
      <Button
        autoFocus
        type="primary"
        onClick={() => {
          masterCheck({});
        }}
      >
        {'マスタチェック開始'}
      </Button>
      <div
        style={{
          width: '100%',
          margin: '16px 0',
          padding: '16px',
          borderRadius: 10,
          background: '#fff',
          boxShadow:
            'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset',
        }}
      >
        <div
          style={{
            width: '100%',
            fontWeight: 'bold',
            fontSize: 16,
          }}
        >
          {'実行結果'}
        </div>
        <div className="results-wrapper">
          {!!results.length &&
            results.map((item: MasterCheckResult, index: number) =>
              renderRow(item, index),
            )}
        </div>
      </div>
      <Footer>
        <Button onClick={() => window.print()}>{'印刷'}</Button>
        <Button
          onClick={() => {
            navigate(PATH.TOP);
          }}
        >
          {'メニューへ戻る'}
        </Button>
      </Footer>
    </Wrapper>
  );
};

export default MasterCheck;
