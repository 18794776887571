import { API_URL } from 'constants/api';
import { useMutation } from 'react-query';
import AxiosPost from 'utils/axios/axiosPost';
import AxiosGetFile from 'utils/axios/getFile/axiosGetFile';

export function useGetOrderProcessingCheck() {
  const getInquiry = async () => {
    return await AxiosPost(API_URL.GET_ORDERPROCESSINGCHECK, {});
  };

  const mutation = useMutation(getInquiry as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });
  return mutation;
}

export function useGetOrderProcessingCheckExcel() {
  const getInquiry = async () => {
    return await AxiosGetFile(API_URL.GET_ORDERPROCESSINGCHECK_EXCEL, {});
  };

  const mutation = useMutation(getInquiry as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });

  return mutation;
}
