import { API_URL } from 'constants/api';
import { useMutation } from 'react-query';
import AxiosPost from 'utils/axios/axiosPost';
import AxiosGetFile from 'utils/axios/getFile/axiosGetFile';

interface TypeProps {
  idCd24: string | null;
  idKana: string | null;
  idNnm: string | null;
}

export function useGetStockInformationProduct() {
  const getProduct = async ({ idCd24, idKana, idNnm }: TypeProps) => {
    const params = {
      idCd24,
      idKana,
      idNnm,
    };
    // console.log(params);
    return await AxiosPost(API_URL.GET_STOCKINFORMATIONPRODUCT, params);
  };

  const mutation = useMutation(getProduct as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });
  return mutation;
}

export function useGetStockInformationProductExcel() {
  const getProduct = async ({ idCd24, idKana, idNnm }: TypeProps) => {
    const params = {
      idCd24,
      idKana,
      idNnm,
    };
    return await AxiosGetFile(
      API_URL.GET_STOCKINFORMATIONPRODUCT_EXCEL,
      params,
    );
  };

  const mutation = useMutation(getProduct as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });

  return mutation;
}
