/* eslint-disable react-hooks/exhaustive-deps */
import { useGetSyohinInfo } from 'api/syohin';
import { MSG_ERROR } from 'constants/text';
import React from 'react';
import messagePopup from 'utils/message/mesage';
interface Props {
  syohinCd: string | null;
}
const SyohinInfo = ({ syohinCd }: Props) => {
  const ENDPOINT = process.env.REACT_APP_ENDPOINT_PDF;
  const { mutate, data, error, isLoading } = useGetSyohinInfo(syohinCd || '');
  React.useEffect(() => {
    if (syohinCd) mutate({} as any);
  }, [syohinCd]);

  React.useEffect(() => {
    if (data) {
      const titlePage = `${syohinCd} ${
        data?.data?.nnm ? data?.data?.nnm : ''
      }  ${data?.data?.nnmsz ? data?.data?.nnmsz : ''} `;
      document.title = titlePage;
    }
  }, [data]);

  React.useEffect(() => {
    if (error) {
      messagePopup({
        type: 'error',
        content:
          MSG_ERROR[(error as any)?.response?.data?.message] ||
          (error as any)?.response?.data?.message,
      });
    }
  }, [error]);

  return (
    <div style={{ height: '99vh', minWidth: '100vw' }}>
      {!isLoading && data?.data?.url && (
        <iframe
          src={`${ENDPOINT}${data?.data?.url}`}
          width="100%"
          height="100%"
          title={`a`}
        ></iframe>
      )}
    </div>
  );
};

export default SyohinInfo;
