import { Button, Form, Space } from 'antd';
import {
  useGetInquiryHat,
  useGetInquiryHatExcel,
} from 'api/hatProductDelivery';
import RangeDatePickerCustom from 'components/RangePickerCustom';
import { PATH } from 'configs/routes';
import { MSG_ERROR, TEXT_ALL } from 'constants/text';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectUserInfo } from 'slice/app';
import messagePopup from 'utils/message/mesage';
import { FormWrapper } from 'views/login/FormLogin/formLogin.style';
import { ControlFilter, MenuWrapper, Wrapper } from '../webSearchMenu.style';
import ModalResultHatProductDelivery from './modalResult';

//Format Date
const dateFormat = 'YYMMDD';

const HatProductDelivery = () => {
  const userInfo = useSelector(selectUserInfo);
  const labelTokuisaki =
    (userInfo.kaisya ?? '') + (userInfo.tensyo ?? '') + TEXT_ALL.SAMA;
  const [form] = Form.useForm();
  const refForm = useRef<any>({});
  const navigate = useNavigate();
  const [dataSource, setDataSource] = useState<any>({
    count: 0,
    listData: [],
  });
  const [visibleModalResult, setVisibleModalResult] = useState<any>(false);
  const { mutate, isLoading, reset } = useGetInquiryHat();
  const {
    mutate: excelMutation,
    isLoading: isLoadingExcel,
    reset: resetExcel,
  } = useGetInquiryHatExcel();

  // Func reset form and clear cache data
  function resetPage() {
    form.resetFields();
    reset();
    resetExcel();
    setDataSource({
      count: 0,
      listData: [],
    });
  }

  // In first render
  useEffect(() => {
    resetPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // onSubmit to get response list
  const onFinish = async (record: any) => {
    let params = {
      fromDt: moment(record.fromDate).format(dateFormat),
      toDt: moment(record.toDate).format(dateFormat),
    };
    mutate(params as any, {
      onSuccess(data, variables, context) {
        const { data: dataList } = data;
        if (dataList?.count > 0) {
          setDataSource({
            count: dataList?.count,
            listData: dataList?.data?.map((order: any, index: number) => ({
              id: index + 1,
              ...order,
            })),
          });
          setVisibleModalResult(true);
        } else {
          messagePopup({
            type: 'error',
            content: MSG_ERROR['MSG_NO_DATA'],
            onOk: () => {
              refForm?.current?.fromDate?.focus();
            },
          });
        }
      },
      onError(error, variables, context) {
        messagePopup({
          type: 'error',
          content:
            MSG_ERROR[(error as any)?.response?.data?.message] ||
            (error as any)?.response?.data?.message,
          onOk: () => {
            refForm?.current?.fromDate?.focus();
          },
        });
      },
    });
  };

  // Validate form input
  const onFinishFailed = ({ values, errorFields }: any) => {
    messagePopup({
      type: 'info',
      content: errorFields[0]?.errors[0],
      onOk: () => {
        refForm?.current?.[errorFields[0]?.name[0]]?.focus();
        !!refForm?.current?.[errorFields[0]?.name[0]]?.select?.toString() &&
          refForm?.current?.[errorFields[0]?.name[0]]?.select();
      },
    });
  };

  //Handle Excel
  const handleExcel = () => {
    const { fromDate, toDate } = form.getFieldsValue();
    const params = {
      fromDt: moment(fromDate).format('YYMMDD'),
      toDt: moment(toDate).format('YYMMDD'),
    };

    form
      .validateFields()
      .then(() =>
        excelMutation(params as any, {
          onSuccess(data, variables, context) {
            let fileName = data.headers['content-disposition'] || 'errorName';
            const file = new Blob([data?.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            const fileURL = URL.createObjectURL(file);
            const link = document.createElement('a');
            link.href = fileURL;
            link.download = decodeURIComponent(
              fileName?.split('filename*=')[1].split(';')[0],
            ).substring(7);
            link.click();
          },
          onError: async (error, variables, context) => {
            const errorMsg = JSON.parse(
              await error.response.data.text(),
            )?.message;
            messagePopup({
              type: 'error',
              content: MSG_ERROR[errorMsg] || errorMsg,
              onOk: () => {
                refForm?.current?.fromDate?.focus();
              },
            });
          },
        }),
      )
      .catch(({ errorFields }) => {
        messagePopup({
          type: 'info',
          content: errorFields[0]?.errors[0],
          onOk: () => {
            refForm?.current?.[errorFields[0]?.name[0]]?.focus();
            !!refForm?.current?.[errorFields[0]?.name[0]]?.select?.toString() &&
              refForm?.current?.[errorFields[0]?.name[0]]?.select();
          },
        });
      });
  };

  return (
    <Wrapper>
      <MenuWrapper style={{ marginBottom: 8 }}>
        <span>{labelTokuisaki}</span>
      </MenuWrapper>

      <FormWrapper
        form={form}
        className="form-mainte"
        name="websearch-mainte"
        labelCol={{ flex: '150px' }}
        wrapperCol={{ flex: 1 }}
        requiredMark={false}
        labelAlign="left"
        colon={false}
        validateTrigger="onSubmit"
        scrollToFirstError
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{
          fromDate: moment().subtract(1, 'months').startOf('month'),
          toDate: moment().subtract(1, 'months').endOf('month'),
        }}
        onKeyPress={(e: any) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
      >
        <Form.Item
          label={
            <label>
              {'納入日'}
              <label style={{ color: 'red' }}> 【必須】</label>
            </label>
          }
        >
          <RangeDatePickerCustom
            title={'納入日'}
            autoFocus={true}
            nextFocus="btnSubmit"
            form={form}
            refForm={refForm}
            disabled={isLoading}
          />
        </Form.Item>
      </FormWrapper>

      <ControlFilter style={{ marginTop: 15 }}>
        <Space>
          <Button
            style={{ minWidth: 100 }}
            loading={isLoading}
            type="primary"
            ref={el => (refForm.current.btnSubmit = el)}
            onClick={e => {
              e.preventDefault();
              setDataSource({
                count: 0,
                listData: [],
              });
              setTimeout(() => {
                form.submit();
              }, 100);
            }}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                e.preventDefault();
                e.currentTarget.click();
              }
            }}
          >
            検索
          </Button>
          <Button
            onClick={e => {
              e.preventDefault();
              resetExcel();
              handleExcel();
            }}
            loading={isLoadingExcel}
            type="default"
            style={{ width: 180 }}
          >
            EXCEL作成（XLSX）
          </Button>
          <Button
            onClick={event => {
              // console.log('first');
              resetPage();
            }}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                e.preventDefault();
                e.currentTarget.click();
              }
            }}
            type="default"
            ref={el => (refForm.current.btnReturn = el)}
          >
            画面クリア
          </Button>
          <Button
            type="default"
            onClick={event => {
              resetPage();
              navigate(PATH.WEBSEARCH_MENU);
            }}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                e.preventDefault();
                e.currentTarget.click();
              }
            }}
          >
            メニューへ戻る
          </Button>
        </Space>
      </ControlFilter>

      <ModalResultHatProductDelivery
        form={form}
        isModalVisible={visibleModalResult}
        setIsModalVisible={setVisibleModalResult}
        dataSource={dataSource}
        afterClose={() => {
          refForm.current.btnSubmit.focus();
          reset();
        }}
      />
    </Wrapper>
  );
};

export default HatProductDelivery;
