export const columns = [
  {
    title: () => <div style={{ minWidth: 30 }}>No</div>,
    dataIndex: 'id',
    key: 'id',
    align: 'right',
    render: (_value: any, record: any, index: any) => (
      <div style={{ minWidth: 30, width: 'max-content' }}>{index + 1}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 85 }}>企業グループ</div>,
    dataIndex: 'kigyoGroupName',
    key: 'kigyoGroupName',
    render: (_value: any) => (
      <div style={{ minWidth: 85, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 65 }}>協力店CD</div>,
    dataIndex: 'torihikicd',
    key: 'torihikicd',
    render: (_value: any) => (
      <div style={{ minWidth: 65, width: 'max-content' }}>{_value}</div>
    ),
  },
  // {
  //   title: () => <div style={{ minWidth: 110 }}>旧CAP事業者CD</div>,
  //   dataIndex: 'captytoricd',
  //   key: 'captytoricd',
  //   render: (_value: any) => (
  //     <div style={{ minWidth: 110, width: 'max-content' }}>{_value}</div>
  //   ),
  // },
  {
    title: () => <div style={{ minWidth: 175 }}>現CAP事業者CD（１０桁）</div>,
    dataIndex: 'chumonfax',
    key: 'chumonfax',
    render: (_value: any) => (
      <div style={{ minWidth: 175, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 80 }}>企業区分</div>,
    dataIndex: 'decodedKigyoKbn',
    key: 'decodedKigyoKbn',
    render: (_value: any) => (
      <div style={{ minWidth: 80, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 80 }}>所属本部</div>,
    dataIndex: 'tensyoH',
    key: 'tensyoH',
    render: (_value: any) => (
      <div style={{ minWidth: 80, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 80 }}>会社名</div>,
    dataIndex: 'kaisya',
    key: 'kaisya',
    render: (_value: any) => (
      <div style={{ minWidth: 80, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 80 }}>事業所名</div>,
    dataIndex: 'tensyo',
    key: 'tensyo',
    render: (_value: any) => (
      <div style={{ minWidth: 80, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 80 }}>会社名かな</div>,
    dataIndex: 'kaisyaH',
    key: 'kaisyaH',
    render: (_value: any) => (
      <div style={{ minWidth: 80, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 50 }}>ID</div>,
    dataIndex: 'id',
    key: 'id',
    render: (_value: any) => (
      <div style={{ minWidth: 50, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 80 }}>PASSWD</div>,
    dataIndex: 'passwd',
    key: 'passwd',
    render: (_value: any) => (
      <div style={{ minWidth: 80, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 60 }}>TEL</div>,
    dataIndex: 'tel',
    key: 'tel',
    render: (_value: any) => (
      <div style={{ minWidth: 60, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 60 }}>FAX</div>,
    dataIndex: 'fax',
    key: 'fax',
    render: (_value: any) => (
      <div style={{ minWidth: 60, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 60 }}>ADRS1</div>,
    dataIndex: 'adrs1',
    key: 'adrs1',
    render: (_value: any) => (
      <div style={{ minWidth: 60, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 60 }}>ADRS2</div>,
    dataIndex: 'adrs2',
    key: 'adrs2',
    render: (_value: any) => (
      <div style={{ minWidth: 60, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 60 }}>ADRS3</div>,
    dataIndex: 'adrs3',
    key: 'adrs3',
    render: (_value: any) => (
      <div style={{ minWidth: 60, width: 'max-content' }}>{_value}</div>
    ),
  },
];
