import { Form, Space } from 'antd';
import { InputCheck } from 'constants/common';
import { MSG_ERROR } from 'constants/text';
import moment from 'moment';
import React, { useRef } from 'react';
import DatePickerCustom from './DatePickerCustom';

interface Props {
  nextFocus: any;
  form: any;
  refForm: any;
  autoFocus: any;
  title: any;
  hideDD?: any;
  formName?: string;
  afterChangeValue?: any;
  disabled?: boolean;
  validateRules?: any;
}

const DATE_FORMAT = 'YYMMDD';

const PickerCustom = ({
  nextFocus,
  form,
  refForm,
  autoFocus,
  title,
  hideDD,
  formName = 'toDate',
  disabled = false,
  validateRules = () => {},
  afterChangeValue = () => {},
}: Props) => {
  const [toDateValue, setToDateValue] = React.useState(
    moment(form.getFieldValue(formName)).format('dd'),
  );
  const pressEnterTo = useRef(false);

  return (
    <Space>
      <Form.Item
        name={formName}
        noStyle
        rules={[
          ({ getFieldValue }) => ({
            validator(_: any, value: string) {
              let _tempValueTo = moment(value).format(DATE_FORMAT);

              if (!disabled && value && !!InputCheck(_tempValueTo)) {
                return Promise.reject(
                  new Error(title + MSG_ERROR[InputCheck(_tempValueTo)]),
                );
              }
              if (!disabled && validateRules(value)) {
                return Promise.reject(new Error(validateRules(value)));
              }
              return Promise.resolve();
            },
          }),
        ]}
      >
        <DatePickerCustom
          disabled={disabled}
          pressEnter={pressEnterTo}
          autoFocus={autoFocus}
          allowClear={false}
          refForm={refForm}
          formName={formName}
          nextFocus={nextFocus}
          title={title}
          onChange={(value: any, _dateString: any) => {
            setToDateValue(moment(value).format('dd'));
            afterChangeValue(value);
          }}
          style={{ width: 150, padding: '2px 10px' }}
          format={DATE_FORMAT}
          getPopupContainer={(trigger: HTMLElement) =>
            trigger.parentNode as HTMLElement
          }
        />
      </Form.Item>
      {hideDD || <span>（{toDateValue}）</span>}
    </Space>
  );
};

export default PickerCustom;
