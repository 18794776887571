import { API_URL } from 'constants/api';
import { useMutation } from 'react-query';
import AxiosPost from 'utils/axios/axiosPost';
import AxiosGetFile from './../utils/axios/getFile/axiosGetFile';

interface TypeProps {
  nohinDt: string | null;
  binCd: string | null;
  n2Atesaki1: string | null;
  genbanm: string | null;
  sendAdr: string | null;
}

export function useGetListRevokedVouchersPDF() {
  const getList = async ({
    nohinDt,
    binCd,
    n2Atesaki1,
    genbanm,
    sendAdr,
  }: TypeProps) => {
    const params = {
      nohinDt,
      binCd,
      n2Atesaki1,
      genbanm,
      sendAdr,
    };
    return await AxiosGetFile(API_URL.GET_LISTREVOKEDVOUCHERS_PDF, params);
  };

  const mutation = useMutation(getList as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });
  return mutation;
}

export function useGetListRevokedVouchers() {
  const getList = async ({
    nohinDt,
    binCd,
    n2Atesaki1,
    genbanm,
    sendAdr,
  }: TypeProps) => {
    const params = {
      nohinDt,
      binCd,
      n2Atesaki1,
      genbanm,
      sendAdr,
    };
    // console.log(params);
    return await AxiosPost(API_URL.GET_LISTREVOKEDVOUCHERS, params);
  };

  const mutation = useMutation(getList as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });

  return mutation;
}

export function useGetListRevokedVouchersExcel() {
  const getListRevokedVouchersExcel = async ({
    nohinDt,
    binCd,
    n2Atesaki1,
    genbanm,
    sendAdr,
  }: TypeProps) => {
    const params = {
      nohinDt,
      binCd,
      n2Atesaki1,
      genbanm,
      sendAdr,
    };
    return await AxiosGetFile(API_URL.GET_LISTREVOKEDVOUCHERS_EXCEL, params);
  };

  const mutation = useMutation(getListRevokedVouchersExcel as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });

  return mutation;
}

export function useGetVoucherCollectionChecklist() {
  const getList = async ({
    nohinDt,
    binCd,
    n2Atesaki1,
    genbanm,
    sendAdr,
  }: TypeProps) => {
    const params = {
      nohinDt,
      binCd,
      n2Atesaki1,
      genbanm,
      sendAdr,
    };
    return await AxiosPost(API_URL.GET_LISTREVOKEDVOUCHERSFORSURVEY, params);
  };

  const mutation = useMutation(getList as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });

  return mutation;
}

export function useGetVoucherCollectionChecklistExcel() {
  const getListRevokedVouchersExcel = async ({
    nohinDt,
    binCd,
    n2Atesaki1,
    genbanm,
    sendAdr,
  }: TypeProps) => {
    const params = {
      nohinDt,
      binCd,
      n2Atesaki1,
      genbanm,
      sendAdr,
    };
    return await AxiosGetFile(
      API_URL.GET_LISTREVOKEDVOUCHERSFORSURVEY_EXCEL,
      params,
    );
  };

  const mutation = useMutation(getListRevokedVouchersExcel as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });

  return mutation;
}
