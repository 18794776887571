import { Button, Divider, Form, Input, Select, Space } from 'antd';
import { useGetListBin } from 'api/hanOtodokesaki';
import { useGetOrderDetails, useGetShippingInfo } from 'api/shipping';
import LoadingCallAPI from 'components/LoadingCallAPI/LoadingCallAPI';
import PickerCustom from 'components/RangePickerCustom/PickerCustom';
import TableCustom from 'components/TableCommon';
import { PATH } from 'configs/routes';
import {
  Hankaku2Zenkaku,
  IsHankakuEisu,
  IsZenkaku,
  ToASCII,
  isNumeric,
} from 'constants/common';
import { MSG_ERROR, TEXT_ALL } from 'constants/text';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectUserInfo } from 'slice/app';
import messagePopup from 'utils/message/mesage';
import OrderSheet from 'views/common/orderSheet';
import {
  FooterForm,
  FormWrapper,
  HeaderForm,
  WrapperMainte,
} from 'views/login/FormLogin/formLogin.style';
import InputBara from './InputBara';
import ShippingConfirm from './shippingConfirm';
import ShippingConfirmReprint from './shippingConfirmReprint';
import DatePickerCommonV2 from 'components/DatePickerCommon/DatePickerCommonV2';

const DATE_FORMAT = 'YYMMDD';

const ShippingCorrection = () => {
  const navigate = useNavigate();
  const userInfo = useSelector(selectUserInfo);
  const labelTokuisaki =
    (userInfo.kaisya ?? '') + (userInfo.tensyo ?? '') + TEXT_ALL.SAMA;

  const formRef = useRef<any>({});
  const inputRef = useRef<any>([]);

  const previousOrderNo = useRef<any>();
  const previousConfirmDel = useRef<any>();

  const [isVisibleDetailOrder, setIsVisibleDetailOrder] =
    useState<boolean>(false);
  const [isVisibleConfirmShipping, setIsVisibleConfirmShipping] =
    useState<boolean>(false);
  const [isVisibleConfirmShippingReprint, setIsVisibleConfirmShippingReprint] =
    useState<boolean>(false);
  const [isChangeDenKbn, setIsChangeDenKbn] = useState<boolean>(false);

  const [previousShippingInfor, setPreviousShippingInfor] = useState<any>({});
  const [confirmShippingInfor, setConfirmShippingInfor] = useState({});

  const [listMeisai, setListMeisai] = useState<any>([]);

  const [disableInput, setDisableInput] = useState({
    disableFormSearch: false,
    disableFormShipping: true,
    disableBtnShipping: false,
  });

  const [openDropdown, setOpenDropdown] = useState<boolean>(false);

  const [form] = Form.useForm();

  const [formSearch] = Form.useForm();

  const [formReprint] = Form.useForm();

  const columns: any = (
    form: any,
    lstMeisai: any,
    setLstMeisai: any,
    inputRef: any,
    formRef: any,
  ) => [
      {
        title: () => <div style={{ minWidth: 60 }}>Ｎｏ．</div>,
        dataIndex: 'index',
        key: 'index',
        render: (_value: any) => (
          <div style={{ textAlign: 'center', minWidth: 60 }}>{_value + 1}</div>
        ),
      },
      {
        title: () => <div style={{ minWidth: 120 }}>品名ＣＤ</div>,
        dataIndex: 'syohinCd',
        key: 'syohinCd',
        render: (_value: any) => <div style={{ minWidth: 120 }}>{_value}</div>,
      },
      {
        title: () => <div style={{ minWidth: 470 }}>品名</div>,
        dataIndex: 'nnm',
        key: 'nnm',
        render: (_value: any, record: any) => (
          <div style={{ lineHeight: 1, minWidth: 470 }}>
            {_value}
            {record.nnmsz}
          </div>
        ),
      },
      {
        title: () => <div style={{ minWidth: 100 }}>ロケーション</div>,
        dataIndex: 'location',
        key: 'location',
        render: (_value: any, record: any) => (
          <div style={{ textAlign: 'center', lineHeight: 1, minWidth: 100 }}>
            {_value}
          </div>
        ),
      },
      {
        title: () => <div style={{ minWidth: 100 }}>数量</div>,
        dataIndex: 'bara',
        key: 'bara',
        render: (_value: any) => (
          <div style={{ textAlign: 'right', minWidth: 100 }}>
            {_value ? Number(_value).toLocaleString() : _value}
          </div>
        ),
      },
      {
        title: () => <div style={{ minWidth: 100 }}>変更数量</div>,
        dataIndex: 'uptBara',
        key: 'uptBara',
        render: (_value: any, record: any) => (
          <div style={{ padding: 2, minWidth: 100 }}>
            <InputBara
              form={form}
              lstMeisai={lstMeisai}
              setLstMeisai={setLstMeisai}
              dfValue={_value}
              record={record}
              inputRef={inputRef}
              formRef={formRef}
            ></InputBara>
          </div>
        ),
      },
    ];

  const {
    mutate: getLstBin,
    data: dataLstBin,
    isLoading: loadingLstBin,
  } = useGetListBin();

  const {
    mutate: getShippingInfo,
    isLoading: loadingShippingInfo,
    reset: resetShippingInfo,
  } = useGetShippingInfo();

  const {
    data: dataDetails,
    mutate: searchOrderDetails,
    isLoading: loadingSearchOrderDetails,
  } = useGetOrderDetails();

  useEffect(() => {
    getLstBin(
      {},
      {
        onError(error, variables, context) {
          messagePopup({
            type: 'error',
            content:
              MSG_ERROR[(error as any)?.response?.data?.message] ||
              error?.response?.data?.message,
          });
        },
      },
    );

    formRef.current.orderNo.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinishSearch = (values: any) => {
    const { naibuNo, denNo, denKbn } = values;
    getShippingInfo(
      { naibuNo, denNo, denKbn },
      {
        onSuccess(data, variables, context) {
          const { header, meisai } = data?.data;
          inputRef.current = [];
          formSearch.setFieldsValue({ orderNo: header.orderNo });

          form.setFieldsValue({
            torihikiCd: header.torihikiInfor.torihikiCd,
            kaisya: header.torihikiInfor.kaisya,
            nyukaDt:
              denKbn === '11'
                ? null
                : moment(header.nyukaDt, DATE_FORMAT).format(DATE_FORMAT),
            nohinDt: moment(header.nohinDt, DATE_FORMAT).format(DATE_FORMAT),
            binCd: header.binCd,
            sendTo: header.sendTo.trim(),
            sendAdrs: header.sendAdrs.trim(),
            nyukaDtFull:
              denKbn === '11'
                ? null
                : moment(header.nyukaDt, DATE_FORMAT).format(
                  'YYYY/MM/DD (ddd)',
                ),
            nohinDtFull: moment(header.nohinDt, DATE_FORMAT).format(
              'YYYY/MM/DD (ddd)',
            ),
            updDt: header.updDt,
            confirmDel: null,
          });

          setListMeisai(
            meisai.map((item: any, index: any) => {
              return {
                ...item,
                uptBara: item.bara,
                previousBara: item.bara,
                index,
              };
            }),
          );
          setPreviousShippingInfor({
            orderNo: header.orderNo,
            naibuNo,
            denNo,
            denKbn,
            torihikiCd: header.torihikiInfor.torihikiCd,
            kaisya: header.torihikiInfor.kaisya,
            nyukaDt: header.nyukaDt,
            nohinDt: header.nohinDt,
            binCd: header.binCd,
            sendTo: header.sendTo.trim(),
            sendAdrs: header.sendAdrs.trim(),
          });
          setDisableInput({
            ...disableInput,
            disableFormShipping: false,
            disableFormSearch: true,
          });
          if (denKbn === '11') {
            formRef.current['nohinDt'].focus();
          } else {
            formRef.current['nyukaDt'].focus();
          }
        },
        onError(error, variables, context) {
          messagePopup({
            type: 'info',
            content:
              MSG_ERROR[(error as any)?.response?.data?.message] ||
              error?.response?.data?.message,
            onOk: () => {
              formRef?.current?.naibuNo?.focus();
              formRef?.current?.naibuNo?.select();
            },
          });
        },
      },
    );
  };

  const onFinishSearchFailed = ({ values, errorFields }: any) => {
    messagePopup({
      type: 'info',
      content: errorFields[0]?.errors[0],
      onOk: () => {
        formRef?.current[errorFields[0]?.name[0]]?.focus();
        formRef?.current[errorFields[0]?.name[0]]?.select();
      },
    });
  };

  const onFinish = (values: any) => {
    const { confirmDel, nohinDtFull, nyukaDtFull, nohinDt, nyukaDt, ...rest } =
      form.getFieldsValue(true);
    const data = {
      header: {
        ...formSearch.getFieldsValue(),
        ...rest,
        nyukaDt:
          formSearch.getFieldValue('denKbn') === '11' ? '000000' : nyukaDt,
        nohinDt: nohinDt,
      },
      listMeisai: listMeisai.map((syohin: any) => {
        const { errorBara, focusBara, msgErrorBara, uptBara, bara, ...rest } =
          syohin;
        return { ...rest, oldBara: bara, newBara: uptBara };
      }),
    };
    if (checkLstMeisai(listMeisai)) {
      setConfirmShippingInfor(data);
      setIsVisibleConfirmShipping(true);
    }
  };

  const onFinishFailed = ({ values, errorFields }: any) => {
    messagePopup({
      type: 'info',
      content: errorFields[0]?.errors[0],
      onOk: () => {
        formRef?.current[errorFields[0]?.name[0]]?.focus();
      },
    });
  };

  // Display msg error validate lstMeisai
  function checkLstMeisai(lstMeisai: any) {
    // // console.log(lstMeisai);
    const errorBara = lstMeisai.find(
      (syohin: any) => syohin.errorBara === true && syohin.uptBara !== '0',
    );

    if (!!errorBara) {
      messagePopup({
        type: 'info',
        content: errorBara.msgErrorBara,
        onOk: () => errorBara?.focusBara(),
      });
      return false;
    }
    return true;
  }

  const clearScreen = () => {
    setIsVisibleConfirmShipping(false);
    resetShippingInfo();
    form.resetFields();
    formSearch.resetFields();
    formReprint.resetFields();
    setListMeisai([]);
    setDisableInput({
      disableBtnShipping: false,
      disableFormShipping: true,
      disableFormSearch: false,
    });
    setTimeout(() => {
      formRef?.current?.orderNo?.focus();
    }, 300);
  };

  return (
    <WrapperMainte>
      {(loadingLstBin || loadingSearchOrderDetails || loadingShippingInfo) && (
        <LoadingCallAPI />
      )}
      <FormWrapper
        form={formSearch}
        className="form-mainte"
        name="search-shipping"
        labelCol={{ flex: '120px' }}
        wrapperCol={{ flex: 1 }}
        onFinish={onFinishSearch}
        requiredMark={false}
        labelAlign="left"
        colon={false}
        validateTrigger="onSubmit"
        onFinishFailed={onFinishSearchFailed}
        scrollToFirstError
        onKeyPress={e => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
      >
        <HeaderForm>
          <span>{labelTokuisaki}</span>
        </HeaderForm>
        <Form.Item
          label="受付Ｎｏ"
          className={disableInput.disableFormSearch ? 'disable-input' : ''}
        >
          <Space>
            <Form.Item noStyle name="orderNo">
              <Input
                disabled={disableInput.disableFormSearch}
                ref={el => (formRef.current.orderNo = el)}
                onKeyUp={event =>
                  (event.key === 'Enter' || event.key === 'Tab') &&
                  event.currentTarget.select()
                }
                onFocus={event => {
                  // event.currentTarget.select();
                  previousOrderNo.current = event.target.value;
                }}
                maxLength={10}
                style={{ width: 200 }}
                onBlur={event => {
                  let value = ToASCII(event.target.value).trim();
                  formSearch.setFieldsValue({
                    orderNo: value,
                  });
                  // if (value && value !== previousOrderNo.current) {
                  //   if (isNumeric(value)) {
                  //     searchOrderDetails(
                  //       { orderNo: value },
                  //       {
                  //         onSuccess(data, variables, context) {
                  //           if (data?.data?.count === 0) {
                  //             messagePopup({
                  //               type: 'info',
                  //               content: MSG_ERROR['MSG_NO_DATA'],
                  //               onOk: () => {
                  //                 formRef.current.orderNo.focus();
                  //                 formRef.current.orderNo.select();
                  //               },
                  //             });
                  //           } else {
                  //             setIsVisibleDetailOrder(true);
                  //           }
                  //         },
                  //       },
                  //     );
                  //   } else {
                  //     messagePopup({
                  //       type: 'info',
                  //       content: '受付№' + MSG_ERROR['MSG_010'],
                  //       onOk: () => {
                  //         formRef.current.orderNo.focus();
                  //         formRef.current.orderNo.select();
                  //       },
                  //     });
                  //   }
                  // }
                }}
                onPressEnter={event => {
                  event.preventDefault();
                  formRef.current.btnSearchOrder.focus();
                }}
              ></Input>
            </Form.Item>
            <Button
              ref={el => (formRef.current.btnSearchOrder = el)}
              style={{ width: 100 }}
              type="primary"
              onClick={() => {
                const value = formSearch.getFieldValue('orderNo');
                if (!value) {
                  messagePopup({
                    type: 'info',
                    content: '受付№' + MSG_ERROR['MSG_INPUT'],
                    onOk: () => {
                      formRef.current.orderNo.focus();
                      formRef.current.orderNo.select();
                    },
                  });
                } else if (isNumeric(value)) {
                  searchOrderDetails(
                    { orderNo: value, needCheck: true },
                    {
                      onSuccess(data, variables, context) {
                        if (data?.data?.count === 0) {
                          messagePopup({
                            type: 'info',
                            content: MSG_ERROR['MSG_NO_DATA'],
                            onOk: () => {
                              formRef.current.orderNo.focus();
                              formRef.current.orderNo.select();
                            },
                          });
                        } else {
                          setIsVisibleDetailOrder(true);
                        }
                      },
                    },
                  );
                } else {
                  messagePopup({
                    type: 'info',
                    content: '受付№' + MSG_ERROR['MSG_010'],
                    onOk: () => {
                      formRef.current.orderNo.focus();
                      formRef.current.orderNo.select();
                    },
                  });
                }
              }}
              onKeyDown={event => {
                if (event.key === 'Enter') {
                  formRef.current.btnSearchOrder.click();
                }
              }}
            >
              参照
            </Button>
          </Space>
        </Form.Item>
        <Divider style={{ margin: '12px 0px' }} />
        <div className="flex-formsearch">
          <Form.Item
            className={disableInput.disableFormSearch ? 'disable-input' : ''}
            label="内部Ｎｏ"
            name="naibuNo"
            style={{ width: 300 }}
            rules={[
              () => ({
                validator(_: any, value: string) {
                  if (!value) {
                    return Promise.reject(
                      new Error('内部Ｎｏ' + MSG_ERROR['MSG_INPUT']),
                    );
                  }
                  if (!isNumeric(value)) {
                    return Promise.reject(
                      new Error('内部Ｎｏ' + MSG_ERROR['MSG_NUMERIC']),
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              disabled={disableInput.disableFormSearch}
              ref={el => (formRef.current.naibuNo = el)}
              maxLength={6}
              style={{ width: 180 }}
              onKeyUp={event =>
                (event.key === 'Enter' || event.key === 'Tab') &&
                event.currentTarget.select()
              }
              onBlur={event => {
                formSearch.setFieldsValue({
                  naibuNo: ToASCII(event.target.value.trimEnd()),
                });
              }}
              onPressEnter={(event: any) => {
                event.preventDefault();
                formRef.current.denKbn.focus();
              }}
            ></Input>
          </Form.Item>
          <Form.Item
            className={disableInput.disableFormSearch ? 'disable-input' : ''}
            label="伝票区分"
            name="denKbn"
            style={{ width: 170 }}
            rules={[
              () => ({
                validator(_: any, value: string) {
                  if (!value) {
                    return Promise.reject(
                      new Error('伝票区分' + MSG_ERROR['MSG_INPUT']),
                    );
                  }
                  if (!isNumeric(value)) {
                    return Promise.reject(
                      new Error('伝票区分' + MSG_ERROR['MSG_NUMERIC']),
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              disabled={disableInput.disableFormSearch}
              ref={el => (formRef.current.denKbn = el)}
              maxLength={2}
              style={{ width: 50 }}
              onKeyUp={event =>
                (event.key === 'Enter' || event.key === 'Tab') &&
                event.currentTarget.select()
              }
              onBlur={event => {
                formSearch.setFieldsValue({
                  denKbn: ToASCII(event.target.value.trimEnd()),
                });
              }}
              onPressEnter={(event: any) => {
                event.preventDefault();
                formRef.current.denNo.focus();
              }}
            ></Input>
          </Form.Item>
          <Form.Item
            className={disableInput.disableFormSearch ? 'disable-input' : ''}
            label="伝票№"
            style={{ width: 270 }}
            name="denNo"
            rules={[
              () => ({
                validator(_: any, value: string) {
                  if (!value) {
                    return Promise.reject(
                      new Error('伝票№' + MSG_ERROR['MSG_INPUT']),
                    );
                  }
                  if (!isNumeric(value)) {
                    return Promise.reject(
                      new Error('伝票№' + MSG_ERROR['MSG_NUMERIC']),
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              disabled={disableInput.disableFormSearch}
              ref={el => (formRef.current.denNo = el)}
              maxLength={6}
              style={{ width: 150 }}
              onKeyUp={event =>
                (event.key === 'Enter' || event.key === 'Tab') &&
                event.currentTarget.select()
              }
              onBlur={event => {
                formSearch.setFieldsValue({
                  denNo: ToASCII(event.target.value.trimEnd()),
                });
              }}
              onPressEnter={event => {
                event.preventDefault();
                formRef.current.location.focus();
              }}
            ></Input>
          </Form.Item>

          <Button
            disabled={disableInput.disableFormSearch || loadingShippingInfo}
            ref={el => (formRef.current.btnSearchShipping = el)}
            htmlType="submit"
            style={{ width: 100 }}
            type="primary"
            onKeyDown={event => {
              if (event.key === 'Enter') {
                formRef.current.btnSearchShipping.click();
              }
            }}
          >
            呼出し
          </Button>
          {/* <Button
            ref={el => (formRef.current.btnSearchShipping = el)}
            style={{ width: 100 }}
            onClick={() => {
              form.submit();
              console.log(form.getFieldsValue());
            }}
          >
            TESTTTTT
          </Button> */}
        </div>
        <Divider style={{ margin: '12px 0px' }} />
      </FormWrapper>
      <FormWrapper
        form={form}
        className="form-mainte"
        name="shipping-correction"
        labelCol={{ flex: '120px' }}
        wrapperCol={{ flex: 1 }}
        onFinish={onFinish}
        requiredMark={false}
        labelAlign="left"
        colon={false}
        validateTrigger="onSubmit"
        onFinishFailed={onFinishFailed}
        scrollToFirstError
        onKeyPress={e => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
      >
        {/* <Form.Item label="111111">
          <DatePickerCommonV2
            refForm={formRef}
            form={form}
            formName="aaaa"
            nextFocus={() => formRef.current['nohinDt'].focus()}
            label="111111"
            afterChangeValue={(value: any) => {
              form.setFieldsValue({
                nyukaDtFull: moment(value).format('YYYY/MM/DD (ddd)'),
              });
            }}
            placeholder="YYMMDD"
          />
        </Form.Item> */}
        <Form.Item className={'disable-input'} label="得意先">
          <Space size={4}>
            <Form.Item
              noStyle
              name="torihikiCd"
              rules={[
                () => ({
                  validator(_: any, value: string) {
                    if (!value) {
                      return Promise.reject(
                        new Error('得意先' + MSG_ERROR['MSG_INPUT']),
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input
                ref={el => (formRef.current.torihikiCd = el)}
                disabled={true}
                maxLength={15}
                style={{ width: 200 }}
                onPressEnter={(event: any) => {
                  formRef.current.torihikiCd.blur();
                  formRef.current.kaisya.focus();
                  formRef.current.kaisya.select();
                }}
              ></Input>
            </Form.Item>
            <Form.Item noStyle name="kaisya">
              <Input
                ref={el => (formRef.current.kaisya = el)}
                disabled={true}
                maxLength={15}
                style={{ width: 400 }}
                onPressEnter={(event: any) => {
                  formRef.current.kaisya.blur();
                  formRef.current.nyukaDt.focus();
                  formRef.current.nyukaDt.select();
                }}
              ></Input>
            </Form.Item>
          </Space>
        </Form.Item>

        <Form.Item
          label="入荷日"
          className={
            disableInput.disableFormShipping ||
              formSearch.getFieldValue('denKbn') === '11'
              ? 'disable-input'
              : ''
          }
        >
          <Space size={4}>
            <DatePickerCommonV2
              refForm={formRef}
              form={form}
              formName="nyukaDt"
              nextFocus={() => formRef.current['nohinDt'].focus()}
              label="入荷日"
              afterChangeValue={(value: any) => {
                form.setFieldsValue({
                  nyukaDtFull: moment(value).format('YYYY/MM/DD (ddd)'),
                });
              }}
              placeholder="YYMMDD"
              disabled={
                disableInput.disableFormShipping ||
                formSearch.getFieldValue('denKbn') === '11'
              }
              hideDD
              validateRules={(value: any) => {
                if (formSearch.getFieldValue('denKbn') !== '11' && !value) {
                  return '入荷日' + MSG_ERROR['MSG_INPUT'];
                }
                return null;
              }}
            />
            {/* <PickerCustom
              disabled={
                disableInput.disableFormShipping ||
                formSearch.getFieldValue('denKbn') === '11'
              }
              title={'入荷日'}
              nextFocus={() => formRef.current['nohinDt'].focus()}
              form={form}
              refForm={formRef}
              autoFocus={false}
              formName="nyukaDt"
              hideDD={true}
              afterChangeValue={(value: any) => {
                form.setFieldsValue({
                  nyukaDtFull: moment(value).format('YYYY/MM/DD (ddd)'),
                });
              }}
              validateRules={(value: any) => {
                if (formSearch.getFieldValue('denKbn') !== '11' && !value) {
                  return '入荷日' + MSG_ERROR['MSG_INPUT'];
                }
                return null;
              }}
            /> */}

            {/* <Form.Item
              noStyle
              name="nyukaDt"
              rules={[
                () => ({
                  validator(_: any, value: string) {
                    if (formSearch.getFieldValue('denKbn') !== '11' && !value) {
                      return Promise.reject(
                        new Error('入荷日' + MSG_ERROR['MSG_INPUT']),
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <DatePickerCommon
                disabled={
                  disableInput.disableFormShipping ||
                  formSearch.getFieldValue('denKbn') === '11'
                }
                allowClear={false}
                refForm={formRef}
                formName="nyukaDt"
                itemName="入荷日"
                nextFocus={() => formRef.current['nohinDt'].focus()}
                onChange={(value: any) => {
                  form.setFieldsValue({
                    nyukaDtFull: moment(value).format('YYYY/MM/DD (ddd)'),
                  });
                }}
                style={{ width: 150, padding: '2px 10px' }}
                format={DATE_FORMAT}
                getPopupContainer={(trigger: HTMLElement) =>
                  trigger.parentNode as HTMLElement
                }
              />
            </Form.Item> */}
            <Form.Item
              noStyle
              name="nyukaDtFull"
              rules={[
                () => ({
                  validator(_: any, value: string) {
                    if (!disableInput) {
                      // 必須チェック
                      if (!value) {
                        return Promise.reject(
                          new Error('品名' + MSG_ERROR['MSG_INPUT']),
                        );
                      }
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input
                ref={el => (formRef.current.nyukaDtFull = el)}
                disabled
                style={{ width: 200 }}
                onPressEnter={(event: any) => {
                  formRef.current.nyukaDtFull.blur();
                  formRef.current.nohinDt.focus();
                  formRef.current.nohinDt.select();
                }}
              ></Input>
            </Form.Item>
          </Space>
        </Form.Item>

        <Form.Item
          label="納入日"
          className={disableInput.disableFormShipping ? 'disable-input' : ''}
        >
          <Space size={4}>
            <DatePickerCommonV2
              refForm={formRef}
              form={form}
              formName="nohinDt"
              nextFocus={() => formRef.current['sendTo'].focus()}
              label="納入日"
              afterChangeValue={(value: any) => {
                form.setFieldsValue({
                  nohinDtFull: moment(value).format('YYYY/MM/DD (ddd)'),
                });
              }}
              placeholder="YYMMDD"
              disabled={disableInput.disableFormShipping}
              hideDD
              validateRules={(value: any) => {
                if (!value) {
                  return '納入日' + MSG_ERROR['MSG_INPUT'];
                }
                if (
                  formSearch.getFieldValue('denKbn') === '21' &&
                  value !== form.getFieldValue('nyukaDt')
                ) {
                  return '特別の場合、入荷日と納入日に同じ日付を入力してください';
                }
                if (
                  formSearch.getFieldValue('denKbn') === '15' &&
                  !(
                    moment(value, DATE_FORMAT).toDate() >=
                    moment(form.getFieldValue('nyukaDt'), DATE_FORMAT).toDate()
                  )
                ) {
                  return '納入日は入荷日以降の日付を入力してください';
                }
                return null;
              }}
            />
            {/* <PickerCustom
              disabled={disableInput.disableFormShipping}
              title={'納入日'}
              nextFocus={() => formRef.current['sendTo'].focus()}
              form={form}
              refForm={formRef}
              autoFocus={false}
              formName="nohinDt"
              hideDD={true}
              afterChangeValue={(value: any) => {
                form.setFieldsValue({
                  nohinDtFull: moment(value).format('YYYY/MM/DD (ddd)'),
                });
              }}
              validateRules={(value: any) => {
                if (!value) {
                  return '納入日' + MSG_ERROR['MSG_INPUT'];
                }
                if (
                  formSearch.getFieldValue('denKbn') === '21' &&
                  !(
                    moment(value).format(DATE_FORMAT) ===
                    moment(form.getFieldValue('nyukaDt')).format(DATE_FORMAT)
                  )
                ) {
                  return '特別の場合、入荷日と納入日に同じ日付を入力してください';
                }
                if (
                  formSearch.getFieldValue('denKbn') === '15' &&
                  !(
                    moment(value).format(DATE_FORMAT) >=
                    moment(form.getFieldValue('nyukaDt')).format(DATE_FORMAT)
                  )
                ) {
                  return '納入日は入荷日以降の日付を入力してください';
                }
                return null;
              }}
            /> */}
            <Form.Item
              noStyle
              name="nohinDtFull"
              rules={[
                () => ({
                  validator(_: any, value: string) {
                    if (!disableInput) {
                      // 必須チェック
                      if (!value) {
                        return Promise.reject(
                          new Error('品名' + MSG_ERROR['MSG_INPUT']),
                        );
                      }
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input
                ref={el => (formRef.current.nohinDtFull = el)}
                disabled
                style={{ width: 200 }}
                onBlur={event => {
                  form.setFieldsValue({});
                }}
                onPressEnter={(event: any) => {
                  formRef.current.nohinDtFull.blur();
                  formRef.current.sendTo.focus();
                  formRef.current.sendTo.select();
                }}
              ></Input>
            </Form.Item>
          </Space>
        </Form.Item>

        <Form.Item
          label="お届け先"
          className={disableInput.disableFormShipping ? 'disable-input' : ''}
        >
          <Space size={4}>
            <Form.Item
              noStyle
              name="sendTo"
              rules={[
                () => ({
                  validator(_: any, value: string) {
                    if (!value) {
                      return Promise.reject(
                        new Error('お届け先' + MSG_ERROR['MSG_INPUT']),
                      );
                    }
                    if (!IsZenkaku(value)) {
                      return Promise.reject(
                        new Error('お届け先' + MSG_ERROR['MSG_HANKAKU']),
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input
                disabled={disableInput.disableFormShipping}
                ref={el => (formRef.current.sendTo = el)}
                maxLength={15}
                style={{ width: 200 }}
                onKeyUp={event =>
                  (event.key === 'Enter' || event.key === 'Tab') &&
                  event.currentTarget.select()
                }
                onBlur={event => {
                  form.setFieldsValue({
                    sendTo: Hankaku2Zenkaku(event.target.value.trimEnd()),
                  });
                }}
                onPressEnter={event => {
                  event.preventDefault();
                  formRef.current.sendAdrs.focus();
                }}
              ></Input>
            </Form.Item>
            <Form.Item
              noStyle
              name="sendAdrs"
              rules={[
                () => ({
                  validator(_: any, value: string) {
                    if (!value) {
                      return Promise.reject(
                        new Error('お届け先情報' + MSG_ERROR['MSG_INPUT']),
                      );
                    }
                    if (!IsZenkaku(value)) {
                      return Promise.reject(
                        new Error('お届け先情報' + MSG_ERROR['MSG_HANKAKU']),
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input
                disabled={disableInput.disableFormShipping}
                ref={el => (formRef.current.sendAdrs = el)}
                maxLength={45}
                style={{ width: 500 }}
                onKeyUp={event =>
                  (event.key === 'Enter' || event.key === 'Tab') &&
                  event.currentTarget.select()
                }
                onBlur={event => {
                  form.setFieldsValue({
                    sendAdrs: Hankaku2Zenkaku(event.target.value),
                  });
                }}
                onPressEnter={event => {
                  event.preventDefault();
                  formRef.current.binCd.focus();
                }}
              ></Input>
            </Form.Item>
          </Space>
        </Form.Item>

        <Form.Item
          className={disableInput.disableFormShipping ? 'disable-input' : ''}
          label="便"
          name="binCd"
          rules={[
            () => ({
              validator(_: any, value: string) {
                let binnm = dataLstBin?.data?.find(
                  (item: any) => item.bincd === value,
                )?.binnm1;
                form.setFieldsValue({ binnm });
                if (!value) {
                  return Promise.reject(
                    new Error('便' + MSG_ERROR['MSG_INPUT']),
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Select
            open={openDropdown}
            onDropdownVisibleChange={open => setOpenDropdown(open)}
            onKeyDown={(event: any) => {
              if (event.key === 'Enter') {
                setOpenDropdown(false);
                formRef.current.confirmDel.focus();
              } else if (event.key === ' ') {
                event.preventDefault();
                setOpenDropdown(true);
              }
            }}
            ref={el => (formRef.current.binCd = el)}
            disabled={disableInput.disableFormShipping}
            style={{ width: 200 }}
            // showAction={['focus']}
            onChange={(value: any, option: any) => {
              if (value === null) {
                messagePopup({
                  type: 'info',
                  content: '班' + MSG_ERROR['MSG_008'],
                  onOk: () => formRef?.current?.binCd?.focus(),
                });
              }
            }}
            getPopupContainer={(trigger: HTMLElement) =>
              trigger.parentNode as HTMLElement
            }
          >
            {dataLstBin?.data?.map((item: any) => (
              <Select.Option key={item.bincd} value={item.bincd}>
                {item.bincd} {item.binnm1}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          className={disableInput.disableFormShipping ? 'disable-input' : ''}
          label="伝票削除"
          style={{ marginBottom: 20 }}
        >
          <Space>
            <Form.Item
              noStyle
              name="confirmDel"
              rules={[
                () => ({
                  validator(_: any, value: string) {
                    if (value && IsHankakuEisu(value)) {
                      return Promise.reject(
                        new Error('伝票削除' + MSG_ERROR['MSG_ZENKAKU']),
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input
                disabled={disableInput.disableFormShipping}
                ref={el => (formRef.current.confirmDel = el)}
                onKeyUp={event =>
                  (event.key === 'Enter' || event.key === 'Tab') &&
                  event.currentTarget.select()
                }
                onFocus={event => {
                  previousConfirmDel.current = event.target.value;
                  // event.currentTarget.select();
                }}
                maxLength={1}
                style={{ width: 50 }}
                onBlur={event => {
                  let valueDel = ToASCII(event.target.value, true);
                  form.setFieldsValue({
                    confirmDel: valueDel,
                  });
                  if (valueDel !== previousConfirmDel.current) {
                    if (valueDel === 'D') {
                      setDisableInput({
                        ...disableInput,
                        disableBtnShipping: true,
                      });
                      setListMeisai((value: any) =>
                        value.map((item: any) => {
                          return {
                            ...item,
                            uptBara: '0',
                          };
                        }),
                      );
                    } else {
                      setDisableInput({
                        ...disableInput,
                        disableBtnShipping: false,
                      });
                      setListMeisai((value: any) =>
                        value.map((item: any) => {
                          return {
                            ...item,
                            uptBara: item.previousBara,
                          };
                        }),
                      );
                    }
                  }
                }}
                onPressEnter={event => {
                  event.preventDefault();
                  inputRef.current[0]?.focus();
                }}
              ></Input>
            </Form.Item>
            <span>
              伝票全体を削除する場合は　<b>D</b>　を入力してください
            </span>
          </Space>
        </Form.Item>
        <TableCustom
          rowKey="orderLine"
          height={'200px'}
          columns={columns(form, listMeisai, setListMeisai, inputRef, formRef)}
          dataSource={listMeisai}
        />
      </FormWrapper>
      <FooterForm style={{ justifyContent: 'space-between' }}>
        <Space>
          {disableInput.disableFormShipping || (
            <FormWrapper
              form={formReprint}
              className="form-mainte"
              name="reprint-shipping"
              labelCol={{ flex: '120px' }}
              wrapperCol={{ flex: 1 }}
              requiredMark={false}
              labelAlign="left"
              colon={false}
              validateTrigger="onSubmit"
              onFinishFailed={onFinishSearchFailed}
              scrollToFirstError
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                }
              }}
            >
              <Form.Item label="ロケーション">
                <Space>
                  <Form.Item noStyle name="location">
                    <Input
                      ref={el => (formRef.current.location = el)}
                      maxLength={6}
                      style={{ width: 100 }}
                      onKeyUp={event =>
                        (event.key === 'Enter' || event.key === 'Tab') &&
                        event.currentTarget.select()
                      }
                      onBlur={event => {
                        formReprint.setFieldsValue({
                          location: ToASCII(event.target.value),
                        });
                      }}
                      onPressEnter={event => {
                        event.preventDefault();
                        formRef.current.btnSearchShipping.focus();
                      }}
                    ></Input>
                  </Form.Item>
                  <Button
                    ref={el => (formRef.current.btnRePrint = el)}
                    onClick={() => {
                      if (
                        !!listMeisai.find(
                          (meisai: any) =>
                            meisai.location ===
                            formReprint.getFieldValue('location'),
                        )
                      ) {
                        setIsVisibleConfirmShippingReprint(true);
                      } else
                        messagePopup({
                          type: 'info',
                          content:
                            '入力したロケーション番号に該当する伝票はありません．\nご入力の情報に誤りはないか、ご確認いただき、再度検索ください．',
                          onOk: () => {
                            formRef.current.location.focus();
                            formRef.current.location.select();
                          },
                        });
                    }}
                    type="primary"
                    style={{ minWidth: 100 }}
                  >
                    伝票再発行
                  </Button>
                </Space>
              </Form.Item>
            </FormWrapper>
          )}
        </Space>
        <Space>
          {disableInput.disableFormShipping || (
            <>
              {previousShippingInfor?.denKbn === '15' && (
                <Button
                  disabled={
                    disableInput.disableFormShipping ||
                    disableInput.disableBtnShipping ||
                    listMeisai?.some((x: any) => x.uptBara == 0)
                  }
                  ref={el => (formRef.current.btnChangeDenKbn = el)}
                  type="primary"
                  htmlType="submit"
                  form="shipping-correction"
                  style={{ minWidth: 100 }}
                  onClick={() => setIsChangeDenKbn(true)}
                >
                  在庫出荷に変更
                </Button>
              )}
              <Button
                disabled={disableInput.disableFormShipping}
                ref={el => (formRef.current.btnSubmit = el)}
                type="primary"
                htmlType="submit"
                form="shipping-correction"
                style={{ minWidth: 100 }}
                onClick={() => setIsChangeDenKbn(false)}
              >
                確認画面へ
              </Button>
            </>
          )}
          <Button
            ref={el => (formRef.current.btnReset = el)}
            type="default"
            style={{ minWidth: 100 }}
            onClick={() => {
              messagePopup({
                type: 'question',
                content: '画面をクリアします。よろしいですか?',
                onOk: () => clearScreen(),
                onCancel: () => formRef?.current?.btnReset?.focus(),
              });
            }}
          >
            画面クリア
          </Button>
          <Button
            ref={el => (formRef.current.btnClose = el)}
            type="default"
            style={{ minWidth: 100 }}
            onClick={() => {
              messagePopup({
                type: 'question',
                content: '終了します。よろしいですか?',
                onOk: () => navigate(PATH.TOP),
                onCancel: () => formRef?.current?.btnClose?.focus(),
              });
            }}
          >
            メニューへ戻る
          </Button>
        </Space>
      </FooterForm>
      <OrderSheet
        formSearch={formSearch}
        dataDetails={dataDetails}
        isModalVisible={isVisibleDetailOrder}
        setIsModalVisible={setIsVisibleDetailOrder}
      ></OrderSheet>
      <ShippingConfirm
        isModalVisible={isVisibleConfirmShipping}
        setIsModalVisible={setIsVisibleConfirmShipping}
        oldShippingInformation={previousShippingInfor}
        newShippingInformation={confirmShippingInfor}
        isChangeDenKbn={isChangeDenKbn}
        onResetPage={clearScreen}
        afterClose={() =>
          isChangeDenKbn
            ? formRef?.current?.btnChangeDenKbn?.focus()
            : formRef?.current?.btnSubmit?.focus()
        }
      />
      <ShippingConfirmReprint
        isModalVisible={isVisibleConfirmShippingReprint}
        setIsModalVisible={setIsVisibleConfirmShippingReprint}
        oldShippingInformation={{
          header: {
            ...previousShippingInfor,
            location: formReprint.getFieldValue('location'),
          },
          listMeisai: formReprint.getFieldValue('location')
            ? listMeisai.filter(
              (meisai: any) =>
                meisai.location === formReprint.getFieldValue('location'),
            )
            : listMeisai,
        }}
        onResetPage={clearScreen}
        afterClose={() => formRef?.current?.btnRePrint?.focus()}
      />
    </WrapperMainte>
  );
};

export default ShippingCorrection;
