import { Modal } from 'antd';
import { useGetDefinedValues } from 'api/common';
import { PATH, ROUTES } from 'configs/routes';
import { IsYhk } from 'constants/cmnDefine';
import moment from 'moment';
import 'moment/locale/ja';
import NotFoundPage from 'pages/notFoundPage';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import { selectIsLoggedIn, selectUserInfo, setDefinedValues } from 'slice/app';
import { GlobalStyle } from 'styles/global.style';
import LayoutAdmin from 'views/layout';
import './App.less';

moment.locale('ja');

function msieversion() {
  var ua = window.navigator.userAgent;
  var msie = ua.indexOf('MSIE ');

  // eslint-disable-next-line no-useless-escape
  if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
    // If Internet Explorer, return version number
    // // console.log(parseInt(ua.substring(msie + 5, ua.indexOf('.', msie))));
    return true;
  }
  // If another browser, return 0
  // // console.log('otherbrowser');
  return false;
}

function App() {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const location = useLocation();
  const userInfo = useSelector(selectUserInfo);
  const { mutate: getDefinedValues } = useGetDefinedValues();
  const dispatch = useDispatch();

  useEffect(() => {
    window.onpopstate = e => {
      Modal.destroyAll();
    };
  }, []);

  const getValueMaster = () => {
    isLoggedIn &&
      getDefinedValues(
        {},
        {
          onSuccess(data) {
            dispatch(setDefinedValues(data.data));
          },
        },
      );
  };

  //Focus browser to update timeserver
  useEffect(() => {
    if (!isLoggedIn) return;
    getValueMaster();

    window.addEventListener('focus', getValueMaster);
    return () => {
      window.removeEventListener('focus', getValueMaster);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  if (msieversion()) {
    return (
      <div className="error-ie-mode">
        <div className="error-ie-mode__text">
          <p
            style={{
              textAlign: 'center',
              fontWeight: 800,
              fontSize: '1.25rem',
            }}
          >
            YHK工材発注システム
          </p>
          {`本サイトはInternet Explorerが非対応になっております。
          下記のブラウザにて再度アクセスをお願いいたします．
          ―――――――――――――――――――
          推奨ブラウザ

          ■パソコン（Windows10/11）
           ・Microsoft Edge（エッジ）
           ・Google Chrome（クローム） 
          
          ■スマホ・タブレット（iOS,Android）
           ・Microsoft Edge（エッジ） 
           ・Google Chrome（クローム）
           ・Safari（サファリ）

           【問合】 
          橋本総業 システム部 佐藤
          TEL:03-3665-9023`}
        </div>
      </div>
    );
  }

  return (
    <>
      <GlobalStyle />
      <Routes>
        <Route path="*" element={<NotFoundPage />} />
        {ROUTES.PUBLIC.map(route => {
          return route.path === PATH.LOGIN ? (
            <Route
              key={route.path}
              element={
                <LayoutAdmin>
                  <Outlet />
                </LayoutAdmin>
              }
            >
              <Route {...route} element={route.element} />
            </Route>
          ) : (
            <Route key={route.path} {...route} />
          );
        })}
        {ROUTES.PRIVATE.map(route => {
          return (
            <Route
              key={route.path}
              element={
                <LayoutAdmin>
                  <Outlet />
                </LayoutAdmin>
              }
            >
              <Route
                {...route}
                element={
                  isLoggedIn && IsYhk(userInfo.kigyoKbn) ? (
                    route.element
                  ) : (
                    <Navigate
                      to={PATH.LOGIN}
                      state={{ from: location }}
                      replace
                    />
                  )
                }
              />
            </Route>
          );
        })}
      </Routes>
    </>
  );
}

export default App;
