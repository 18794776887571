import { Input } from 'antd';
import { ToASCII } from 'constants/common';
import { MSG_ERROR } from 'constants/text';
import { useEffect, useRef, useState } from 'react';
import messagePopup from 'utils/message/mesage';

interface Props {
  setLstStock?: any;
  dfValue?: any;
  lstStock?: any;
  record?: any;
  form?: any;
  inputRef?: any;
  formRef?: any;
}

const InputRefill = ({
  lstStock,
  setLstStock,
  dfValue,
  record,
  inputRef,
  formRef,
}: Props) => {
  const previousValueHatChuSuu = useRef<any>();
  const [valueInputHatChuSuu, setValueInputHatChuSuu] = useState<string | null>(
    null,
  );

  function FocusInput() {
    inputRef?.current[record.index]?.select();
  }

  const handleValue = (value: any) => {
    let updateArray: any;
    let hatchusuu = value?.toString().replaceAll(',', '').trim();
    if (hatchusuu !== previousValueHatChuSuu.current) {
      if (hatchusuu) {
        if (!/^[0-9]+$/.test(hatchusuu)) {
          let updateStock = {
            ...record,
            hatchusuu: hatchusuu,
            previousHatchusuu: hatchusuu,
            errorHatChuSuu: true,
            msgErrorHatChuSuu: '変更数量' + MSG_ERROR['MSG_010'],
            focusHatChuSuu: () => FocusInput(),
          };
          updateArray = lstStock?.map((u: any) =>
            u?.index !== updateStock.index ? u : updateStock,
          );
          messagePopup({
            type: 'info',
            content: '変更数量' + MSG_ERROR['MSG_010'],
            onOk: () => FocusInput(),
          });
        } else if (parseInt(hatchusuu) === 0) {
          hatchusuu = parseInt(hatchusuu).toString();
          let updateStock = {
            ...record,
            hatchusuu: hatchusuu,
            previousHatchusuu: hatchusuu,
            errorHatChuSuu: true,
            msgErrorHatChuSuu: '変更数量に0は入力できません',
            focusHatChuSuu: () => FocusInput(),
          };
          updateArray = lstStock?.map((u: any) =>
            u?.index !== updateStock.index ? u : updateStock,
          );
          messagePopup({
            type: 'info',
            content: '変更数量に0は入力できません',
            onOk: () => FocusInput(),
          });
        } else {
          hatchusuu = parseInt(hatchusuu).toString();
          let updateStock = {
            ...record,
            hatchusuu: hatchusuu,
            previousHatchusuu: hatchusuu,
            errorHatChuSuu: false,
            msgErrorHatChuSuu: '',
            focusHatChuSuu: () => FocusInput(),
          };
          updateArray = lstStock?.map((u: any) =>
            u?.index !== updateStock.index ? u : updateStock,
          );
        }
      } else {
        let updateStock = {
          ...record,
          hatchusuu: '',
          previousHatchusuu: '',
          errorHatChuSuu: false,
          msgErrorHatChuSuu: '',
        };
        updateArray = lstStock?.map((u: any) =>
          u?.index !== updateStock.index ? u : updateStock,
        );
      }
      setLstStock(updateArray);
      setValueInputHatChuSuu(hatchusuu);
    }
  };

  useEffect(() => {
    setValueInputHatChuSuu(dfValue?.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dfValue]);

  return (
    <Input
      ref={el => (inputRef.current[record.index] = el)}
      style={{ textAlign: 'right', width: 80 }}
      maxLength={6}
      value={valueInputHatChuSuu ?? ''}
      size="small"
      disabled={record?.check}
      onChange={e => {
        setValueInputHatChuSuu(e.target.value);
      }}
      onBlur={event => {
        event.preventDefault();
        let value = ToASCII(event.target.value);
        handleValue(value);
      }}
      onKeyUp={event =>
        (event.key === 'Enter' || event.key === 'Tab') &&
        event.currentTarget.select()
      }
      onFocus={event => {
        previousValueHatChuSuu.current = event.target.value;
        // event.currentTarget.select();
      }}
      onPressEnter={event => {
        if (inputRef?.current[record.index + 1]) {
          inputRef?.current[record.index + 1]?.focus();
        } else formRef?.current?.btnSubmit?.focus();
      }}
    />
  );
};

export default InputRefill;
