import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Button, Form, Input, Space } from 'antd';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/drop/lib/styles/index.css';
import '@react-pdf-viewer/highlight/lib/styles/index.css';
import { useGetPreDelivery, useGetPreDeliveryExcel } from 'api/preDelivery';
import PickerCustom from 'components/RangePickerCustom/PickerCustom';
import { PATH } from 'configs/routes';
import {
  getKeyboardFocusableElements,
  Hankaku2Zenkaku,
  hasKanji,
  IsHankakuEisu,
  ToASCII,
} from 'constants/common';
import { MSG_ERROR, TEXT_ALL } from 'constants/text';
import { selectUserInfo } from 'slice/app';
import messagePopup from 'utils/message/mesage';
import { FormWrapper } from 'views/login/FormLogin/formLogin.style';
import { ControlFilter, MenuWrapper, Wrapper } from '../webSearchMenu.style';
import ModalResultPreDelivery from './modalResult';

//Format Date
const dateFormat = 'YYMMDD';

const PreDelivery = () => {
  const userInfo = useSelector(selectUserInfo);
  const labelTokuisaki =
    (userInfo.kaisya ?? '') + (userInfo.tensyo ?? '') + TEXT_ALL.SAMA;
  const [form] = Form.useForm();
  const refForm = useRef<any>({});
  const refPreviousValue = useRef<any>({});
  const navigate = useNavigate();
  const [dataSource, setDataSource] = useState<any>({
    count: 0,
    listData: [],
  });
  const [visibleModalResult, setVisibleModalResult] = useState<any>(false);
  const { mutate, isLoading, reset } = useGetPreDelivery();
  const {
    mutate: excelMutation,
    isLoading: isLoadingExcel,
    reset: resetExcel,
  } = useGetPreDeliveryExcel();
  // const dataBunrui = [
  //   { value: 'E' },
  //   { value: 'F' },
  //   { value: 'J' },
  //   { value: 'K' },
  //   { value: 'M' },
  //   { value: 'P' },
  //   { value: 'R' },
  //   { value: 'S' },
  //   { value: 'G' },
  // ];

  const keyboardFocusElement: any = useRef([]);
  const focusNextEle = (e: any) => {
    const index = keyboardFocusElement.current.findIndex(
      (element: any) => element === e.target,
    );
    if (index !== -1) {
      if (e.key === 'Enter' || (e.key === 'Tab' && !e.shiftKey)) {
        e.preventDefault();
        keyboardFocusElement.current[index + 1]
          ? keyboardFocusElement.current[index + 1]?.focus()
          : keyboardFocusElement.current[0]?.focus();
      } else if (e.shiftKey && e.key === 'Tab') {
        e.preventDefault();
        keyboardFocusElement.current[index - 1]
          ? keyboardFocusElement.current[index - 1]?.focus()
          : keyboardFocusElement.current[
              keyboardFocusElement.current.length
            ]?.focus();
      }
    }
  };

  useEffect(() => {
    keyboardFocusElement.current = getKeyboardFocusableElements();
  });

  // Func reset form and clear cache data
  function resetPage() {
    form.resetFields();
    reset();
    resetExcel();
    setDataSource({
      count: 0,
      listData: [],
    });
  }

  // In first render
  useEffect(() => {
    resetPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // onSubmit to get response list  hat product delivery
  const onFinish = async (record: any) => {
    let params = {
      binCd: record.binCd,
      nohinDt: moment(record.toDate).format(dateFormat),
      n2Atesaki1: record.n2Atesaki1,
      sendAdr: record.sendAdr,
      bunrui: record.bunrui,
      hinmei: record.hinmei,
    };
    mutate(params as any, {
      onSuccess(data, variables, context) {
        const { data: dataList } = data;
        if (dataList?.count > 0) {
          setDataSource({
            count: dataList?.count,
            listData: dataList?.data?.map((order: any, index: number) => ({
              id: index + 1,
              ...order,
            })),
          });
          setVisibleModalResult(true);
        } else {
          messagePopup({
            type: 'error',
            content: MSG_ERROR['MSG_NO_DATA'],
            onOk: () => {
              refForm?.current?.toDate?.focus();
            },
          });
        }
      },
      onError(error, variables, context) {
        messagePopup({
          type: 'error',
          content:
            MSG_ERROR[(error as any)?.response?.data?.message] ||
            (error as any)?.response?.data?.message,
          onOk: () => {
            refForm?.current?.toDate?.focus();
          },
        });
      },
    });
  };

  // Validate form input
  const onFinishFailed = ({ values, errorFields }: any) => {
    messagePopup({
      type: 'info',
      content: errorFields[0]?.errors[0],
      onOk: () => {
        refForm?.current?.[errorFields[0]?.name[0]]?.focus();
        !!refForm?.current?.[errorFields[0]?.name[0]]?.select?.toString() &&
          refForm?.current?.[errorFields[0]?.name[0]]?.select();
      },
    });
  };

  //Handle Excel
  const handleExcel = () => {
    const { binCd, toDate, n2Atesaki1, sendAdr, bunrui, hinmei } =
      form.getFieldsValue();
    const params = {
      binCd: binCd,
      nohinDt: moment(toDate).format('YYMMDD'),
      n2Atesaki1: n2Atesaki1,
      sendAdr: sendAdr,
      bunrui: bunrui,
      hinmei: hinmei,
    };

    form
      .validateFields()
      .then(() =>
        excelMutation(params as any, {
          onSuccess(data, variables, context) {
            let fileName = data.headers['content-disposition'] || 'errorName';
            const file = new Blob([data?.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            const fileURL = URL.createObjectURL(file);
            const link = document.createElement('a');
            link.href = fileURL;
            link.download = decodeURIComponent(
              fileName?.split('filename*=')[1].split(';')[0],
            ).substring(7);
            link.click();
          },
          onError: async (error, variables, context) => {
            const errorMsg = JSON.parse(
              await error.response.data.text(),
            )?.message;
            messagePopup({
              type: 'error',
              content: MSG_ERROR[errorMsg] || errorMsg,
              onOk: () => {
                refForm?.current?.toDate?.focus();
              },
            });
          },
        }),
      )
      .catch(({ errorFields }) => {
        messagePopup({
          type: 'info',
          content: errorFields[0]?.errors[0],
          onOk: () => {
            refForm?.current?.[errorFields[0]?.name[0]]?.focus();
            !!refForm?.current?.[errorFields[0]?.name[0]]?.select?.toString() &&
              refForm?.current?.[errorFields[0]?.name[0]]?.select();
          },
        });
      });
  };

  return (
    <Wrapper>
      <MenuWrapper style={{ marginBottom: 8 }}>
        <span>{labelTokuisaki}</span>
      </MenuWrapper>

      <FormWrapper
        form={form}
        className="form-mainte"
        name="websearch-mainte"
        labelCol={{ flex: '150px' }}
        wrapperCol={{ flex: 1 }}
        requiredMark={false}
        labelAlign="left"
        colon={false}
        validateTrigger="onSubmit"
        scrollToFirstError
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{
          toDate: moment().add(1, 'd'),
          binCD: '',
          n2Atesaki1: '',
          sendAdr: '',
          bunrui: null,
          hinmei: '',
        }}
        onKeyPress={(e: any) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
      >
        <Form.Item
          label={
            <label>
              {'納入日'}
              <label style={{ color: 'red' }}> 【必須】</label>
            </label>
          }
        >
          <PickerCustom
            title={'納入日'}
            nextFocus={() => refForm.current.binCd.focus()}
            form={form}
            refForm={refForm}
            autoFocus={true}
            disabled={isLoading}
          />
        </Form.Item>
        <Form.Item label="便CD">
          <Space style={{ minWidth: 200 }}>
            <Form.Item
              name="binCd"
              noStyle
              rules={[
                () => ({
                  validator(_: any, value: string) {
                    if (value && (IsHankakuEisu(value) || hasKanji(value))) {
                      return Promise.reject(
                        new Error('便CD' + MSG_ERROR['MSG_HANKAKU_EISU']),
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input
                disabled={isLoading}
                maxLength={3}
                ref={el => (refForm.current.binCd = el)}
                style={{ width: 85 }}
                onKeyUp={event =>
                  (event.key === 'Enter' || event.key === 'Tab') &&
                  event.currentTarget.select()
                }
                onFocus={event => {
                  refPreviousValue.current.binCd = event.target.value;
                }}
                onBlur={event => {
                  event.preventDefault();
                  let value = ToASCII(event.target.value.trim());
                  form.setFieldsValue({
                    binCd: value,
                  });
                  if (value !== refPreviousValue.current.binCd) {
                    form.validateFields(['binCd']).catch(({ errorFields }) => {
                      messagePopup({
                        type: 'info',
                        content: errorFields[0]?.errors[0],
                        onOk: () => {
                          refForm?.current?.[errorFields[0]?.name[0]]?.focus();
                          refForm?.current?.[errorFields[0]?.name[0]]?.select();
                        },
                      });
                    });
                  }
                }}
                onKeyDown={focusNextEle}
              ></Input>
            </Form.Item>
            <span>「P01」～「P16」</span>
          </Space>
        </Form.Item>
        <Form.Item label="協力企業名" name="n2Atesaki1">
          <Input
            disabled={isLoading}
            maxLength={30}
            ref={el => (refForm.current.n2Atesaki1 = el)}
            style={{ width: 400 }}
            onKeyUp={event =>
              (event.key === 'Enter' || event.key === 'Tab') &&
              event.currentTarget.select()
            }
            onBlur={event => {
              event.preventDefault();
              form.setFieldsValue({
                n2Atesaki1: Hankaku2Zenkaku(event.target.value.trimEnd()),
              });
            }}
            onKeyDown={focusNextEle}
          ></Input>
        </Form.Item>
        <Form.Item label="配送住所" name="sendAdr">
          <Input
            disabled={isLoading}
            maxLength={20}
            ref={el => (refForm.current.sendAdr = el)}
            style={{ width: 400 }}
            onKeyUp={event =>
              (event.key === 'Enter' || event.key === 'Tab') &&
              event.currentTarget.select()
            }
            onBlur={event => {
              event.preventDefault();
              form.setFieldsValue({
                sendAdr: Hankaku2Zenkaku(event.target.value.trimEnd()),
              });
            }}
            onKeyDown={focusNextEle}
          ></Input>
        </Form.Item>
        <Form.Item
          label={<label>商品分類</label>}
          name="bunrui"
          rules={[
            () => ({
              validator(_: any, value: string) {
                if (value && (IsHankakuEisu(value) || hasKanji(value))) {
                  return Promise.reject(
                    new Error('商品分類' + MSG_ERROR['MSG_HANKAKU_EISU']),
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input
            disabled={isLoading}
            maxLength={3}
            ref={el => (refForm.current.bunrui = el)}
            style={{ width: 85 }}
            onKeyUp={event =>
              (event.key === 'Enter' || event.key === 'Tab') &&
              event.currentTarget.select()
            }
            onFocus={event => {
              refPreviousValue.current.bunrui = event.target.value;
            }}
            onBlur={event => {
              event.preventDefault();
              let value = ToASCII(event.target.value.trim());
              form.setFieldsValue({
                bunrui: value,
              });
              if (value !== refPreviousValue.current.bunrui) {
                form.validateFields(['bunrui']).catch(({ errorFields }) => {
                  messagePopup({
                    type: 'info',
                    content: errorFields[0]?.errors[0],
                    onOk: () => {
                      refForm?.current?.[errorFields[0]?.name[0]]?.focus();
                      refForm?.current?.[errorFields[0]?.name[0]]?.select();
                    },
                  });
                });
              }
            }}
            onKeyDown={focusNextEle}
          ></Input>
        </Form.Item>
        <Form.Item name="hinmei" label={<label>品名</label>}>
          <Input
            disabled={isLoading}
            maxLength={60}
            ref={el => (refForm.current.hinmei = el)}
            style={{ width: 400 }}
            onKeyUp={event =>
              (event.key === 'Enter' || event.key === 'Tab') &&
              event.currentTarget.select()
            }
            onBlur={event => {
              event.preventDefault();
              form.setFieldsValue({
                hinmei: Hankaku2Zenkaku(event.target.value.trimEnd()),
              });
            }}
            onKeyDown={focusNextEle}
          ></Input>
        </Form.Item>
      </FormWrapper>

      <ControlFilter style={{ marginTop: 15 }}>
        <Space>
          <Button
            style={{ minWidth: 100 }}
            loading={isLoading}
            type="primary"
            ref={el => (refForm.current.btnSubmit = el)}
            onClick={e => {
              setDataSource({
                count: 0,
                listData: [],
              });
              setTimeout(() => {
                form.submit();
              }, 100);
            }}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                e.preventDefault();
                form.submit();
              }
            }}
          >
            検索
          </Button>
          <Button
            onClick={e => {
              e.preventDefault();
              resetExcel();
              handleExcel();
            }}
            loading={isLoadingExcel}
            type="default"
            style={{ width: 180 }}
          >
            EXCEL作成（XLSX）
          </Button>
          <Button
            onClick={event => {
              event.preventDefault();
              resetPage();
            }}
            type="default"
          >
            画面クリア
          </Button>
          <Button
            type="default"
            onClick={event => {
              event.preventDefault();
              resetPage();
              navigate(PATH.WEBSEARCH_MENU);
            }}
          >
            メニューへ戻る
          </Button>
        </Space>
      </ControlFilter>

      <ModalResultPreDelivery
        form={form}
        isModalVisible={visibleModalResult}
        setIsModalVisible={setVisibleModalResult}
        dataSource={dataSource}
        afterClose={() => {
          refForm.current.btnSubmit.focus();
          reset();
        }}
      />
    </Wrapper>
  );
};

export default PreDelivery;
