import { API_URL } from 'constants/api';
import { useMutation } from 'react-query';
import AxiosPost from 'utils/axios/axiosPost';

interface ReturnInputProps {
  header: {
    torihikiCd: string;
    shukkaDt: string;
    nohinDt: string;
    urikubun: string;
    shorikubun: string;
  };
  meisai: Array<{ cd24: string; bara: number }>;
}
export function useReturnInput() {
  const returnInput = async (request: ReturnInputProps) => {
    return await AxiosPost(API_URL.RETURN_INPUT, { ...request });
  };

  const mutation = useMutation(returnInput as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });

  return mutation;
}

interface CheckZaikoProps {
  meisai: Array<{ cd24: string; bara: number }>;
}
export function useCheckZaiko() {
  const checkZaiko = async ({ meisai }: CheckZaikoProps) => {
    return await AxiosPost(API_URL.RETURN_INPUT_CHECK_ZAIKO, meisai);
  };

  const mutation = useMutation(checkZaiko as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });

  return mutation;
}
