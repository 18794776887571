import { useLogAccess } from 'api/auth';
import { PATH } from 'configs/routes';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectUserInfo } from 'slice/app';
import {
  ButtonWrapper,
  // MenuExtraText,
  MenuItemWrapper,
  MenuTitle,
} from './menuItem.style';
interface Props {
  number?: string;
  backgroundColor?: string;
  title?: string;
  extraText?: string;
  path: string;
  autoFocus?: boolean;
}

const MenuItem = ({
  number,
  backgroundColor,
  title,
  extraText,
  path,
  autoFocus,
}: Props) => {
  const navigate = useNavigate();
  const userInfo = useSelector(selectUserInfo);

  /**
   * *API LogAccess
   * todo log
   * @param {...}
   */
  const { mutate: logAccess } = useLogAccess();

  const logAccessMenu = () => {
    switch (path) {
      case PATH.CALENDAR_MAINTE:
        logAccess([
          {
            tokuiCd: userInfo.torihikicd,
            gyoumuId: 'カレンダーメンテ',
            pgName: 'カレンダーメンテ',
            logType: '1',
            action: '',
          },
        ] as any);
        break;
      case PATH.TORIHIKISAKI_MAINTE:
        logAccess([
          {
            tokuiCd: userInfo.torihikicd,
            gyoumuId: '取引先メンテ',
            pgName: '取引先メンテ',
            logType: '1',
            action: '',
          },
        ] as any);
        break;
      case PATH.HANOTODOKESAKI_MAINTE:
        logAccess([
          {
            tokuiCd: userInfo.torihikicd,
            gyoumuId: '班・お届け先（便）メンテ',
            pgName: '班・お届け先（便）メンテ',
            logType: '1',
            action: '',
          },
        ] as any);
        break;
      case PATH.TANTOU_MAINTE:
        logAccess([
          {
            tokuiCd: userInfo.torihikicd,
            gyoumuId: 'ご注文担当メンテ',
            pgName: 'ご注文担当メンテ',
            logType: '1',
            action: '',
          },
        ] as any);
        break;
      case PATH.OSIRASE_MAINTE:
        logAccess([
          {
            tokuiCd: userInfo.torihikicd,
            gyoumuId: 'お知らせメンテ',
            pgName: 'お知らせメンテ',
            logType: '1',
            action: '',
          },
        ] as any);
        break;
      case PATH.SYOHIN_MAINTE:
        logAccess([
          {
            tokuiCd: userInfo.torihikicd,
            gyoumuId: '商品メンテ',
            pgName: '商品メンテ',
            logType: '1',
            action: '',
          },
        ] as any);
        break;
      case PATH.MASTER_CHECK:
        logAccess([
          {
            tokuiCd: userInfo.torihikicd,
            gyoumuId: 'マスタチェック',
            pgName: 'マスタチェック',
            logType: '1',
            action: '',
          },
        ] as any);
        break;
      default:
        break;
    }
  };

  return (
    <ButtonWrapper
      autoFocus={autoFocus}
      type="text"
      onClick={event => {
        event.stopPropagation();
        logAccessMenu();
        if (path === PATH.YHK_MANUAL) {
          let widthScreen = window.screen.width;
          let heightScreen = window.screen.height;
          window.open(
            process.env.PUBLIC_URL + PATH.YHK_MANUAL,
            '_blank',
            `resizable=yes, width=${widthScreen}, height=${heightScreen}, top=${heightScreen}, left=${widthScreen}`,
          );
        } else {
          return navigate(path);
        }
      }}
    >
      <MenuItemWrapper backgroundColor={backgroundColor}>
        <MenuTitle>
          {number}
          {title}
        </MenuTitle>
        {/* <MenuExtraText>{extraText}</MenuExtraText> */}
      </MenuItemWrapper>
    </ButtonWrapper>
  );
};

export default MenuItem;
